import React, { Component } from 'react';
import './PaymentsSettingsComponent.scss'
import CoinPayments from './CoinPayments/CoinPayments';
import Stripe from './Stripe/Stripe';
import Paypal from './Paypal/Paypal';
import { Link } from "react-router-dom";
class PaymentsSettingsComponent extends Component {
    state = {
        step: this.props.paymentGatewaysData.data[0].type ? this.props.paymentGatewaysData.data[0].type : "",
        data: null
    }
    onStepChange = (step) => {
        this.setState({ step: step })
    }
    componentWillMount() {
        this.setState({ data: this.props.paymentGatewaysData.data });
        this.props.paymentGatewaysData.data.map(item => {
            if (item.type === "paypal") {
                this.setState({
                    paypal: item
                })
            }
            if (item.type === "coinPayments") {
                this.setState({
                    coinPayments: item
                })
            }
            if (item.type === "stripe") {
                this.setState({
                    stripe: item
                })
            }
        })
    }
    renderTabs = () => {
        let tabs = []
        this.state.data.map((item, key) => {
            if (item.type === "coinPayments") {
                tabs.push(
                    <div className="coinPayments" key={key} onClick={() => this.onStepChange("coinPayments")}>
                        <div className="coinPaymentsHeader">
                            <div className="Bitcoin"></div>
                            <div className="BlackCoin"></div>
                            <div className="Dash"></div>
                            <div className="Ether"></div>
                            <div className="Namecoin"></div>
                            <div className="LitecoinTestnet"></div>
                        </div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>Crypto Payment</div>
                    </div>
                )
            }
            if (item.type === "paypal") {
                tabs.push(
                    <div className="paypal" key={key} onClick={() => this.onStepChange("paypal")}>
                        <div className="paypalHeader"></div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>PayPal</div>
                    </div>
                )
            }
            if (item.type === "stripe") {
                tabs.push(
                    <div className="stripe" key={key} onClick={() => this.onStepChange("stripe")}>
                        <div className="stripeHeader"></div>
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>Credit Card</div>
                    </div>
                )
            }
        })
        return tabs
    }
    onChangeCoinPayments = (name, value) => {
        let coinPayments = this.state.coinPayments
        if (name === "min" || name === "max" || name === "fee") {
            coinPayments[name] = value.replace(/\D/g, '');
        } else {
            coinPayments[name] = value;
        }
        this.setState({ coinPayments })
    }
    onChangeStripe = (name, value) => {
        let stripe = this.state.stripe;
        if (name === "min" || name === "max" || name === "fee") {
            stripe[name] = value.replace(/\D/g, '');
        } else {
            stripe[name] = value;
        }
        this.setState({ stripe })
    }
    onPaypalChange = (name, value) => {
        let paypal = this.state.paypal;
        if (name === "min" || name === "max" || name === "fee") {
            paypal[name] = value.replace(/\D/g, '');
        } else {
            paypal[name] = value;
        }
        this.setState({ paypal })
    }
    onSave = () => {
        let data = {
            type: "paymentGateways",
            paymentGateways: this.state.data,
            provider: this.props.DashboardReducer.optionsData.provider === "localhost" ? "adlooker" : this.props.DashboardReducer.optionsData.provider
        }
        this.props.paymentUpdate(data)
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-8">
                        <div className="shadow-widget">
                            <div className="row">
                                <div className="col-md-7">
                                    <h5 style={{ paddingTop: "20px" }}>Payment Type</h5>
                                    <div className="paymentPart">
                                        {this.renderTabs()}
                                    </div>
                                    {
                                        this.state.step === "coinPayments" &&
                                        <CoinPayments
                                            data={this.state.coinPayments}
                                            onChangeCoinPayments={this.onChangeCoinPayments}
                                            onSave={this.onSave}
                                        />
                                    }
                                    {
                                        this.state.step === "stripe" &&
                                        <Stripe
                                            data={this.state.stripe}
                                            onChangeStripe={this.onChangeStripe}
                                            onSave={this.onSave}
                                        />
                                    }
                                    {
                                        this.state.step === "paypal" &&
                                        <Paypal
                                            data={this.state.paypal}
                                            onPaypalChange={this.onPaypalChange}
                                            onSave={this.onSave}
                                        />
                                    }
                                    <div className="footer-buttons">
                                        <Link className="btn btn-default" to="/admin/payments">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PaymentsSettingsComponent;
