import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import './LineChart.scss';

let data = [['x', '']];
class LineChart extends Component {
  componentWillMount() {
    this.props.traficData.data.traffic.map(item => {
      data.push([moment(item.date).format('ll'), item.traffic]);
    });
  }
  componentWillUnmount() {
    data = [['x', '']];
  }
  render() {
    const {
      traficData: {
        data: { totalweek },
      },
    } = this.props;

    return (
      <div style={{ marginTop: '30px' }}>
        <div className="shadow-widget">
          <div className="todays-container">
            <div className="todays-boxes">
              <p className="titile">Week's Traffic</p>
              <p className="number">{totalweek[0].totalCount}</p>
            </div>
            <div className="todays-boxes">
              <p className="titile">Week's Cost</p>
              <p className="number">{totalweek[0].totalCost}</p>
            </div>
          </div>
          <div>
            <Chart
              width={'100%'}
              height={'400px'}
              chartType="LineChart"
              data={data}
              options={{
                series: {
                  0: { curveType: 'function' },
                },
                legend: 'none',
                chartArea: { width: '95%', height: '80%' },
              }}
              rootProps={{ 'data-testid': '2' }}
            />
          </div>
          <div className="chart-footer">
            <p>Your Traffic for Last 7 Days</p>
          </div>
        </div>
      </div>
    );
  }
}

export default LineChart;
