import React from 'react';
import BoxStats from './BoxStats/BoxStats';
import LineChart from './LineChart/LineChart';
import GeoChart from './GeoChart/GeoChart';
import './DashboardComponent.scss';

const DashboardComponent = ({
  dashboardData,
  traficData,
  dashboardData: {
    data: { topCountries },
  },
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <BoxStats dashboardData={dashboardData} />
          <LineChart dashboardData={dashboardData} traficData={traficData} />
        </div>
        <GeoChart topCountries={topCountries} />
      </div>
    </div>
  );
};

export default DashboardComponent;
