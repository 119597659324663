import CommonAction from '../../shared/Http/Http';
import {PAYMENTS_GET,PAYMENTS_LAST_GET,PAYMENTS_APPROVE,AUTOCOMPLITE_USER,ADD_MONEY,GET_PAYMENTS_GATEWAYS,PAYMENTS_UPDATE} from '../constants';


export const getPayments = body => CommonAction('POST', null, `backoffice-extended/payments/get`, PAYMENTS_GET, body);
export const getPaymentsLast = body => CommonAction('POST', null, `backoffice-extended/payments/get`, PAYMENTS_LAST_GET, body);
export const getPaymentsApprove = body => CommonAction('POST', null, `backoffice-extended/approve/advertiser`, PAYMENTS_APPROVE, body);
export const userAutocomplite = body => CommonAction('POST', null, `backoffice-extended/get/user`, AUTOCOMPLITE_USER, body);
export const AddMoney = body => CommonAction('POST', null, `backoffice-extended/add/balance`, ADD_MONEY, body);
export const paymentGateways = body => CommonAction('POST', null, `backoffice/payments/get`, GET_PAYMENTS_GATEWAYS, body);
export const paymentUpdate = body => CommonAction('POST', null, `backoffice/payments/update`, PAYMENTS_UPDATE, body);