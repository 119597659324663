import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Input } from '../../../shared/Forms/Inputs/inputs';
import Button from '../../../shared/Forms/Btn/btn';

const LoginComponent = React.memo(props => (
  <section className="login d-flex">
    <div className="login-content">
      <h1>New Password</h1>
      {(props.error && props.error.message) && <p className="alert alert-danger">{props.error.message}</p>}
      <div className="inputs-box">
        <div className="form-group">
          <Input
            type='password'
            value={props.containerState.datapassword.password}
            valid={props.containerState.datapassword.password}
            placeholder='Password'
            name='password'
            inputClass='input-with-icon log-reg-input'
            icon=''
            dropdownIcon={false}
            onChange={(name, value, valid) => props.onChange('password', {name, value, valid})}
            disabled={false}
          />
        </div>
        <div className="form-group">
          <Input
            type='password'
            value={props.containerState.datapassword.confirmPassword}
            valid={props.containerState.datapassword.confirmPassword}
            password={props.containerState.datapassword.password}
            placeholder='Password'
            name='confirmPassword'
            inputClass='input-with-icon log-reg-input'
            icon=''
            dropdownIcon={false}
            onChange={(name, value, valid) => props.onChange('password', {name, value, valid})}
            disabled={false}
          />
        </div>
      </div>
      <div className="button-section text-center">
        <Button
          className="main"
          onClick={props.onClickButton}
          disabled={!props.formInvalid}
        >
          Sign In
        </Button>
      </div>
    </div>
  </section>
));

LoginComponent.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  containerState: PropTypes.shape({
    data: PropTypes.shape({
      email: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired
    }).isRequired,
    valid: PropTypes.shape({
      email: PropTypes.bool.isRequired,
      password: PropTypes.bool.isRequired
    }).isRequired,
  }).isRequired,
}
export default LoginComponent
