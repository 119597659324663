import React from 'react';
import { PropTypes } from 'prop-types';
import './index.scss';

const Loader = React.memo(props =>
    <article className={`spiner-box ${props.className}`}>
      <div className="cssload-loader">
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
        <div className="cssload-side"></div>
      </div>
    </article>
);

export default Loader;

Loader.propTypes = {
    className : PropTypes.string.isRequired,
}
