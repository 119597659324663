import React, { Component } from 'react';
import Filter from '../../shared/Filters/Filter';
import Loader from '../../shared/Loader';
import Filters from '../../shared/Filters/Filters';
import Table from '../../shared/SemanticTable/Table';
import MoreModal from '../../shared/Modal/MoreModal';
import './AppsComponent.scss';
import moment from 'moment';

class AppsComponent extends Component {
  state = {
    tableHeader: [
      {
        title: 'Name',
        key: 'name',
      },
      {
        title: 'Media Type',
        key: 'media_type',
      },
      {
        title: 'Category',
        key: 'category',
      },
      {
        title: 'Genre',
        key: 'genre',
      },
      {
        title: 'Url',
        key: 'url',
      },
      {
        title: 'Updated Date',
        key: 'updated_at',
      },
      {
        title: 'Created Date',
        key: 'created_at',
      },
      {
        title: 'Status',
        key: 'status',
      },
      {
        title: 'Actions',
        key: 'actions',
      },
    ],
  };

  mapData = () => {
    const {
      appsReducer: { appsData },
    } = this.props;
    const data = appsData || [];
    return data.map(item => {
      return {
        id: item.app_id,
        name: item.name,
        media_type: item.media_type,
        category: item.category,
        genre: item.genre,
        url: item.url,
        updated_at: moment(item.updated_at).format('ll'),
        created_at: moment(item.created_at).format('ll'),
        status: item.status,
      };
    });
  };

  onMoreModal = key => this.setState({ moreModal: true, key: key });

  onModalClose = () => this.setState({ moreModal: false, key: null });

  handleStatusUpdate = (id, type, status) => {
    const {
      appStatusUpdate,
      appsReducer: { appsData },
    } = this.props;
    appStatusUpdate({ app_id: id, status });
  };

  onAdModal = key => this.setState({ adModal: true, index: key });

  onAdModalClose = () => this.setState({ adModal: false, index: null });

  onFilteredSearch = data => {
    const { appTable, provider } = this.props;
    appTable({ ...data, provider });
    // TODO
    this.setState({ filters: { ...data } });
  };

  fetchItems = () => {
    const { appTable, provider } = this.props;
    appTable({ provider });
  };
  render() {
    const {
      appsReducer,
      appAdSpace,
      resetReducer,
      appTable,
      appTableLast,
      appPermissions,
      provider,
      filteredItems,
      resetTableState,
    } = this.props;

    const { moreModal, adModal, tableHeader } = this.state;

    return (
      <div className="container-fluid">
        {appsReducer.loader && <Loader />}
        {moreModal && (
          <MoreModal
            type="apps"
            data={appsReducer.appsData[this.state.key]}
            onModalClose={this.onModalClose}
          />
        )}
        {adModal && (
          <MoreModal
            type="ads"
            data={appsReducer.adSpacesData[this.state.index]}
            onModalClose={this.onAdModalClose}
          />
        )}
        <Filter
          type="apps"
          filter={this.onFilteredSearch}
          isLoading={appsReducer.loader}
          fetchItems={this.fetchItems}
          resetTable={resetReducer}
        />
        <Table
          cells={tableHeader}
          data={this.mapData()}
          action
          type="apps"
          permissions={appPermissions}
          onViewModal={this.onViewModal}
          onMoreModal={this.onMoreModal}
          isLoading={appsReducer.loader}
          dataUpdate={this.handleStatusUpdate}
          getItems={appTableLast}
          filtered={filteredItems}
          infinite
          pagination={appsReducer.pagination && appsReducer.pagination}
          dataStatus={appsReducer}
          resetTableState={resetTableState}
          // filters={this.state.filters}
        />
        {
          // <Table
          //   type="apps"
          //   onMoreModal={this.onMoreModal}
          //   header={tableHeader}
          //   data={this.mapData()}
          //   appsStatusUbdate={this.handleStatusUpdate}
          //   AppsAdspaces={appAdSpace}
          //   adSpacesData={appsReducer.adSpacesData}
          //   adSpacesFetched={appsReducer.adSpacesFetched}
          //   resetReducer={resetReducer}
          //   onAdModal={this.onAdModal}
          //   appsReducer={appsReducer}
          //   AppsTable={appTableLast}
          //   filter={this.state.filter}
          //   Permissions={appPermissions}
          //   loader={appsReducer.loader}
          //   provider={provider}
          // />
        }
      </div>
    );
  }
}

export default AppsComponent;
