import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import './TenantsUpdateComponent.scss';

class TenatsUpdateComponent extends Component {
    state = {
      logo: null,
      mainColor1: "#" + this.props.DashboardReducer.optionsData.options.mainColor1,
      mainColor2: "#" + this.props.DashboardReducer.optionsData.options.mainColor2,
      displayName: this.props.DashboardReducer.optionsData.options.displayName
    }
    requestData = {}
    componentWillReceiveProps(nextProps) {
        if (nextProps.TenantUpdateReducer.tenantFetched) {
            window.location.reload();
        }
    }
    convertBase64 = (value) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
        })
    }
    onChange = (key, value) => {
        if (key === "logo") {
            let logo = null;
            this.convertBase64(value).then(res => {
                logo = res;
                this.setState({
                    logo,
                })
                this.requestData['logo'] = res
            })
        }
        if (key === "mainColor1") {
            this.setState({ mainColor1: value })
            this.requestData['mainColor1'] = value.replace("#", "")
        }
        if (key === "mainColor2") {
            this.setState({ mainColor2: value })
            this.requestData['mainColor2'] = value.replace("#", "")
        }
        if (key === "displayName") {
            this.setState({ displayName: value });
            this.requestData['displayName'] = value
        }
    }
    onEdit = () => {
        let body = {
            provider: this.props.DashboardReducer.optionsData.provider,
            options: this.requestData
        }
        this.props.editTenant(body)
    }
    render() {
        let logoUrl = this.props.DashboardReducer.optionsData.options.logo;
        return (
            <div className="container-fluid">
                <div className="shadow-widget tenants-page-widget">
                    <div className="row">
                      <div className="col-md-2">
                        <h3>General</h3>
                        <div className="form-group">
                          <small>Site Title</small>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.displayName}
                            onChange={(e) => this.onChange("displayName", e.target.value)}
                          />
                        </div>
                        <small>Website Logo</small>
                        <div className="logo-image-placeholder">
                          {logoUrl && <img src={logoUrl}/>}
                        </div>
                        <div className="form-group">
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => this.onChange("logo", e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <h3>Website Colors</h3>
                        <div className="form-group">
                          <small>Main Color 1</small>
                          <input
                              type="color"
                              className="form-control"
                              value={this.state.mainColor1}
                              onChange={(e) => this.onChange("mainColor1", e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <small>Main Color 2</small>
                          <input
                            type="color"
                            className="form-control"
                            value={this.state.mainColor2}
                            onChange={(e) => this.onChange("mainColor2", e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-sm-12">
                          <div className="footer-line">

                            {!this.props.TenantUpdateReducer.tenantPending && <div className="form-group">
                              <button className="btn btn-success form-control" onClick={() => this.onEdit()}>Edit Settings</button>
                            </div>}
                            {this.props.TenantUpdateReducer.tenantPending &&
                            <Loader
                              type="Bars"
                              color="#00BFFF"
                              height="40"
                              width="60"
                            />}
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TenatsUpdateComponent;
