import { LOGIN } from '../constants';
import Auth from '../../shared/Auth/Auth';

export const LoginAction = ({username, password}) => {
  return dispatch => {
    dispatch({ type: LOGIN.PENDING });
    Auth.signIn(username, password)
      .then(user => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch({ type: LOGIN.PASSWORD_CHANGE_REQUIRED, payload: user })
        } else if (user.challengeName === 'SMS_MFA') {
          dispatch({ type: LOGIN.MFA_REQUIRED, payload: user })
        } else {
          Auth.currentAuthenticatedUser().then((session) => {
            const token = session.signInUserSession.accessToken.jwtToken;
            const idToken = session.signInUserSession.idToken.jwtToken;
            const payload = { token, idToken };
            localStorage.setItem('userToken', token);
            localStorage.setItem('user', JSON.stringify(payload));
            dispatch({ type: LOGIN.SUCCESS, payload })
          }).catch((error) => dispatch({ type: LOGIN.ERROR, error }));
        }
      }).catch(error => dispatch({ type: LOGIN.ERROR, error }));
  };
};

export const LoginPasswordChangeAction = ({user, password}) => {
  return dispatch => {
    dispatch({ type: LOGIN.PENDING });
    Auth.completeNewPassword(user, password, {}).then(user => {
      Auth.currentAuthenticatedUser().then((session) => {
        const token = session.signInUserSession.accessToken.jwtToken;
        const idToken = session.signInUserSession.idToken.jwtToken;
        const payload = { token, idToken };
        localStorage.setItem('userToken', token);
        localStorage.setItem('user', JSON.stringify(payload));
        dispatch({ type: LOGIN.SUCCESS, payload })
      }).catch((error) => dispatch({ type: LOGIN.ERROR, error }));
    }).catch(error => dispatch({ type: LOGIN.ERROR, error }));
  };
};


export const LoginMFAAction = ({user, code}) => {
  return dispatch => {
    dispatch({ type: LOGIN.PENDING });
    Auth.confirmSignIn(user, code, 'SMS_MFA').then(user => {
      Auth.currentAuthenticatedUser().then((session) => {
        const token = session.signInUserSession.accessToken.jwtToken;
        const idToken = session.signInUserSession.idToken.jwtToken;
        const payload = { token, idToken };
        localStorage.setItem('userToken', token);
        localStorage.setItem('user', JSON.stringify(payload));
        dispatch({ type: LOGIN.SUCCESS, payload })
      }).catch((error) => dispatch({ type: LOGIN.ERROR, error }));
    }).catch(error => dispatch({ type: LOGIN.ERROR, error }));
  };
};

export const LogoutAction = () => {
  return dispatch => {
    dispatch({ type: LOGIN.LOGOUT_PENDING });
    Auth.signOut().then(data => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
      dispatch({ type: LOGIN.LOGOUT_SUCCESS })
    }).catch(error => dispatch({ type: LOGIN.LOGOUT_ERROR, error }));
  };
};
