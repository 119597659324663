import React  from 'react';

import  Login from './containers/LoginContainer/LoginContainer';
import Dashboard from './containers/DashboardContainer/DashboardContainer';
import Users from'./containers/UsersContainer/UsersContainer';
import Campaigns from './containers/CampaignsContainer/CampaignsContainer';
import Apps from './containers/AppsContainer/AppsContainer';
import DefaultOptions from './containers/DefaultOptionsContainer/DefaultOptionsContainer';
import BillingInfoContainer from './containers/BillingInfoContainer/BillingInfoContainer';
import BidByCountryContainer from './containers/BidByCountryContainer/BidByCountryConatiner';
import PublishersContainer from './containers/PublishersContainer/PublishersContainer';
import AdvertisersContainer from './containers/AdvertisersContainer/AdvertisersContainer';
import PotentialsContainer from './containers/PotentialsContainer/PotentialsContainer';
import AdminsContainer from './containers/AdminsContainer/AdminsContainer';
import LogsContainer from './containers/LogsContainer/LogsContainer';
import WidrovalContainer from './containers/WidrovalContainer/WidrovalContainer';
import PaymentsContainer from './containers/PaymentsContainer/PaymentsContainer';
import TenantsUpdateContainer from './containers/TenantsUpdateContainer/TenantsUpdateContainer';
import IncomesContainer from './containers/IncomesContainer/IncomesContainer';
import PaymentsSettingsContainer from './containers/PaymentsSettingsContainer/PaymentsSettingsContainer';
import WithdrawalsSettingsContainer from './containers/WithdrawalsSettingsContainer/WithdrawalsSettingsContainer'

export const Routes = [
  {
    isNavBar: true,
    isExact: true,
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    isNavBar: true,
    path: '/admin/dashboard',
    name: 'dashboard',
    component: Dashboard,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/users',
    name: 'users',
    component: Users,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/campaigns',
    name: 'campaigns',
    component: Campaigns,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/apps',
    name: 'apps',
    component: Apps,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/default-options',
    name: 'default-options',
    component: DefaultOptions,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/billing-info',
    name: 'default-options',
    component: BillingInfoContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/minbid-by-country',
    name: 'default-options',
    component: BidByCountryContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/publishers',
    name: 'default-options',
    component: PublishersContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/advertisers',
    name: 'default-options',
    component: AdvertisersContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/potentials',
    name: 'default-options',
    component: PotentialsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/admins',
    name: 'default-options',
    component: AdminsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/logs',
    name: 'default-options',
    component: LogsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/withdrawals',
    name: 'default-options',
    component: WidrovalContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/payments',
    name: 'default-options',
    component: PaymentsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/payments-settings',
    name: 'default-options',
    component: PaymentsSettingsContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/website-settings',
    name: 'website-settings',
    component: TenantsUpdateContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/incomes',
    name: 'default-options',
    component: IncomesContainer,
    isPrivate: true
  },
  {
    isNavBar: true,
    path: '/admin/withdrawals-settings',
    name: 'default-options',
    component: WithdrawalsSettingsContainer,
    isPrivate: true
  },
];
