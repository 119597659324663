import React, { Component } from 'react';
import {uniqBy} from 'lodash';

class DefaultOptionsComponent extends Component {
    state = {
        options: {
            // advShaping: "",
            pubShaping: "",
            referralPubFee: "",
            minBidCPM: "",
            // net0: "",
            // net2: "",
            // net1: "",
            referralAdvFee: "",
            // paypal: "",
            // wire: ""
        }
    };
    changedOptions={};
    componentWillMount(){
        let data = this.props.DefaultOptionsReducer.defaultData;
        let options = this.state.options
        data.map(item=>{
            if(item.type==="pubShaping" || item.type==="referralPubFee" || item.type==="minBidCPM" || item.type==="referralAdvFee"){
                options[item.type]=item.value
            }
            if(item.type==="minPayout"){
                options.paypal=item.value.paypal;
                options.wire=item.value.wire;
            }
        })
        this.setState({options})
    }
    onChangeValues = (key, value) => {
        let options = this.state.options;
        options[key]=value.replace(/\D/g,'');
        this.changedOptions[key] = value.replace(/\D/g,'');
        this.setState({options})
    }
    onSave=()=>{
        let data = this.props.DefaultOptionsReducer.defaultData;
        let body = []
        for(let key in this.changedOptions){
            data.map(item=>{
                if(key===item.type){
                    body.push({
                        id: item.id,
                        value: this.changedOptions[key]
                    })
                }
                // if(key==="net0" && item.type==="netCommission" ){
                //     body.push({
                //         id: item.id,
                //         value: {net0: this.changedOptions[key], net1: this.state.options.net1, net2: this.state.options.net2}
                //     })
                // }
                // if(key==="net1" && item.type==="netCommission" ){
                //     body.push({
                //         id: item.id,
                //         value: {net1: this.changedOptions[key], net0: this.state.options.net0, net2: this.state.options.net2}
                //     })
                // }
                // if(key==="net2" && item.type==="netCommission" ){
                //     body.push({
                //         id: item.id,
                //         value: {net2: this.changedOptions[key], net0: this.state.options.net0, net1: this.state.options.net1}
                //     })
                // }
                if(key==="paypal" && item.type==="minPayout" ){
                    body.push({
                        id: item.id,
                        value: {paypal: this.changedOptions[key], wire: this.state.options.wire}
                    })
                }
                if(key==="wire" && item.type==="minPayout" ){
                    body.push({
                        id: item.id,
                        value: {wire: this.changedOptions[key], paypal: this.state.options.paypal}
                    })
                }
            })
        }
        body= uniqBy(body, "id")
        this.props.editDefaultOptions(body)
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="shadow-widget" style={{ padding: "20px" }}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Publisher shaping(%)</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.options.pubShaping}
                                    onChange={(e)=>this.onChangeValues("pubShaping",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Referral percent for publisher (%)</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.options.referralPubFee}
                                    onChange={(e)=>this.onChangeValues("referralPubFee",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Min Bid CPM ($)</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.options.minBidCPM}
                                    onChange={(e)=>this.onChangeValues("minBidCPM",e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Referral percent for advertiser (%)</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.options.referralAdvFee}
                                    onChange={(e)=>this.onChangeValues("referralAdvFee",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12"> </div>
                        {this.props.permissions.update && <div className="col-md-4">
                            <button className="btn btn-success" onClick={()=>this.onSave()} type="button">Save</button>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default DefaultOptionsComponent;
