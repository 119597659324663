import { LOGS_GET, LOGS_GET_LAST } from '../constants';

const defaultState = {
    logsPending: false,
    logsFetched: false,
    logsError: null,
    logsData: [],
    pagination: null
}

const logsReducer = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case LOGS_GET.PENDING: {
            return {
                ...state,
                logsPending: true,
                logsFetched: false,
            }
        }
        case LOGS_GET.SUCCESS: {
            return {
                ...state,
                logsPending: false,
                logsFetched: true,
                logsData: payload.Items,
                pagination: payload.LastEvaluatedKey
            }
        }
        case LOGS_GET_LAST.SUCCESS: {
            return {
                ...state,
                logsPending: false,
                logsFetched: true,
                logsData: [...state.logsData, ...payload.Items],
                pagination: payload.LastEvaluatedKey,
            }
        }
        case LOGS_GET.ERROR: {
            return {
                ...state,
                logsPending: false,
                logsFetched: false,
                logsError: error,
                logsData: [],
                pagination: null,
            }
        }
        case "RESET_LOGS_REDUCER": {
            return {
                ...state,
                logsPending: false,
                logsFetched: false,
                logsError: null,
                logsData: [],
                pagination: null
            }
        }
        default: return state;
    }
}

export default logsReducer;
