import React, { useEffect } from 'react';
import { Menu } from 'semantic-ui-react';

const Tabs = ({ getIncomes, activeItem, setActiveItem }) => {
  useEffect(() => {
    if (activeItem === 'Country') {
      getIncomes({ type: 'country' });
    }
    if (activeItem === 'Date') {
      getIncomes({ type: 'adminDate' });
    }
  }, [activeItem, getIncomes]);
  return (
    <Menu pointing secondary color="blue">
      <Menu.Item
        name="Date"
        active={activeItem === 'Date'}
        onClick={() => setActiveItem('Date')}
      />
      {/* <Menu.Item
        name="Campaign"
        active={activeItem === 'Campaign'}
        onClick={() => setActiveItem('Campaign')}
      /> */}
      <Menu.Item
        name="Country"
        active={activeItem === 'Country'}
        onClick={() => setActiveItem('Country')}
      />
    </Menu>
  );
};

export default Tabs;
