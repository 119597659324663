import { ADMINS_GET, PERMISION_UPDATE, CREATE_ADMIN, RESET_ADMIN_PASSWORD } from '../constants';

const defaultState = {
  adminsPending: false,
  adminsFetched: false,
  adminsError: null,
  adminsData: [],
  pagination: null,
  permissionsPending: false,
  permissionsFetched: false,
  permissionsError: null,
  createPending: false,
  createFetched: false,
  createError: null,
  resetPasswordPending: false,
  resetPasswordFetched: false,
  resetPasswordError: null,
}

const AdminsReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case ADMINS_GET.PENDING: {
      return {
        ...state,
        adminsPending: true,
        adminsFetched: false,
        permissionsPending: false,
        permissionsFetched: false,
        createPending: false,
        createFetched: false,
      }
    }
    case ADMINS_GET.SUCCESS: {
      let pagination = state.pagination
      return {
        ...state,
        adminsPending: false,
        adminsFetched: true,
        adminsData: pagination ? [...state.adminsData, ...payload.Items] : payload.Items,
        pagination: payload.LastEvaluatedKey ? payload.LastEvaluatedKey : null,
      }
    }
    case ADMINS_GET.ERROR: {
      return {
        ...state,
        adminsPending: false,
        adminsFetched: false,
        adminsError: error,
        adminsData: [],
        pagination: null,
      }
    }


    case PERMISION_UPDATE.PENDING: {
      return {
        ...state,
        permissionsPending: true,
        permissionsFetched: false,
      }
    }
    case PERMISION_UPDATE.SUCCESS: {
      return {
        ...state,
        permissionsPending: false,
        permissionsFetched: true,
      }
    }
    case PERMISION_UPDATE.ERROR: {
      return {
        ...state,
        permissionsPending: false,
        permissionsFetched: false,
        permissionsError: error,
      }
    }

    case CREATE_ADMIN.PENDING: {
      return {
        ...state,
        createPending: true,
        createFetched: false,
      }
    }
    case CREATE_ADMIN.SUCCESS: {
      return {
        ...state,
        createPending: false,
        createFetched: true,
      }
    }
    case CREATE_ADMIN.ERROR: {
      return {
        ...state,
        createPending: false,
        createFetched: false,
        createError: error,
      }
    }


    case RESET_ADMIN_PASSWORD.PENDING: {
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordFetched: false,
      }
    }
    case RESET_ADMIN_PASSWORD.SUCCESS: {
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordFetched: true,
      }
    }
    case RESET_ADMIN_PASSWORD.ERROR: {
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordFetched: false,
        resetPasswordError: error,
      }
    }
    case "RESET_ADMINS_REDUCER": {
      return {
        ...state,
        adminsPending: false,
        adminsFetched: false,
        adminsError: null,
        adminsData: [],
        pagination: null,
        permissionsPending: false,
        permissionsFetched: false,
        permissionsError: null,
        resetPasswordPending: false,
        resetPasswordFetched: false,
        resetPasswordError: null,
      }
    }
    default:
      return state
  }
}

export default AdminsReducer;
