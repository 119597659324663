import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import PotentialsComponent from '../../components/PotentialsComponent/PotentialsComponent';
import { resetReducer } from '../../store/actions/resetReducerAction';
import { getPotentials } from '../../store/actions/PotentialsActions';
import { withRouter } from 'react-router-dom';

const PotentialsContainer = ({
  resetReducer,
  getPotentials,
  PotentialsReducer,
  DashboardReducer: {
    optionsData: {
      options: { logo },
    },
    permissionsFetched,
  },
  data,
  pending,
  status,
  filteredItems,
}) => {
  useEffect(() => {
    getPotentials({ type: 'date' });
    return () => resetReducer('RESET_POTENTIALS_REDUCER');
  }, [getPotentials, resetReducer]);

  return (
    <Fragment>
      <InnerPagesComponent pageName="Potentials" logo={logo}>
        {permissionsFetched && (
          <PotentialsComponent
            resetReducer={resetReducer}
            getPotentials={getPotentials}
            PotentialsReducer={PotentialsReducer}
            data={data}
            pending={pending}
            status={status}
            filteredItems={filteredItems}
          />
        )}
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  PotentialsReducer: store.PotentialsReducer,
  DashboardReducer: store.DashboardReducer,
  data: store.PotentialsReducer.data,
  pending: store.PotentialsReducer.pending,
  status: store.PotentialsReducer.status,
  filteredItems: store.PotentialsReducer.filtered,
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: name => dispatch(resetReducer(name)),
  getPotentials: body => dispatch(getPotentials(body)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(PotentialsContainer)
);
