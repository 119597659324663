import CommonAction from '../../shared/Http/Http';
import {
  CAMPAIGNS,
  CAMPAIGNS_UPDATE,
  CAMPAIGNS_LAST,
  FILTER_CAMPAIGNS,
  RESET_CAMPAIGNS_REDUCER,
  FILTER_USERS,
  INFINITE_CAMPAIGNS_SCROLL,
} from '../constants';

export const CompaignTable = body =>
  CommonAction('POST', null, `backoffice-extended/campaign/data`, CAMPAIGNS, {
    ...body,
    limit: 20,
  });

export const CompaignTableLast = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/campaign/data`,
    CAMPAIGNS_LAST,
    { ...body, limit: 20 }
  );

export const CompaignStatusUbdate = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/campaign/update-status`,
    CAMPAIGNS_UPDATE,
    body
  );

export const filterCampaigns = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/campaign/data`,
    FILTER_CAMPAIGNS,
    {
      ...body,
      limit: 20,
    }
  );

// export const infiniteScroll = () => ({ type: 'INFINITE_CAMPAIGNS_SCROLL' });
export const resetCampaignsReducer = () => ({ type: RESET_CAMPAIGNS_REDUCER });

export const infiniteCampaignsScroll = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/campaign/data`,
    INFINITE_CAMPAIGNS_SCROLL,
    {
      ...body,
      limit: 20,
    }
  );
