import { BILLING_INFO_GET, BILLING_INFO_EDIT } from '../constants';

const defaultState = {
  billingPending: false,
  billingFetched: false,
  billingError: null,
  billingData: [],
  editPending: false,
  editFetched: false,
  editError: null,
}

const BillingInfoReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case BILLING_INFO_GET.PENDING: {
      return {
        ...state,
        billingPending: true,
        billingFetched: false,
        billingData: [],
      }
    }
    case BILLING_INFO_GET.SUCCESS: {
      return {
        ...state,
        billingPending: false,
        billingFetched: true,
        billingData: payload,
      }
    }
    case BILLING_INFO_GET.ERROR: {
      return {
        ...state,
        billingPending: false,
        billingFetched: false,
        billingError: error,
        billingData: [],
      }
    }
    case BILLING_INFO_EDIT.PENDING: {
      return {
        ...state,
        editPending: true,
        editFetched: false,
      }
    }
    case BILLING_INFO_EDIT.SUCCESS: {
      return {
        ...state,
        editPending: false,
        editFetched: true,
      }
    }
    case BILLING_INFO_EDIT.ERROR: {
      return {
        ...state,
        editPending: false,
        editFetched: false,
        editError: error,
      }
    }
    case "RESET_BILING_INFO_REDUCER": {
      return {
        ...state,
        billingPending: false,
        billingFetched: false,
        billingError: null,
        billingData: [],
        editPending: false,
        editFetched: false,
        editError: null,
      }
    }
    default: return state
  }
}

export default BillingInfoReducer;
