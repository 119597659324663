import React from 'react';
import './Box.scss';

const Box = ({ value, title }) => {
  return (
    <div className="box-wrapper">
      <p className="value">{value}</p>
      <p className="title">{title}</p>
    </div>
  );
};

export default Box;
