import React, { Component } from 'react';
import './CreateModal.scss';

const regexp ={
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
	email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}
class CreateModal extends Component {
    state = {
        data: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
            repeatePassword: ""
        },
        validation: {
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            password: false,
        }
    }
    onChange=(key, value)=>{
        let data = this.state.data;
        let validation = this.state.validation
        if(key === "phone"){
            data[key] = "+"+value.replace(/\D/g,'');
            if(value!==""){
                validation[key] = true
            }
            if(value===""){
                validation[key] = false
            }
        }
        if(key === "lastName" || key === "firstName"){
            data[key] = value.replace(/[0-9]/g,'');
            if(value.length!==""){
                validation[key] = true
            }
            if(value===""){
                validation[key] = false
            }
        }if(key === "email" || key === "password" || key==="repeatePassword"){
            data[key] = value;
            if(key === "email" && regexp.email.test(value)){
                validation.email = true
            }if(key === "email" && !regexp.email.test(value)){
                validation.email = false
            }
            if(key==="password" && regexp.password.test(value) && data.password === data.repeatePassword){
                validation.password = true
            }
            if(key==="password" && !regexp.password.test(value) && data.password !== data.repeatePassword){
                validation.password = false
            }
            if(key==="repeatePassword" && data.password === data.repeatePassword){
                validation.password = true
            }
            if(key==="repeatePassword" && data.password !== data.repeatePassword){
                validation.password = false
            }
        }
        this.setState({data,validation})
    }
    onCreate=()=>{
        let body={
            firstName: this.state.data.firstName,
            lastName: this.state.data.lastName,
            email: this.state.data.email,
            phone: this.state.data.phone,
            password: this.state.data.password,
        }
        this.props.onAdminCreate(body)
    }
    render() {
        return (
            <div className="CreateContainer">
                <div className="modalContent">
                    <div className="modalTitle">
                        <h6 className="Title">
                            Create
                            <button onClick={() => this.props.onModalClose()}><i className="fa fa-close"></i></button>
                        </h6>
                    </div>
                    <div className="modalBody">
                        <div className="form-group">
                            <small>Fisrt Name</small>
                            <input 
                                className="form-control" 
                                value={this.state.data.firstName}
                                onChange={(e)=>this.onChange("firstName",e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <small>Last Name</small>
                            <input 
                                className="form-control"
                                value={this.state.data.lastName}
                                onChange={(e)=>this.onChange("lastName",e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <small>Email</small>
                            <input 
                                className="form-control"
                                value={this.state.data.email}
                                onChange={(e)=>this.onChange("email",e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <small>Phone Number</small>
                            <input 
                                className="form-control"
                                value={this.state.data.phone}
                                onChange={(e)=>this.onChange("phone",e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <small>Password</small>
                            <input 
                                className="form-control"
                                value={this.state.data.password}
                                onChange={(e)=>this.onChange("password",e.target.value)}
                                type="password"
                            />
                        </div>
                        <div className="form-group">
                            <small>Repeate Password</small>
                            <input 
                                className="form-control"
                                value={this.state.data.repeatePassword}
                                onChange={(e)=>this.onChange("repeatePassword",e.target.value)}
                                type="password"
                            />
                        </div>
                    </div>
                    <div className="modalFooter">
                        <button className="btn btn-secondary" onClick={() => this.props.onModalClose()} style={{ marginRight: "5px" }}>Close</button>
                        <button 
                            className="btn btn-success" 
                            disabled={!this.state.validation.firstName || !this.state.validation.lastName || !this.state.validation.email || !this.state.validation.phone || !this.state.validation.password}
                            onClick={()=>this.onCreate()}
                        >
                            Create
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateModal;
