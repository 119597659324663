import React, { useEffect, useState } from 'react';
import 'numeral/locales/pt-br';
import {
  Dropdown,
  Menu,
  List,
} from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './index.scss';

const ActionsMenu = ({
  data,
  value,
  permissions: { update },
  permissions,
  type,
  onMoreModal,
  onViewModal,
  modalKey,
  campaignStatusUpdate,
  actionKey,
  modalData, provider
}) => {
  const [actions, setActions] = useState({});
  const [isModalOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setActions({
      userActions:
        data.status === 'unconfirmed' || !update
          ? [{ value: 'More', key: 'More', text: 'More' }]
          : data.status === 'approved' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Block', key: 'Block', text: 'Block' },
            ]
          : data.status === 'declined' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Approve', key: 'Approve', text: 'Approve' },
            ]
          : data.status === 'pending' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Block', key: 'Block', text: 'Block' },
              { value: 'Approve', key: 'Approve', text: 'Approve' },
            ]
          : [],
      campaignActions:
        data.status === 'active' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'View', key: 'View', text: 'View' },
              { value: 'Block', key: 'Block', text: 'Block' },
            ]
          : data.status === 'declined' && update
          ? [
              { value: 'Active', key: 'Active', text: 'Active' },
              { value: 'More', key: 'More', text: 'More' },
              { value: 'View', key: 'View', text: 'View' },
            ]
          : data.status === 'pending' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Block', key: 'Block', text: 'Block' },
              { value: 'Active', key: 'Active', text: 'Active' },
            ]
          : [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'View', key: 'View', text: 'View' },
            ],
      appsActions:
        data.status === 'active' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Block', key: 'Block', text: 'Block' },
            ]
          : data.status === 'declined' && update
          ? [
              { value: 'Active', key: 'Active', text: 'Active' },
              { value: 'More', key: 'More', text: 'More' },
            ]
          : data.status === 'pending' && update
          ? [
              { value: 'More', key: 'More', text: 'More' },
              { value: 'Block', key: 'Block', text: 'Block' },
              { value: 'Active', key: 'Active', text: 'Active' },
            ]
          : [{ value: 'More', key: 'More', text: 'More' }],
      adActions: ['More'],
      bidActions:
        update && permissions.delete
          ? ['DELETE', 'EDIT']
          : update && !permissions.delete
          ? ['EDIT']
          : !update && permissions.delete
          ? ['DELETE']
          : [],
    });
  }, [data.status, update, permissions.delete]);

  const onChange = (e, info) => {
    if (info.value === 'View') {
      onViewModal(modalKey);
    }
    if (info.value === 'More') {
      setModalIsOpen(!isModalOpen);
    }
    if (info.value === 'Block') {
      campaignStatusUpdate(data.id || data.advertiser, data.type, 'declined');
    }
    if (info.value === 'Active') {
      campaignStatusUpdate(data.id || data.advertiser, data.type, 'active');
    }
    if (info.value === 'Approve') {
      campaignStatusUpdate(data.id || data.advertiser, data.type, 'approved');
    }
  };

  const handleClose = () => setModalIsOpen(false);

  return (
    <Menu compact>
      {type === 'campaigns' && (
        <Dropdown
          text="Actions"
          options={actions.campaignActions}
          onChange={(e, data) => onChange(e, data)}
          item
          simple
        />
      )}

      {type === 'user' && (
        <Dropdown
          text="Menu"
          options={actions.userActions}
          onChange={(e, data) => onChange(e, data)}
          item
          simple
        />
      )}

      {type === 'apps' && (
        <Dropdown
          text="Menu"
          options={actions.appsActions}
          onChange={(e, data) => onChange(e, data)}
          item
          simple
        />
      )}

      {isModalOpen && (
        <SemanticModal
          isOpen={isModalOpen}
          data={data}
          modalData={modalData && modalData}
          modalKey={modalKey && modalKey}
          handleClose={handleClose}
        />
      )}
    </Menu>
  );
};

const SemanticModal = ({ isOpen, modalData, modalKey, handleClose }) => {
  return (
    <Modal isOpen={isOpen} toggle={handleClose} style={{ color: 'black' }}>
      <ModalHeader>More Info</ModalHeader>
      <ModalBody style={{ color: 'black' }}>
        <List divided relaxed>
          <List.Item>
            <List.Content>
              {/* <List.Description as="a" className="mt-3 mb-3">
                Campaign ID : {modalData[modalKey].userId}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Platform</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].platform}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Global Type</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].globalType}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Type</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].adType}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Title</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].title}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Description as="a" className="mt-3 mb-3">
                Targeting Options
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Title</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].categoryTargeting}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Geo</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].geoTargeting}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">OS</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].osTargeting}
              </List.Description> */}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header as="a">Time</List.Header>
              {/* <List.Description as="a">
                {modalData[modalKey].timeTargeting}
              </List.Description> */}
            </List.Content>
          </List.Item>
        </List>
      </ModalBody>
    </Modal>
  );
};
export default ActionsMenu;
