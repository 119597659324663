import React, { Component } from 'react';
import "./AddMoneyModal.scss"

class AddMoneyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            dropDown: true,
            id:"",
            amount: '',
            description: ''
        };
        this.timer=0
    }
    onEmailChange=(value)=>{
        this.setState({email: value,dropDown: true})
        clearTimeout(this.timer)
        if(value.length>2){
            this.timer = setTimeout(() => {
                this.props.userAutocomplite({
                    provider: this.props.DashboardReducer.optionsData.provider,
                    search: {
                        type: "email",
                        value: value
                    }
                })
            },300);
        }
    }
    renderAutocomliteData=(data)=>{
        let li = []
        data.map((item,key)=>{
            li.push(<li key={key} onClick={()=>this.onSelectUser(item.id,item.email)}>
               {item.email}
            </li>)
        })
        return li
    }
    onSelectUser=(id, email)=>{
        this.setState({
            id: id,
            dropDown: false,
            email: email
        })
    }
    onChange=(key,value)=>{
        if(key==="amount"){
            this.setState({amount: value.replace(/\D/g,'')})
        }
        if(key==="description"){
            this.setState({description: value})
        }
    }
    onAdd=()=>{
        let body={
            userId: this.state.id,
            amount: +this.state.amount,
            description: this.state.description
        }
        this.props.onAddMoney(body)
    }
    render() {
        return (
            <div className="modalContainer">
                <div className="AddMoneyContent">
                    <div className="modalTitle">
                        <h6 className="Title">
                            Add Money
                            <button onClick={() => this.props.onModalClose()}><i className="fa fa-close"></i></button>
                        </h6>
                    </div>
                    <div className="modalBody">
                        <div className="form-group" style={{position: "relative"}}>
                            <small>Email</small>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.email}
                                onChange={(e)=>this.onEmailChange(e.target.value)}
                            />
                            {this.props.autocompliteData.length>0 && this.state.dropDown && <div className="autoComplite">
                                <ul>
                                    {this.renderAutocomliteData(this.props.autocompliteData)}
                                </ul>
                            </div>}
                        </div>
                        <div className="form-group">
                            <small>Amount</small>
                            <input 
                                type="text"
                                value={this.state.amount}
                                className="form-control"
                                onChange={(e)=>this.onChange("amount",e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <small>Description</small>
                            <textarea className="form-control" onChange={(e)=>this.onChange("description",e.target.value)}></textarea>
                        </div>
                        <button 
                            className="btn btn-success" 
                            style={{width: "100%"}} 
                            onClick={()=>this.onAdd()}
                            disabled={!this.state.id || !this.state.amount || !this.state.description}
                        >
                            Add</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddMoneyModal;
