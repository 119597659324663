import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import {
  getAdvertisers,
  getAdvertisersLast,
  resetAdvertiserReducer,
  getAdvertisersCampaigns,
} from '../../store/actions/AdvertisersActions';
import AdvertisersComponent from '../../components/AdvertisersComponent/AdvertisersComponent';
import { withRouter } from 'react-router-dom';
import { getCountries } from '../../config/getCountries';

const AdvertisersContainer = ({
  resetReducer,
  DashboardReducer: {
    optionsData: {
      options: { logo },
      provider
    },
    permissionsFetched,
  },
  getAdvertisers,
  AdvertisersReducer,
  getAdvertisersLast,
  data,
  pending,
  filtered,
  status,
}) => {
  const [dataMapped, setDataMapped] = useState(null);

  useEffect(() => {
    getAdvertisers({ type: 'adminDate', provider, filter: { userType: ['advertiser'] } });
    return () => {
      resetReducer();
    };
  }, [getAdvertisers, resetReducer]);

  useEffect(() => {
    const countries = getCountries();
    const mapped = data.map(it => {
      const item = { ...it };
      item.country &&
        countries.forEach(country => {
          if (item.country === country.code) {
            item.country = country.name;
          }
        });
      return item;
    });
    setDataMapped([...mapped]);
  }, [data]);

  return (
    <Fragment>
      <InnerPagesComponent pageName="Advertiser" logo={logo}>
        {permissionsFetched && (
          <AdvertisersComponent
            resetReducer={resetReducer}
            getAdvertisers={getAdvertisers}
            AdvertisersReducer={AdvertisersReducer}
            getAdvertisersLast={getAdvertisersLast}
            data={dataMapped}
            pending={pending}
            status={status}
            filtered={filtered}
            provider={provider}
          />
        )}
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  AdvertisersReducer: store.AdvertisersReducer,
  DashboardReducer: store.DashboardReducer,
  data: store.AdvertisersReducer.data,
  pending: store.AdvertisersReducer.pending,
  filtered: store.AdvertisersReducer.filtered,
  status: store.AdvertisersReducer.status,
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: _ => dispatch(resetAdvertiserReducer()),
  getAdvertisers: body => dispatch(getAdvertisers(body)),
  getAdvertisersLast: body => dispatch(getAdvertisersLast(body)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AdvertisersContainer)
);
