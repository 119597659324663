import {
  APPS,
  APPS_UPDATE,
  APPS_ADSPACES,
  APPS_LAST,
  INFINITE_APPS_SCROLL,
  RESET_ADSPACES,
} from '../constants';
import { handleActions } from 'redux-actions';

const defaultState = {
  appsPending: false,
  appsFetched: false,
  appsError: null,
  appsData: [],
  pagination: null,
  loader: false,
  updatedItem: null,

  appsStatusPending: false,
  appsStatusFetched: false,
  appsStatusError: null,

  adSpacesPending: false,
  adSpacesData: [],
  adSpacesFetched: false,
  adSpacesError: null,
  filtered: {
    items: null,
    pending: false,
  },
  resetTable: false,
};

const appsReducer = handleActions(
  {
    [INFINITE_APPS_SCROLL.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
    }),

    [INFINITE_APPS_SCROLL.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      appsData: [...state.appsData, ...payload.Items],
      loader: false,
    }),

    [APPS.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      appsPending: true,
      appsFetched: false,
      appsData: [],
      appsStatusFetched: false,
    }),

    [APPS.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      appsPending: false,
      appsFetched: true,
      appsData: [...state.appsData, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [APPS_LAST.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      appsPending: false,
      appsFetched: true,
      appsData: [...state.appsData, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [APPS.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      appsPending: false,
      appsFetched: false,
      appsError: error,
      appsData: [],
      pagination: null,
      loader: false,
    }),

    [APPS_UPDATE.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      appsStatusPending: true,
      appsStatusFetched: false,
    }),

    [APPS_UPDATE.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      appsStatusPending: false,
      appsStatusFetched: true,
      loader: false,
      updatedItem: { ...payload.Attributes },
    }),

    [APPS_UPDATE.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      appsStatusPending: false,
      appsStatusFetched: false,
      appsStatusError: error,
    }),

    [APPS_ADSPACES.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      adSpacesPending: true,
      adSpacesFetched: false,
    }),

    [APPS_ADSPACES.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      appsStatusPending: false,
      appsStatusFetched: true,
      loader: false,
      updatedItem: { ...payload.Attributes },
    }),

    [APPS_ADSPACES.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      adSpacesPending: false,
      adSpacesFetched: false,
      adSpacesError: error,
    }),

    [RESET_ADSPACES]: (state = defaultState) => ({ ...defaultState }),
  },
  defaultState
);

export default appsReducer;
