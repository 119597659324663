import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import DashboardComponent from '../../components/DashboardComponent/DashboardComponent';
import {
  getDashboardData,
  getTrafic,
} from '../../store/actions/DashboarActions';
import Loader from '../../shared/Loader';
import { withRouter } from 'react-router-dom';

class DashboardContainer extends Component {
  componentDidMount() {
    const { getDashboardData, optionsData, getTrafic } = this.props;
    getDashboardData({
      provider:
        optionsData.provider === 'localhost'
          ? 'adlooker'
          : optionsData.provider,
    });
    getTrafic({
      provider:
        optionsData.provider === 'localhost'
          ? 'adlooker'
          : optionsData.provider,
    });
  }

  render() {
    const { optionsData, dashboardData, traficData } = this.props;

    return (
      <div>
        <InnerPagesComponent
          pageName="Dashboard"
          logo={optionsData.options.logo}
        >
          {dashboardData.pending && <Loader />}
          {traficData.pending && <Loader />}
          {dashboardData.fetched && traficData.fetched && (
            <DashboardComponent
              dashboardData={dashboardData}
              traficData={traficData}
            />
          )}
        </InnerPagesComponent>
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  optionsData: store.DashboardReducer.optionsData,
  dashboardData: store.DashboardReducer.dashboardData,
  traficData: store.DashboardReducer.traficData,
});

const mapDispatchFromProps = dispatch => ({
  getDashboardData: data => dispatch(getDashboardData(data)),
  getTrafic: data => dispatch(getTrafic(data)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(DashboardContainer)
);
