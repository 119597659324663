import CommonAction from '../../shared/Http/Http';
import {
  APPS,
  APPS_UPDATE,
  APPS_ADSPACES,
  APPS_LAST,
  INFINITE_APPS_SCROLL,
  RESET_ADSPACES,
} from '../constants';

export const AppsTable = body =>
  CommonAction('POST', null, `backoffice-extended/apps/data`, APPS, body);
export const AppsTableLast = body =>
  CommonAction('POST', null, `backoffice-extended/apps/data`, APPS_LAST, body);
export const AppsStatusUbdate = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/apps/update-status`,
    APPS_UPDATE,
    body
  );
export const AppsAdspaces = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/adspaces/data`,
    APPS_ADSPACES,
    body
  );
export const resetAdReducer = () => ({ type: RESET_ADSPACES });

export const infiniteAppsScroll = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/apps/data`,
    INFINITE_APPS_SCROLL,
    {
      ...body,
      limit: 20,
    }
  );
