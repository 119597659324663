import CommonAction from '../../shared/Http/Http';
import {
  USERS,
  USERS_UPDATE,
  USERS_LAST,
  FILTER_USERS,
  INFINITE_USERS_SCROLL,
  RESET_USERS_TABLE,
  RESET_USERS_REDUCER,
} from '../constants';

export const UsersTable = body =>
  CommonAction('POST', null, `backoffice/users`, USERS, { ...body, limit: 20 });

export const UsersTableLastKey = body =>
  CommonAction('POST', null, `backoffice/users`, USERS_LAST, {
    ...body,
    limit: 20,
  });

export const filterUsers = body =>
  CommonAction('POST', null, `backoffice/users`, FILTER_USERS, {
    ...body,
    limit: 20,
  });

export const UsersStatusUbdate = body =>
  CommonAction('POST', null, `backoffice/users/update-status`, USERS_UPDATE, {
    ...body,
    limit: 20,
  });

export const infiniteUsersScroll = body =>
  CommonAction('POST', null, `backoffice/users`, INFINITE_USERS_SCROLL, {
    ...body,
    limit: 20,
  });

export const resetUsersTable = () => ({ type: RESET_USERS_TABLE });

export const resetUsersReducer = () => ({ type: RESET_USERS_REDUCER });
