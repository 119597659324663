import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import './GeoChart.scss';
import Countries from '../../../config/countries.json';

const data = [['Country', 'Sessions']];
class GeoChart extends Component {
  componentWillMount() {
    this.props.topCountries.map(item => {
      data.push([item.country, item.count]);
    });
  }
  renderCountries = () => {
    const { topCountries } = this.props;

    const data = [];
    topCountries.map(item =>
      Countries.countries.map(country => {
        return (
          item.country === country.code &&
          data.push(
            <p>
              {country.name}: <span>{item.count}</span>
            </p>
          )
        );
      })
    );
    return data;
  };
  render() {
    return (
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <div className="todays-boxes">
              <p className="titile">Where are your customers?</p>
              <p className="number">Sessions by country</p>
            </div>
          </div>
          <div className="col-md-12">
            <Chart
              chartType="GeoChart"
              width="100%"
              height={'451px'}
              data={data}
              options={{
                colorAxis: { colors: ['blue'] },
              }}
            />
            <div
              className="chart-footer"
              style={{ backgroundColor: '#FFF', padding: '10px' }}
            >
              <p style={{ margin: '0' }}>Your Traffic for Last 7 Days</p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="top5countries">
              <p>Top Countries</p>
              {this.renderCountries()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeoChart;
