import { useState } from 'react';
import { getCountries } from '../../config/getCountries';

export const useTypes = () => {
  const [campaigns] = useState({
    type: [
      { value: 'videoAd', text: 'Video Ad', key: 'videoAd' },
      { value: 'sceneAd', text: 'Scene Ad', key: 'sceneAd' },
      { value: '3dObject', text: '3D Object', key: '3dObject' },
      { value: 'flatVideo', text: 'Flat Banner', key: 'flatBanner' },
    ],
    status: [
      { value: 'active', text: 'Active', key: 'active' },
      { value: 'approved', text: 'Approved', key: 'approved' },
      { value: 'pending', text: 'Pending', key: 'pending' },
      { value: 'paused', text: 'Paused', key: 'paused' },
    ],
  });
  const [users] = useState({
    country: getCountries(),
    status: [
      { value: 'approved', text: 'Approved', key: 'approved' },
      { value: 'pending', text: 'Pending', key: 'pending' },
      { value: 'declined', text: 'Declined', key: 'declined' },
    ],
    type: [
      { value: 'advertiser', text: 'Advertiser', key: 'advertiser' },
      { value: 'publisher', text: 'Publisher', key: 'publisher' },
      { value: 'both', text: 'Both', key: 'both' },
    ],
  });
  const [apps] = useState({
    media_type: [
      { value: 'games', text: 'Games', key: 'games' },
      { value: 'apps', text: 'Apps', key: 'apps' },
    ],
    category: [
      { value: 'business', text: 'Business', key: 'business' },
      { value: 'creativity', text: 'Creativity', key: 'creativity' },
      { value: 'education', text: 'Education', key: 'education' },
      { value: 'entertainment', text: 'Entertainment', key: 'entertainment' },
      { value: 'lifestyle', text: 'Lifestyle', key: 'lifestyle' },
      { value: 'news', text: 'News', key: 'news' },
      { value: 'productivity', text: 'Productivity', key: 'productivity' },
      { value: 'social', text: 'Social', key: 'social' },
      { value: 'shopping', text: 'Shopping', key: 'shopping' },
    ],
    genre: [
      { value: '360video', text: '360° video', key: '360video' },
      { value: 'action', text: 'Action', key: 'action' },
      { value: 'adventure', text: 'Adventure', key: 'adventure' },
      { value: 'casual', text: 'Casual', key: 'casual' },
      { value: 'comedy', text: 'Comedy', key: 'comedy' },
      { value: 'creativeStory', text: 'Creative story', key: 'Creative story' },
      { value: 'educational', text: 'Educational', key: 'educational' },
      { value: 'horror', text: 'Horror', key: 'horror' },
      {
        value: 'interactiveVideo',
        text: 'Interactive video',
        key: 'interactiveVideo',
      },
      { value: 'liveEvent', text: 'Live event', key: 'liveEvent' },
      { value: 'medical', text: 'Medical', key: 'medical' },
      { value: 'music', text: 'Music', key: 'music' },
      { value: 'racing', text: 'Racing', key: 'racing' },
      { value: 'realEstate', text: 'Real Estate', key: 'realEstate' },
      { value: 'RPG', text: 'RPG', key: 'RPG' },
      { value: 'science', text: 'Science', key: 'science' },
      { value: 'shooter', text: 'Shooter', key: 'shooter' },
      { value: 'sports', text: 'Sports', key: 'sports' },
      { value: 'simulation', text: 'Simulation', key: 'simulation' },
      { value: 'strategy', text: 'Strategy', key: 'strategy' },
      { value: 'tools', text: 'Tools', key: 'tools' },
      { value: 'travel', text: 'Travel', key: 'travel' },
      { value: 'war', text: 'War', key: 'war' },
    ],
    status: [
      { value: 'active', text: 'Active', key: 'active' },
      { value: 'pending', text: 'Pending', key: 'pending' },
      { value: 'declined', text: 'Declined', key: 'declined' },
      { value: 'deleted', text: 'Deleted', key: 'deleted' },
    ],
  });

  return [campaigns, apps, users];
};
