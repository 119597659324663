import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import {resetReducer} from '../../store/actions/resetReducerAction';
import {getLogs,getLogsLast} from '../../store/actions/LogsActions';
import { withRouter } from 'react-router-dom';
import LogsComponent from '../../components/LogsComponent/LogsComponent'

class LogsContainer extends Component {
    componentWillUnmount(){
        this.props.resetReducer("RESET_LOGS_REDUCER")
    }
    render() {
        return (
            <div>
                <InnerPagesComponent pageName="" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.DashboardReducer.permissionsFetched && <LogsComponent
                        getLogs={this.props.getLogs}
                        DashboardReducer={this.props.DashboardReducer}
                        logsReducer={this.props.logsReducer}
                        getLogsLast={this.props.getLogsLast}
                        resetReducer={this.props.resetReducer}
                    />}
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    PotentialsReducer: store.PotentialsReducer,
    DashboardReducer: store.DashboardReducer,
    logsReducer: store.logsReducer
});

const mapDispatchFromProps = dispatch => ({
    resetReducer: (name) =>(dispatch(resetReducer(name))),
    getLogs: (body) => (dispatch(getLogs(body))),
    getLogsLast: (body) => (dispatch(getLogsLast(body)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(LogsContainer));
