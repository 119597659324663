import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import {resetReducer} from '../../store/actions/resetReducerAction';
import { withRouter } from 'react-router-dom';
import TenatsUpdateComponent from '../../components/TenantsUpdateComponent/TenantsUpdateComponent'
import {editTenant} from '../../store/actions/TenantUpdateActions'

class TenantsUpdateContainer extends Component {
    componentWillUnmount(){
        this.props.resetReducer("RESET_LOGS_REDUCER")
    }
    render() {
        return (
            <div>
                <InnerPagesComponent pageName="Website Settings" logo={this.props.DashboardReducer.optionsData.options.logo}>
                   <TenatsUpdateComponent
                        DashboardReducer={this.props.DashboardReducer}
                        editTenant={this.props.editTenant}
                        TenantUpdateReducer={this.props.TenantUpdateReducer}
                   />
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
  DashboardReducer: store.DashboardReducer,
  TenantUpdateReducer: store.TenantUpdateReducer
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: (name) =>(dispatch(resetReducer(name))),
  editTenant: (body) => (dispatch(editTenant(body)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(TenantsUpdateContainer));
