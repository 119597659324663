import React from 'react';
import { PropTypes } from 'prop-types';
import './btn.scss';

const Button = React.memo(props => {
    const {
        className,
        onClick,
        disabled,
    } = props;
    return (
        <button
            type='button'
            className={className}
            onClick={onClick}
            disabled={disabled}
        >
            {props.children}
        </button>
    );
});

Button.propTypes = {
    className: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    // formInvalid: PropTypes.bool.isRequired
}

export default Button;
    
