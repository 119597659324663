import React, { Component } from 'react';
import Countries from '../../config/countries.json';
import moment from 'moment';
import './MoreModal.scss';

class MoreModal extends Component {
  render() {
    return (
      <div className="modalContainer" onClick={() => this.props.onModalClose()}>
        <div className="modalContent">
          <div className="modalTitle">
            <h6 className="Title">
              More Info
              <button onClick={() => this.props.onModalClose()}>
                <i className="fa fa-close" />
              </button>
            </h6>
          </div>
          {this.props.type === 'user' && (
            <div className="modalBody">
              <table className="table table-bordered">
                <tbody>
                  <tr className="text-center">
                    <td colSpan="2">ID: {this.props.data.id}</td>
                  </tr>
                  <tr>
                    <td>First Name</td>
                    <td>
                      {this.props.data.first_name && this.props.data.first_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>
                      {this.props.data.last_name && this.props.data.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Account type</td>
                    <td>{this.props.data.type}</td>
                  </tr>
                  <tr>
                    <td>Register Date</td>
                    <td />
                  </tr>
                  <tr>
                    <td>Approve/decline date</td>
                    <td>...</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>
                      {this.props.data.country &&
                        Countries.countries.map(item => {
                          if (item.code === this.props.data.country)
                            return item.name;
                        })}
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{this.props.data.city && this.props.data.city}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {this.props.data.address && this.props.data.address}
                    </td>
                  </tr>
                  <tr>
                    <td>Address2</td>
                    <td>
                      {this.props.data.address2 && this.props.data.address2}
                    </td>
                  </tr>
                  <tr>
                    <td>Zip code</td>
                    <td>
                      {this.props.data.postal_code &&
                        this.props.data.postal_code}
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{this.props.data.phone && this.props.data.phone}</td>
                  </tr>
                  <tr>
                    <td>Login count</td>
                    <td>...</td>
                  </tr>
                  <tr>
                    <td>Last login</td>
                    <td>...</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {this.props.type === 'compaigns' && (
            <div className="modalBody">
              <table className="table table-bordered">
                <tbody>
                  <tr className="text-center">
                    <td colSpan="2">ID: {this.props.data.id}</td>
                  </tr>
                  <tr>
                    <td>Platform</td>
                    <td>{this.props.data.platform}</td>
                  </tr>
                  <tr>
                    <td>Global Type</td>
                    <td>{this.props.data.globalType[0]}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{this.props.data.adType}</td>
                  </tr>
                  <tr>
                    <td>Title</td>
                    <td>{this.props.data.title}</td>
                  </tr>
                  <tr className="text-center">
                    <td colSpan="2">Targeting Options</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{this.props.data.categoryTargeting}</td>
                  </tr>
                  <tr>
                    <td>Geo</td>
                    <td>{this.props.data.geoTargeting}</td>
                  </tr>
                  <tr>
                    <td>OS</td>
                    <td>{this.props.data.osTargeting}</td>
                  </tr>
                  <tr>
                    <td>Time</td>
                    <td>{this.props.data.timeTargeting}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {this.props.type === 'apps' && (
            <div className="modalBody">
              <table className="table table-bordered">
                <tbody>
                  <tr className="text-center">
                    <td colSpan="2">ID: {this.props.data.app_id}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{this.props.data.name}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{this.props.data.category}</td>
                  </tr>
                  <tr>
                    <td>Genre</td>
                    <td>{this.props.data.genre}</td>
                  </tr>
                  <tr>
                    <td>Media Type</td>
                    <td>{this.props.data.media_type}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{this.props.data.status}</td>
                  </tr>
                  <tr>
                    <td>Url</td>
                    <td>{this.props.data.url}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td>{moment(this.props.data.created_at).format('ll')}</td>
                  </tr>
                  <tr>
                    <td>Updated Date</td>
                    <td>{moment(this.props.data.updated_at).format('ll')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {this.props.type === 'ads' && (
            <div className="modalBody">
              <table className="table table-bordered">
                <tbody>
                  <tr className="text-center">
                    <td colSpan="2">ID: {this.props.data.adspace_id}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{this.props.data.name}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{this.props.data.category}</td>
                  </tr>
                  <tr>
                    <td>Platform</td>
                    <td>{this.props.data.platform}</td>
                  </tr>
                  <tr>
                    <td>Mode</td>
                    <td>{this.props.data.arMode}</td>
                  </tr>
                  <tr>
                    <td>SDK</td>
                    <td>{this.props.data.headset_sdk}</td>
                  </tr>
                  <tr>
                    <td>Platform</td>
                    <td>{this.props.data.platform}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{this.props.data.status}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{this.props.data.type}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td>{moment(this.props.data.created_at).format('ll')}</td>
                  </tr>
                  <tr>
                    <td>Updated Date</td>
                    <td>{moment(this.props.data.updated_at).format('ll')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {this.props.type === 'Payment' && (
            <div className="modalBody">
              <table className="table table-bordered">
                <tbody>
                  <tr className="text-center">
                    <td colSpan="2">Email: {this.props.data.email}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>
                      {this.props.data.country &&
                        Countries.countries.map(item => {
                          if (item.code === this.props.data.country)
                            return item.name;
                        })}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{this.props.data.amount}</td>
                  </tr>
                  <tr>
                    <td>Amount Type</td>
                    <td>{this.props.data.amountType}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{this.props.data.status}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td>{moment(this.props.data.created_at).format('ll')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MoreModal;
