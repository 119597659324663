import React, { Component } from 'react';
import Loader from '../../shared/Loader';
import Table from './Table/Table';
import PermisionModal from './PermisionModal/PermisionModal';
import CreateModal from './CreateModal/CreateModal'

class AdminsComponent extends Component {
    state = {
        permissionModal: false,
        createModal: false,
    };

    componentWillMount() {
        let provider = this.props.DashboardReducer.optionsData.provider;
        this.props.adminsGet({ provider: provider })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.AdminsReducer.permissionsFetched !== this.props.AdminsReducer.permissionsFetched && nextProps.AdminsReducer.permissionsFetched){
            let provider = this.props.DashboardReducer.optionsData.provider
            this.props.adminsGet({ provider: provider })
        }
    }

    onPermisionModalOpen = (key) => {
        this.setState({
            index: key,
            permissionModal: true,
            permissionData: this.props.AdminsReducer.adminsData[key]
        })
    };

    onPermisionModalClose = () => {
        this.setState({
            index: null,
            permissionModal: false,
            permissionData: null
        });
    };

    onChangePermisions = (key, name) => {
        let permissionData = this.state.permissionData
        if (permissionData.permissions[key][name]) {
            permissionData.permissions[key][name] = false;
            this.setState({ permissionData })
            return
        }
        if (!permissionData.permissions[key][name]) {
            permissionData.permissions[key][name] = true
            this.setState({ permissionData })
            return
        }
    };

    onPermissionsUpdate = () => {
        let id = this.state.permissionData.id;
        let provider = this.props.DashboardReducer.optionsData.provider;
        let body = {
            permissions: this.state.permissionData.permissions,
            provider: provider
        };
        this.props.adminsUpdate(id,body);
        this.setState({
            index: null,
            permissionModal: false,
            permissionData: null
        })
    };

    onBlock = (item) => {
        if (item.status === "active") {
            this.props.adminsUpdate(item.id, {
              status: "blocked",
              provider: this.props.DashboardReducer.optionsData.provider
            });
        }
        if (item.status === "blocked"){
            this.props.adminsUpdate(item.id, {
              status: "active",
              provider: this.props.DashboardReducer.optionsData.provider
            });
        }
    };

    onOpenCreateModal = () => { this.setState({ createModal: true }) };

    onCloseCreateModal = () => { this.setState({ createModal: false }) };

    onAdminCreate = (body) => {
        this.setState({ createModal: false }, () => {
          this.props.createAdmin({ ...body, provider: this.props.DashboardReducer.optionsData.provider });
        });
    };

    onResetPassword = (item) => {
      this.props.resetPassword({
        provider: this.props.DashboardReducer.optionsData.provider,
        email: item.email
      });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.permissionModal &&
                    <PermisionModal
                        onModalClose={this.onPermisionModalClose}
                        data={this.state.permissionData}
                        onChangePermisions={this.onChangePermisions}
                        onPermissionsUpdate={this.onPermissionsUpdate}
                    />
                }
                {this.state.createModal &&
                    <CreateModal
                        onModalClose={this.onCloseCreateModal}
                        onAdminCreate={this.onAdminCreate}
                    />
                }
                <div className="content-header clearfix" style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                    <h1>ADMINS</h1>
                    {this.props.permissions.create && <button className="btn btn-success create-lead" type="button" onClick={()=>this.onOpenCreateModal()}>Create</button>}
                </div>
                {this.props.AdminsReducer.adminsPending && <Loader/>}
                {this.props.AdminsReducer.permissionsPending && <Loader/>}
                {this.props.AdminsReducer.createPending && <Loader/>}
                {this.props.AdminsReducer.resetPasswordPending && <Loader/>}
                <Table
                    AdminsReducer={this.props.AdminsReducer}
                    onPermisionModalOpen={this.onPermisionModalOpen}
                    onResetPassword={this.onResetPassword}
                    onBlock={this.onBlock}
                    permissions={this.props.permissions}
                />
            </React.Fragment>
        );
    }
}

export default AdminsComponent;
