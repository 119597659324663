import { GET_INCOMES } from '../constants';

const initialState = {
  pending: false,
  fetched: false,
  data: [],
  pagination: null,
  error: false,
  status: {
    updatedItem: null,
  },
  filtered: {
    items: [],
    pending: false,
    success: false,
  },
};

export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case GET_INCOMES.PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_INCOMES.SUCCESS:
      return {
        ...state,
        data: [...payload],
        success: true,
        pending: false,
      };
    case GET_INCOMES.ERROR:
      return {
        ...state,
        data: [],
        success: false,
        pending: false,
        error,
      };
    case 'RESET_INCOMES_REDUCER':
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
