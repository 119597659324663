import React, { Component } from 'react';
import './Table.scss';
import moment from 'moment';
import Loader from 'react-loader-spinner'

class Table extends Component {
    renderTableBody = () => {
        let body = []
        this.props.logsReducer.logsData.map((item, key) => {
            return body.push(
                <div className="my-table-row" key={key}>
                    <div className="my-table-column  border-bottom">{item.email}</div>
                    <div className="my-table-column  border-bottom">{item.actionType}</div>
                    <div className="my-table-column  border-bottom">{moment(item.created_at).format('lll')}</div>
                    <div className="my-table-column  border-bottom">{item.description}</div>
                </div>)
        })
        return body
    }
    onScrollTop = () => {
        let div = document.getElementById("scroledDiv");
        let bottom = div.scrollHeight - div.scrollTop
        if(bottom===500 && this.props.logsReducer.pagination){
            let body={
                provider: this.props.DashboardReducer.optionsData.provider,
                LastEvaluatedKey: this.props.logsReducer.pagination
            }
            this.props.getLogsLast(body)
        }
        if(this.props.search && bottom===500 && this.props.logsReducer.pagination){
            let body={
                provider: this.props.DashboardReducer.optionsData.provider,
                LastEvaluatedKey: this.props.logsReducer.pagination,
                email: this.props.search
            }
            this.props.getLogsLast(body)
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="logs shadow-widget table-widget">
                    <div className="my-table">
                        <div className="my-table-header">
                            <div className="my-table-row border-bottom">
                                <div className="my-table-column">Email</div>
                                <div className="my-table-column">Action Type</div>
                                <div className="my-table-column">Date</div>
                                <div className="my-table-column">Description</div>
                            </div>
                        </div>
                        <div className="my-table-body" id="scroledDiv" onScroll={() => this.onScrollTop()}>
                            { this.renderTableBody()}
                        </div>
                        {this.props.logsReducer.logsPending && <div style={{textAlign: "center"}}><Loader type="Bars" color="#00BFFF" height="50" width="40"/></div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;
