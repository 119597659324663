import React, { Component } from 'react';
import './BidByCountryComponent.scss';
import Create from './Create/Create';
import Countries from "../../config/countries.json";
import ActionsMenu from '../../shared/Table/ActionsMenu/ActionsMenu';
import Modal from './Modal/Modal'

class BidByCountryComponent extends Component {
    state = {
        id: null,
        value: null,
        createNew: false,
        country: '',
        CPM: '',
        display: "none",
        modal: false,
        key: ''
    };
    componentWillMount() {
        this.setState({
            id: this.props.bidData[0].id,
            value: this.props.bidData[0].value
        })
    }
    onOpenActions = () => {
        if (this.state.display === "block") this.setState({ display: "none" });
        if (this.state.display === "none") this.setState({ display: "block" })
    }
    onDelete = (code) => {
        let value = this.state.value;
        delete value[code];
        let body = {
            id: this.state.id,
            value: value
        };
        this.props.editBid([body])
    }
    createTableData = () => {
        let data = []
        for (let key in this.state.value) {
            data.push(
                <React.Fragment>
                    <div className="my-table-column  border-bottom">{this.state.value[key].countryName}</div>
                    <div className="my-table-column  border-bottom">{this.state.value[key].minBidCPM}</div>
                    <div className="my-table-column  border-bottom">
                        <ActionsMenu
                            index={key}
                            onDelete={this.onDelete}
                            onMoreModal={this.onMoreModal}
                            Permissions={this.props.bidPermissions}
                        />
                    </div>
                </React.Fragment>
            )
        }
        return <div className="my-table-row">{data}</div>
    }
    onCreate = () => {
        this.setState({
            createNew: true,
            country: '',
            CPM: ''
        })
    }
    cancelCreate = () => {
        this.setState({
            createNew: false,
            country: '',
            CPM: ''
        })
    }
    onChangeCreate = (key, value) => {
        if (key === "country") {
            this.setState({ country: value })
        }
        if (key === "CPM") {
            this.setState({ CPM: value.replace(/\D/g, '') })
        }
    }
    onSave = () => {
        let value = this.state.value;
        let countryName = ''
        Countries.countries.map(country => {
            if (country.code === this.state.country) {
                countryName = country.name
            }
        })
        value[this.state.country] = {
            code: this.state.country,
            minBidCPM: +this.state.CPM,
            countryName: countryName
        }
        let body = {
            id: this.state.id,
            value: value
        }
        this.props.editBid([body])
    }
    onMoreModal = (key) => {
        this.setState({
            modal: true,
            key: key
        })
    }
    onModalClose = () => {
        this.setState({
            modal: false
        })
    }
    onEdit=()=>{
        let value= this.state.value;
        value[this.state.key].minBidCPM = +this.state.CPM;
        let body = {
            id: this.state.id,
            value: value
        }
        this.props.editBid([body])
    }
    render() {
        return (
            <React.Fragment>
                <div className="content-header clearfix" style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                    <h1>MINBID BY COUNTRY</h1>
                    {this.props.bidPermissions.create && <button className="btn btn-success create-lead" type="button" onClick={() => this.onCreate()}>Create</button>}
                </div>
                {   
                    this.state.modal && 
                    <Modal
                        onModalClose={this.onModalClose}
                        CPM={this.state.CPM}
                        onChangeCreate={this.onChangeCreate}
                        onEdit={this.onEdit}
                    />
                }
                {
                    this.state.createNew &&
                    <Create
                        cancelCreate={this.cancelCreate}
                        country={this.state.country}
                        CPM={this.state.CPM}
                        onChangeCreate={this.onChangeCreate}
                        onSave={this.onSave}
                    />
                }
                <div className="container-fluid">
                    <div className="shadow-widget table-widget minBid">
                        <div className="my-table">
                            <div className="my-table-header">
                                <div className="my-table-row">
                                    <div className="my-table-column  border-bottom">Country</div>
                                    <div className="my-table-column  border-bottom">Minbid CPM</div>
                                    <div className="my-table-column  border-bottom">Actions</div>
                                </div>
                            </div>
                            <div className="my-table-body">
                                {this.createTableData()}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BidByCountryComponent;
