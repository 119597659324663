import { TENANT_UPDATE } from '../constants';

const defaultState = {
  tenantPending: false,
  tenantFetched: false,
  tenantError: null,
}

const TenantUpdateReducer = (state = defaultState, action) => {
  const { type, error } = action;
  switch (type) {
    case TENANT_UPDATE.PENDING: {
      return {
        ...state,
        tenantPending: true,
        tenantFetched: false,
      }
    }
    case TENANT_UPDATE.SUCCESS: {
      return {
        ...state,
        tenantPending: false,
        tenantFetched: true,
      }
    }
    case TENANT_UPDATE.ERROR: {
      return {
        ...state,
        tenantPending: false,
        tenantFetched: false,
        tenantError: error,
      }
    }
    default: return state;
  }
}

export default TenantUpdateReducer;
