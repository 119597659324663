import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import { resetReducer } from '../../store/actions/resetReducerAction';
import { adminsGet, adminsUpdate, createAdmin, resetPassword } from '../../store/actions/AdminsActions'
import AdminsComponent from '../../components/AdminsComponent/AdminsComponent'

class AdminsContainer extends Component {
    componentWillUnmount(){
        this.props.resetReducer("RESET_ADMINS_REDUCER")
    }
    render() {
        return (
            <div>
                <InnerPagesComponent pageName="" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.DashboardReducer.permissionsFetched && <AdminsComponent
                        adminsGet={this.props.adminsGet}
                        DashboardReducer={this.props.DashboardReducer}
                        AdminsReducer={this.props.AdminsReducer}
                        adminsUpdate={this.props.adminsUpdate}
                        createAdmin={this.props.createAdmin}
                        resetPassword={this.props.resetPassword}
                        permissions={this.props.DashboardReducer.permissionsData.adminUsers}
                    />}
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    DashboardReducer: store.DashboardReducer,
    AdminsReducer: store.AdminsReducer
});

const mapDispatchFromProps = dispatch => ({
    resetReducer: (name) =>(dispatch(resetReducer(name))),
    adminsGet: (body) => (dispatch(adminsGet(body))),
    adminsUpdate: (id,body) => (dispatch(adminsUpdate(id,body))),
    createAdmin: (body) => (dispatch(createAdmin(body))),
    resetPassword: (body) => (dispatch(resetPassword(body)))
});

export default connect(mapStateFromProps, mapDispatchFromProps)(AdminsContainer);
