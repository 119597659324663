import React, { useState, useEffect } from 'react';
import Box from './Box/Box';

const BoxStats = ({
  dashboardData: {
    data: { fillRate, newUser, incomesCount, traffic },
  },
}) => {
  const [formattedFillRate, setFormattedFillRate] = useState('');

  /**
   * @param useEffect setting formatted fillRate value on mounting
   */
  useEffect(() => {
    setFormattedFillRate(() => `${fillRate} %`);
  }, [fillRate]);

  return (
    <div className="row">
      <div className="col-sm-3">
        <Box title="New Users" value={newUser} />
      </div>
      <div className="col-sm-3">
        <Box title="Today's Income" value={incomesCount} />
      </div>
      <div className="col-sm-3">
        <Box title="Today's Traffic" value={traffic} />
      </div>
      <div className="col-sm-3">
        <Box title="Today's Fill Rate" value={formattedFillRate} />
      </div>
    </div>
  );
};

export default BoxStats;
