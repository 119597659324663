import React, { Component } from 'react';
import Table from './Table/Table';
import MoreModal from '../../shared/Modal/MoreModal';
import AddMoneyModal from './AddMoneyModal/AddMoneyModal';
import {Link} from 'react-router-dom'

class WidrovalComponent extends Component {
    state = {
        search: "",
        moreModal: false,
        key: null,
        addMoneyModal: false,
    }
    componentWillMount() {
        this.props.getPayments({
            type: "payment"
        })
    }
    onSearchChange = (value) => {
        this.setState({ search: value })
    }
    onFilter = () => {
        this.props.resetReducer("RESET_PAYMENTS_REDUCER")
        let body = {
            type: "payment",
            search: {type: "email", value: this.state.search}
        }
        this.props.getPayments(body)
    }
    onMoreModal = (key) => {
        this.setState({
            moreModal: true,
            key: key
        })
    }
    onModalClose = () => {
        this.setState({
            moreModal: false,
            key: null
        })
    }
    onAddMoney=(body)=>{
        this.setState({addMoneyModal: false});
        this.props.AddMoney(body)
    }
    onOpenMoneyModal=()=>{this.setState({addMoneyModal: true})}
    onCloseMoneyModal=()=>{this.setState({addMoneyModal: false})}
    render() {
        return (
            <React.Fragment>
                <div className="content-header clearfix" style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                    <h1>PAYMENTS</h1>
                    <Link to="/admin/payments-settings" className="btn btn-success">Payments Settings</Link>
                </div>
                <div className="shadow-widget">
                    <div className="row pt-3">
                        <div className="col-md-2">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.search}
                                    onChange={(e) => this.onSearchChange(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div className="form-group">
                                <button className="btn btn-success" onClick={() => this.onFilter()}>Filter</button>
                            </div>
                        </div>
                        <div className="col-md-8"></div>
                        <div className="col-md-1">
                            <div className="form-group">
                                <button className="btn btn-success" onClick={() => this.onOpenMoneyModal()}>Add money</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    paymentsReducer={this.props.paymentsReducer}
                    getPaymentsLast={this.props.getPaymentsLast}
                    DashboardReducer={this.props.DashboardReducer}
                    search={this.state.search}
                    onMoreModal={this.onMoreModal}
                    getPaymentsApprove={this.props.getPaymentsApprove}
                />
                {this.state.moreModal && <MoreModal
                    type="Payment"
                    data={this.props.paymentsReducer.paymentsData[this.state.key]}
                    onModalClose={this.onModalClose}
                />}
                {this.state.addMoneyModal && 
                    <AddMoneyModal
                        onModalClose={this.onCloseMoneyModal}
                        userAutocomplite={this.props.userAutocomplite}
                        DashboardReducer={this.props.DashboardReducer}
                        autocompliteData={this.props.paymentsReducer.autocompliteData}
                        onAddMoney={this.onAddMoney}
                    />
                }
            </React.Fragment>
        );
    }
}

export default WidrovalComponent;
