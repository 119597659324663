import { POTENTIALS_GET } from '../constants';

const defaultState = {
  pending: false,
  fetched: false,
  data: [],
  pagination: null,
  error: false,
  status: {
    updatedItem: null,
  },
  filtered: {
    items: [],
    pending: false,
    success: false,
  },
};

const PotentialsReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case POTENTIALS_GET.PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case POTENTIALS_GET.SUCCESS: {
      return {
        ...state,
        pending: false,
        success: true,
        data: [...payload],
      };
    }
    case POTENTIALS_GET.ERROR: {
      return {
        ...state,
        pending: false,
        success: true,
        error,
      };
    }
    case 'RESET_POTENTIALS_REDUCER': {
      return {
        ...defaultState,
      };
    }
    default:
      return state;
  }
};

export default PotentialsReducer;
