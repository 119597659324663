import React, { Component } from 'react';
import Filter from '../../shared/Filters/Filter';
import Loader from '../../shared/Loader';
import Table from '../../shared/SemanticTable/Table';
import ViewModal from './ViewModal/ViewModal';
import './CampaignsComponent.scss';

class CampaignsComponent extends Component {
  state = {
    moreModal: false,
    viewModal: false,
    key: null,
    tableHeader: [
      {
        title: 'Advertiser',
        key: 'advertiser',
      },
      {
        title: 'Title',
        key: 'title',
      },
      {
        title: 'Ad Type',
        key: 'adType',
      },
      {
        title: 'Daily Budget',
        key: 'isDailyBudget',
      },
      {
        title: 'Total Budget',
        key: 'budget',
      },
      {
        title: 'Status',
        key: 'status',
      },
      {
        title: 'Actions',
        key: 'actions',
      },
    ],
    filters: null,
  };

  mapData = () => {
    const { campaignsReducer } = this.props;
    return (
      campaignsReducer.campaignsData &&
      campaignsReducer.campaignsData.map(item => {
        return {
          advertiser: item.id,
          title: item.title,
          adType: item.adType === 'flatVideo' ? 'Flat Video' : item.adType,
          isDailyBudget: `${Number(item.isDailyBudget).toFixed(2)}$`,
          budget: `${Number(item.budget).toFixed(2)}$`,
          status: item.status,
        };
      })
    );
  };

  handleStatusEdit = (id, type, status) => {
    const { campaignsStatusUpdate } = this.props;
    campaignsStatusUpdate({ id, status, provider: this.props.provider });
  };

  onViewModal = key => this.setState({ viewModal: true, key: key });

  onViewModalClose = () => this.setState({ viewModal: false, key: null });

  getCampaignsFilterData = data => {
    if (data.search !== {}) {
      this.setState({ search: data.search });
    }
    if (data.filter !== {}) {
      this.setState({ filter: data.filter });
    }
  };

  onFilteredSearch = data => {
    const { filterCampaigns } = this.props;
    filterCampaigns({ ...data, provider: this.props.provider });
    this.setState({ filters: { ...data } });
  };

  render() {
    const {
      campaignsReducer: {
        campaignsData,
        loader,
        campaignsStatusPending,
        pagination,
      },
      campaignsReducer,
      fetchItems,
      campaignPermissions,
      filteredItems,
      resetCampaignsReducer,
      filteredItemsPending,
      resetTableState,
      infiniteScroll,
    } = this.props;

    const { key, viewModal, tableHeader } = this.state;

    return (
      <div className="container-fluid">
        {viewModal && (
          <ViewModal
            data={campaignsData[key]}
            onViewModalClose={this.onViewModalClose}
          />
        )}

        <Filter
          type="campaigns"
          filter={this.onFilteredSearch}
          isLoading={filteredItemsPending}
          fetchItems={fetchItems}
          resetTable={resetCampaignsReducer}
        />

        {loader && <Loader />}

        <Table
          cells={tableHeader}
          data={this.mapData()}
          modalData={campaignsData}
          filtered={filteredItems}
          action
          type="campaigns"
          permissions={campaignPermissions}
          onViewModal={this.onViewModal}
          onMoreModal={this.onMoreModal}
          dataUpdate={this.handleStatusEdit}
          isLoading={campaignsStatusPending}
          getItems={infiniteScroll}
          infinite
          pagination={pagination}
          dataStatus={campaignsReducer}
          resetTableState={resetTableState}
          filters={this.state.filters}
        />
      </div>
    );
  }
}

export default CampaignsComponent;
