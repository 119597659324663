import CommonAction from '../../shared/Http/Http';
import { PUBLISHERS_GET, PUBLISHERS_GET_LAST } from '../constants';

export const getPublishers = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/get/reports`,
    PUBLISHERS_GET,
    body
  );
export const getPublishersLast = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/get/reports`,
    PUBLISHERS_GET_LAST,
    body
  );

export const resetPublishersReducer = () => ({
  type: 'RESET_PUBLISHERS_REDUCER',
});
