import CommonAction from '../../shared/Http/Http';
import {
  ADVERTISERS_GET,
  ADVERTISERS_GET_LAST,
  RESET_ADVERTISER_REDUCER,
  ADVERTISERS_CAMPAIGNS_GET,
} from '../constants';

export const getAdvertisers = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/get/reports`,
    ADVERTISERS_GET,
    body
  );

export const getAdvertisersLast = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/get/reports`,
    ADVERTISERS_GET_LAST,
    body
  );
export const resetAdvertiserReducer = () => ({
  type: RESET_ADVERTISER_REDUCER,
});
