import React, { useEffect } from 'react';
import { Menu } from 'semantic-ui-react';

const Tabs = ({ getAdvertisers, activeItem, setActiveItem, provider }) => {
  useEffect(() => {
    if (activeItem === 'Campaign') {
      getAdvertisers({
        type: 'campaigns',
        filter: { userType: ['advertiser'] },
        provider
      });
    }
    if (activeItem === 'Date') {
      getAdvertisers({
        type: 'adminDate',
        filter: { userType: ['advertiser'] },
        provider
      });
    }
    if (activeItem === 'Country') {
      getAdvertisers({
        type: 'country',
        filter: { userType: ['advertiser'] },
        provider
      });
    }
  }, [activeItem, getAdvertisers]);
  return (
    <Menu pointing secondary color="blue">
      <Menu.Item
        name="Date"
        active={activeItem === 'Date'}
        onClick={() => setActiveItem('Date')}
      />
      <Menu.Item
        name="Campaign"
        active={activeItem === 'Campaign'}
        onClick={() => setActiveItem('Campaign')}
      />
      <Menu.Item
        name="Country"
        active={activeItem === 'Country'}
        onClick={() => setActiveItem('Country')}
      />
    </Menu>
  );
};

export default Tabs;
