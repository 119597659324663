import React, { Component } from 'react';
import './Table.scss';
import moment from 'moment';
import Loader from 'react-loader-spinner'

class Table extends Component {
    renderTableBody = () => {
        let body = []
        this.props.paymentsReducer.paymentsData.map((item, key) => {
            return body.push(
                <div className="my-table-row" key={key}>
                    <div className="my-table-column  border-bottom">{item.email}</div>
                    <div className="my-table-column  border-bottom">{item.amount/100}</div>
                    <div className="my-table-column  border-bottom">{item.withdrawalType}</div>
                    <div className="my-table-column  border-bottom">{moment(item.created_at).format('lll')}</div>
                    <div className="my-table-column  border-bottom">{item.status}</div>
                    <div className="my-table-column  border-bottom">
                        {item.status==="pending" && <button className="btn btn-sm btn-success" style={{marginRight: "5px"}} onClick={()=>this.props.getPaymentsApprove({status: 'approved', transactionId: item.transactionId})}>Approve</button>}
                        {item.status==="pending" && <button className="btn btn-sm btn-danger" style={{marginRight: "5px"}} onClick={()=>this.props.getPaymentsApprove({status: 'declined', transactionId: item.transactionId})}>Decline</button>}
                        <button className="btn btn-sm btn-secondary" onClick={()=>this.props.onMoreModal(key)}>More</button>
                    </div>
                </div>)
        })
        return body
    }
    onScrollTop = () => {
        let div = document.getElementById("scroledDiv");
        let bottom = div.scrollHeight - div.scrollTop
        if(bottom===500 && this.props.paymentsReducer.pagination){
            this.props.getPaymentsLast({
                type:"withdrawal",
                LastEvaluatedKey: this.props.paymentsReducer.pagination
            })
        }
        if(this.props.search && bottom===500 && this.props.paymentsReducer.pagination){
            this.props.getPaymentsLast({
                type:"withdrawal",
                LastEvaluatedKey: this.props.paymentsReducer.pagination,
                search: {type: "email", value: this.props.search}
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="payment shadow-widget table-widget">
                    <div className="my-table">
                        <div className="my-table-header">
                            <div className="my-table-row border-bottom">
                                <div className="my-table-column">Email</div>
                                <div className="my-table-column">Amount</div>
                                <div className="my-table-column">Withdrawal Type</div>
                                <div className="my-table-column">Date</div>
                                <div className="my-table-column">Status</div>
                                <div className="my-table-column">Actions</div>
                            </div>
                        </div>
                        <div className="my-table-body" id="scroledDiv" onScroll={() => this.onScrollTop()}>
                            { this.renderTableBody()}
                        </div>
                        {this.props.paymentsReducer.paymentsPending && <div style={{textAlign: "center", marginTop: "10px",marginBottom: "10px"}}><Loader type="Bars" color="#00BFFF" height="50" width="40"/></div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;
