import { PAYMENTS_GET, PAYMENTS_LAST_GET, PAYMENTS_APPROVE, AUTOCOMPLITE_USER, ADD_MONEY, GET_PAYMENTS_GATEWAYS,PAYMENTS_UPDATE} from '../constants';

const defaultState = {
    paymentsPending: false,
    paymentsFetched: false,
    paymentsError: null,
    paymentsData: [],
    pagination: null,
    paymentsPending: false,
    paymentsFetched: false,
    autocomplitePending: false,
    autocompliteFetched: false,
    autocompliteData: [],
    addMoneyPending: false,
    addMoneyFetched: false,
    paymentGatewaysData: {
        pending: false,
        fetched: false,
        data: null,
        error: null
    },
    paymentUpdateData: {
        pending: false,
        fetched: false,
        error: null
    }
}

const paymentsReducer = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case PAYMENTS_GET.PENDING: {
            return {
                ...state,
                paymentsPending: true,
                paymentsFetched: false,
            }
        }
        case PAYMENTS_GET.SUCCESS: {
            return {
                ...state,
                paymentsPending: false,
                paymentsFetched: true,
                paymentsData: payload.Items,
                pagination: payload.LastEvaluatedKey
            }
        }
        case PAYMENTS_LAST_GET.SUCCESS: {
            return {
                ...state,
                paymentsPending: false,
                paymentsFetched: true,
                paymentsData: [...state.paymentsData, ...payload.Items],
                pagination: payload.LastEvaluatedKey,
            }
        }
        case PAYMENTS_APPROVE.PENDING: {
            return {
                ...state,
                paymentsPending: true,
                paymentsFetched: false
            }
        }
        case PAYMENTS_APPROVE.SUCCESS: {
            return {
                ...state,
                paymentsPending: false,
                paymentsFetched: true
            }
        }
        case PAYMENTS_GET.ERROR: {
            return {
                ...state,
                paymentsPending: false,
                paymentsFetched: false,
                paymentsError: error,
                paymentsData: [],
                pagination: null,
            }
        }

        case AUTOCOMPLITE_USER.PENDING: {
            return {
                ...state,
                autocomplitePending: true,
                autocompliteFetched: false,
                autocompliteData: [],
            }
        }
        case AUTOCOMPLITE_USER.SUCCESS: {
            return {
                ...state,
                autocomplitePending: false,
                autocompliteFetched: true,
                autocompliteData: payload.Items,
            }
        }

        case ADD_MONEY.PENDING: {
            return {
                ...state,
                addMoneyPending: true,
                addMoneyFetched: false,
            }
        }
        case ADD_MONEY.SUCCESS: {
            return {
                ...state,
                addMoneyPending: false,
                addMoneyFetched: true,
            }
        }
        case GET_PAYMENTS_GATEWAYS.PENDING: {
            return {
                ...state,
                paymentGatewaysData: {
                    pending: true,
                    fetched: false,
                    data: null,
                    error: null
                }
            }
        }
        case GET_PAYMENTS_GATEWAYS.SUCCESS: {
            return {
                ...state,
                paymentGatewaysData: {
                    pending: false,
                    fetched: true,
                    data: payload.paymentGateways || payload.withdrawalGateways,
                    error: null
                }
            }
        }
        case GET_PAYMENTS_GATEWAYS.ERROR: {
            return {
                ...state,
                paymentGatewaysData: {
                    pending: false,
                    fetched: false,
                    data: null,
                    error: error
                }
            }
        }
        case PAYMENTS_UPDATE.PENDING: {
            return {
                ...state,
                paymentUpdateData: {
                    pending: true,
                    fetched: false,
                    error: null
                }
            }
        }
        case PAYMENTS_UPDATE.SUCCESS: {
            return {
                ...state,
                paymentUpdateData: {
                    pending: false,
                    fetched: true,
                    error: null
                }
            }
        }
        case PAYMENTS_UPDATE.ERROR: {
            return {
                ...state,
                paymentUpdateData: {
                    pending: false,
                    fetched: false,
                    error: error
                }
            }
        }
        case "RESET_PAYMENTS_REDUCER": {
            return {
                ...state,
                paymentsPending: false,
                paymentsFetched: false,
                paymentsError: null,
                paymentsData: [],
                pagination: null,
                paymentsPending: false,
                paymentsFetched: false,
                autocomplitePending: false,
                autocompliteFetched: false,
                autocompliteData: [],
                addMoneyPending: false,
                addMoneyFetched: false,
                paymentGatewaysData: {
                    pending: false,
                    fetched: false,
                    data: null,
                    error: null
                },
                paymentUpdateData: {
                    pending: false,
                    fetched: false,
                    error: null
                }
            }
        }
        default: return state;
    }
}

export default paymentsReducer;
