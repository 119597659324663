import { DEFAULT_OPTIONS_GET, DEFAULT_OPTIONS_EDIT } from '../constants';

const defaultState = {
  defaultPending: false,
  defaultFetched: false,
  defaultError: null,
  defaultData: [],
  editPending: false,
  editFetched: false,
  editError: null,
}

const DefaultOptionsReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case DEFAULT_OPTIONS_GET.PENDING: {
      return {
        ...state,
        defaultPending: true,
        defaultFetched: false,
        defaultData: [],
      }
    }
    case DEFAULT_OPTIONS_GET.SUCCESS: {
      return {
        ...state,
        defaultPending: false,
        defaultFetched: true,
        defaultData: payload,
      }
    }
    case DEFAULT_OPTIONS_GET.ERROR: {
      return {
        ...state,
        defaultPending: false,
        defaultFetched: false,
        defaultError: error,
        defaultData: [],
      }
    }
    case DEFAULT_OPTIONS_EDIT.PENDING: {
      return {
        ...state,
        editPending: true,
        editFetched: false,
      }
    }
    case DEFAULT_OPTIONS_EDIT.SUCCESS: {
      return {
        ...state,
        editPending: false,
        editFetched: true,
      }
    }
    case DEFAULT_OPTIONS_EDIT.ERROR: {
      return {
        ...state,
        editPending: false,
        editFetched: false,
        editError: error,
      }
    }
    case "RESET_DEFAULT_OPTIONS_REDUCER": {
      return {
        ...state,
        defaultPending: false,
        defaultFetched: false,
        defaultError: null,
        defaultData: [],
        editPending: false,
        editFetched: false,
        editError: null,
      }
    }
    default: return state;
  }
}

export default DefaultOptionsReducer;
