import React, { Component } from 'react';
import './PermisionModal.scss';

class PermisionModal extends Component {
  renderPermisions = data => {
    let permissions = [];
    for (let key in data.permissions) {
      permissions.push(
        <div key={key} className="row">
          <div className="col-sm-6">
            <p className="text-capitalize">{key}</p>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <span
                className={
                  data.permissions[key].create
                    ? 'action-types active-type'
                    : 'action-types deactive-type'
                }
                onClick={() => this.props.onChangePermisions(key, 'create')}
              >
                Create
              </span>
              <span
                className={
                  data.permissions[key].update
                    ? 'action-types active-type'
                    : 'action-types deactive-type'
                }
                onClick={() => this.props.onChangePermisions(key, 'update')}
              >
                Update
              </span>
              <span
                className={
                  data.permissions[key].read
                    ? 'action-types active-type'
                    : 'action-types deactive-type'
                }
                onClick={() => this.props.onChangePermisions(key, 'read')}
              >
                Read
              </span>
              <span
                className={
                  data.permissions[key].delete
                    ? 'action-types active-type'
                    : 'action-types deactive-type'
                }
                onClick={() => this.props.onChangePermisions(key, 'delete')}
              >
                Delete
              </span>
            </div>
          </div>
        </div>
      );
    }
    return permissions;
  };
  render() {
    return (
      <div className="modalContainer">
        <div className="modalContent">
          <div className="modalTitle">
            <h6 className="Title">
              Permissions
              <button onClick={() => this.props.onModalClose()}>
                <i className="fa fa-close" />
              </button>
            </h6>
          </div>
          <div className="modalBody">
            {this.renderPermisions(this.props.data)}
          </div>
          <div className="modalFooter">
            <button
              className="btn btn-secondary"
              onClick={() => this.props.onModalClose()}
              style={{ marginRight: '5px' }}
            >
              Close
            </button>
            <button
              className="btn btn-success"
              onClick={() => this.props.onPermissionsUpdate()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PermisionModal;
