import React, { useState, useEffect } from 'react';
import { Menu } from 'semantic-ui-react';

const Tabs = ({ getPotentials }) => {
  const [activeItem, setActiveItem] = useState('Date');

  useEffect(() => {
    if (activeItem === 'Date') {
      getPotentials({ type: 'date' });
    }
  }, [activeItem, getPotentials]);
  return (
    <Menu pointing secondary color="blue">
      <Menu.Item
        name="Date"
        active={activeItem === 'Date'}
        onClick={() => setActiveItem('Date')}
      />
      {/* <Menu.Item
        name="Genre"
        active={activeItem === 'Genre'}
        onClick={() => setActiveItem('Genre')}
      />
      <Menu.Item
        name="Device Type"
        active={activeItem === 'Device Type'}
        onClick={() => setActiveItem('Device Type')}
      />
      <Menu.Item
        name="Country"
        active={activeItem === 'Country'}
        onClick={() => setActiveItem('Country')}
      /> */}
    </Menu>
  );
};

export default Tabs;
