import React, { Component } from 'react';
import '../../../shared/Modal/MoreModal.scss'

class ViewModal extends Component {
    render() {
        return (
            <div className="modalContainer" onClick={() => this.props.onViewModalClose()}>
                <div className="modalContent">
                    <div className="modalTitle">
                        <h6 className="Title">
                            Ad View
                            <button onClick={() => this.props.onViewModalClose()}><i className="fa fa-close"></i></button>
                        </h6>
                    </div>
                    <div className="modalBody">
                        {this.props.data.creative.flatVideo && this.props.data.creative.flatVideo.url && <div className="row">
                            <div className="col-md-12">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.flatVideo.url} type="video/mp4" />
                                </video>
                            </div>
                        </div>}
                        {this.props.data.creative.videos &&  <div className="row">
                            {this.props.data.creative.videos.video480 && <div className="col-md-6">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.videos.video480.url} type="video/mp4" />
                                </video>
                            </div>}
                            {this.props.data.creative.videos.video1080 && <div className="col-md-6">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.videos.video1080.url} type="video/mp4" />
                                </video>
                            </div>}
                            {this.props.data.creative.videos.video2k && <div className="col-md-6">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.videos.video2k.url} type="video/mp4" />
                                </video>
                            </div>}
                            {this.props.data.creative.videos.video4k && <div className="col-md-6">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.videos.video4k.url} type="video/mp4" />
                                </video>
                            </div>}
                        </div>}
                        {this.props.data.creative.video && <div className="row">
                            <div className="col-md-12">
                                <video width="100%" height="400" controls>
                                    <source src={this.props.data.creative.video.url} type="video/mp4" />
                                </video>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewModal;
