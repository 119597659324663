import { BID_BY_COUNTRY_GET, BID_BY_COUNTRY_EDIT } from '../constants';

const defaultState = {
  bidPending: false,
  bidFetched: false,
  bidError: null,
  bidData: [],
  editPending: false,
  editFetched: false,
  editError: null,
}

const BidReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case BID_BY_COUNTRY_GET.PENDING: {
      return {
        ...state,
        bidPending: true,
        bidFetched: false,
        bidData: [],
      }
    }
    case BID_BY_COUNTRY_GET.SUCCESS: {
      return {
        ...state,
        bidPending: false,
        bidFetched: true,
        bidData: payload,
      }
    }
    case BID_BY_COUNTRY_GET.ERROR: {
      return {
        ...state,
        bidPending: false,
        bidFetched: false,
        bidError: error,
        bidData: [],
      }
    }
    case BID_BY_COUNTRY_EDIT.PENDING: {
      return {
        ...state,
        editPending: true,
        editFetched: false,
      }
    }
    case BID_BY_COUNTRY_EDIT.SUCCESS: {
      return {
        ...state,
        editPending: false,
        editFetched: true,
      }
    }
    case BID_BY_COUNTRY_EDIT.ERROR: {
      return {
        ...state,
        editPending: false,
        editFetched: false,
        editError: error,
      }
    }
    case "RESET_BID_BY_COUNTRY_REDUCER": {
      return {
        ...state,
        bidPending: false,
        bidFetched: false,
        bidError: null,
        bidData: [],
        editPending: false,
        editFetched: false,
        editError: null,
      }
    }
    default: return state;
  }
}

export default BidReducer;
