import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import CampaignsComponent from '../../components/CampaignsComponent/CampaignsComponent';
import {
  CompaignTable,
  CompaignStatusUbdate,
  CompaignTableLast,
  filterCampaigns,
  resetCampaignsReducer,
  infiniteCampaignsScroll,
} from '../../store/actions/CampaignsActions';
import { resetReducer } from '../../store/actions/resetReducerAction';
import { withRouter } from 'react-router-dom';

const CampaignsContainer = ({
  dashboardReducer: { optionsData, optionsData: { provider }, permissionsFetched, permissionsData },
  campaignsReducer,
  campaignsStatusUpdate,
  fetchItems,
  campaignTableLast,
  filterCampaigns,
  filteredItems,
  resetCampaignsReducer,
  filteredItemsPending,
  resetTableState,
  resetReducer,
  infiniteScroll,
}) => {
  useEffect(() => {
    fetchItems({ provider });
    return () => resetReducer('RESET_CAMPAIGNS_REDUCER');
  }, [fetchItems, resetReducer]);

  return (
    <div>
      <InnerPagesComponent pageName="Campaigns" logo={optionsData.options.logo}>
        {permissionsFetched && (
          <CampaignsComponent
            campaignsReducer={campaignsReducer}
            campaignsStatusUpdate={campaignsStatusUpdate}
            fetchItems={fetchItems}
            campaignPermissions={permissionsData.campaigns}
            campaignTableLast={campaignTableLast}
            filterCampaigns={filterCampaigns}
            filteredItems={filteredItems}
            resetCampaignsReducer={resetCampaignsReducer}
            filteredItemsPending={filteredItemsPending}
            resetTableState={resetTableState}
            infiniteScroll={infiniteScroll}
            provider={provider}
          />
        )}
      </InnerPagesComponent>
    </div>
  );
};

const mapStateFromProps = store => ({
  campaignsReducer: store.campaignsReducer,
  dashboardReducer: store.DashboardReducer,
  filteredItems: store.campaignsReducer.filtered,
  filteredItemsPending: store.campaignsReducer.filtered.pending,
  resetTableState: store.campaignsReducer.resetTableState,
});

const mapDispatchFromProps = dispatch => ({
  fetchItems: data => dispatch(CompaignTable(data)),
  campaignsStatusUpdate: data => dispatch(CompaignStatusUbdate(data)),
  resetReducer: name => dispatch(resetReducer(name)),
  filterCampaigns: data => dispatch(filterCampaigns(data)),
  resetCampaignsReducer: () => dispatch(resetCampaignsReducer()),
  infiniteScroll: props => dispatch(infiniteCampaignsScroll(props)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(CampaignsContainer)
);
