import React, { useState, Fragment } from 'react';
import Table from '../../shared/SemanticTable/Table';
import Tabs from './Tabs';
import useCells from './useCells';

const IncomesComponent = ({
  data,
  pending,
  filtered,
  resetReducer,
  status,
  getIncomes,
}) => {
  const [activeItem, setActiveItem] = useState('Date');
  const [cells] = useCells(data, activeItem);

  return (
    <Fragment>
      <Tabs
        getIncomes={getIncomes}
        resetReducer={resetReducer}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />

      <Table
        cells={cells}
        data={data}
        action
        type="campaigns"
        isLoading={pending}
        filtered={filtered}
        // getItems={infiniteScroll}
        infinite
        // pagination={pagination}
        dataStatus={status}
        resetTableState={resetReducer}
        // filters={this.state.filters}
      />
    </Fragment>
  );
};

export default IncomesComponent;
