import {
  CAMPAIGNS,
  CAMPAIGNS_UPDATE,
  CAMPAIGNS_LAST,
  FILTER_CAMPAIGNS,
  RESET_CAMPAIGNS_REDUCER,
  INFINITE_CAMPAIGNS_SCROLL,
} from '../constants';
import { handleActions } from 'redux-actions';

const defaultState = {
  campaignsPending: false,
  campaignsFetched: false,
  campaignsError: null,
  campaignsData: [],
  pagination: null,
  loader: false,
  campaignsStatusPending: false,
  campaignsStatusFetched: false,
  campaignsStatusError: null,
  filtered: {
    items: null,
    pending: false,
  },
  resetTableState: false,
};

const campaignsReducer = handleActions(
  {
    [CAMPAIGNS.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      campaignsPending: true,
      campaignsFetched: false,
      campaignsData: [],
      campaignsStatusFetched: false,
    }),

    [INFINITE_CAMPAIGNS_SCROLL.SUCCESS]: (
      state = defaultState,
      { payload }
    ) => ({
      ...state,
      campaignsData: [...state.campaignsData, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [INFINITE_CAMPAIGNS_SCROLL.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
    }),

    [CAMPAIGNS.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      campaignsPending: false,
      campaignsFetched: true,
      campaignsData: payload.Items,
      pagination: payload.LastEvaluatedKey,
      loader: false,
      resetTableState: false,
    }),

    [FILTER_CAMPAIGNS.PENDING]: (state = defaultState) => ({
      ...state,
      filtered: {
        items: null,
        pending: true,
      },
    }),

    [FILTER_CAMPAIGNS.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      filtered: { pending: false, success: true },
      campaignsData: [...payload.Items],
      pagination: payload.LastEvaluatedKey,
    }),

    [CAMPAIGNS_LAST.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      campaignsPending: false,
      campaignsFetched: true,
      campaignsData: [...state.campaignsData, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [CAMPAIGNS.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      campaignsPending: false,
      campaignsFetched: false,
      campaignsError: error,
      campaignsData: [],
      pagination: null,
      loader: false,
    }),

    [CAMPAIGNS_UPDATE.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      campaignsStatusPending: true,
      campaignsStatusFetched: false,
    }),

    [CAMPAIGNS_UPDATE.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      campaignsStatusPending: false,
      campaignsStatusFetched: true,
      loader: false,
      updatedItem: { ...payload.Attributes },
    }),

    [CAMPAIGNS_UPDATE.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      campaignsStatusPending: false,
      campaignsStatusFetched: false,
      campaignsStatusError: error,
    }),

    [RESET_CAMPAIGNS_REDUCER]: (state = defaultState) => ({
      ...state,
      resetTableState: true,
    }),
  },
  defaultState
);

export default campaignsReducer;
