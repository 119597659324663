import React, { Component } from 'react';
import Countries from '../../config/countries.json';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'semantic-ui-react';

class FiltersRow extends Component {
  state = {
    user: {
      id: '',
      status: '',
      country: '',
      type: '',
    },
    campaigns: {
      id: '',
      type: '',
      status: '',
    },
    apps: {
      id: '',
      type: '',
      category: '',
      genre: '',
      status: '',
    },
  };
  //user filter
  onUserFilterChanges = (key, value) => {
    const user = this.state.user;
    user[key] = value;
    this.setState({user });
    const data = {
      search: {},
      filter: {},
    };
    if (user.id) {
      data['search'] = { type: 'id', value: user.id };
    }
    if (user.status) {
      data.filter['status'] = [user.status];
    }
    if (user.country) {
      data.filter['country'] = [user.country];
    }
    if (user.type) {
      data.filter['type'] = [user.type];
    }
    this.props.getUserFilterData(data);
  };
  
  clearUserFilter = () => {
    let user = { id: '', status: '', country: '', type: '' };
    this.setState({ user: user });
    let data = {
      search: {},
      filter: {},
    };
    this.props.UsersTable({});
    this.props.getUserFilterData(data);
  };

  //campaigns filter
  onCampaignsFilterChanges = (key, value) => {
    let campaigns = this.state.campaigns;
    campaigns[key] = value;
    this.setState({
      campaigns: campaigns,
    });
    let data = {
      search: {},
      filter: {},
    };
    if (campaigns.id) {
      data['search'] = { type: 'id', value: campaigns.id };
    }
    if (campaigns.status) {
      data.filter['status'] = [campaigns.status];
    }
    if (campaigns.type) {
      data.filter['type'] = [campaigns.type];
    }
    this.props.getCampaignsFilterData(data);
  };
  clearCampaignsFilter = () => {
    let campaigns = { id: '', status: '', type: '' };
    this.setState({ campaigns: campaigns });
    let data = {
      search: {},
      filter: {},
    };
    this.props.CompaignTable({});
    this.props.getCampaignsFilterData(data);
  };

  //apps filter
  onAppsFilterChanges = (key, value) => {
    let apps = this.state.apps;
    apps[key] = value;
    this.setState({
      apps: apps,
    });
    let data = {
      search: {},
      filter: {},
    };
    if (apps.id) {
      data['search'] = { type: 'id', value: apps.id };
    }
    if (apps.status) {
      data.filter['status'] = [apps.status];
    }
    if (apps.type) {
      data.filter['media_type'] = [apps.type];
    }
    if (apps.category) {
      data.filter['category'] = [apps.category];
    }
    if (apps.genre) {
      data.filter['genre'] = [apps.genre];
    }
    this.props.getAppsFilterData(data);
  };
  clearAppsFilter = () => {
    let apps = { id: '', type: '', category: '', genre: '', status: '' };
    this.setState({ apps: apps });
    let data = {
      search: {},
      filter: {},
    };
    this.props.AppsTable({});
    this.props.getAppsFilterData(data);
  };

  render() {
    return (
      <div className="shadow-widget">
        <div className="row pt-3">
          {(this.props.type === 'user' ||
            this.props.type === 'campaigns' ||
            this.props.type === 'apps') && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                {this.props.type === 'user' && (
                  <input
                    className="form-control ng-untouched ng-pristine ng-valid"
                    placeholder="Search..."
                    type="text"
                    value={this.state.user.id}
                    onChange={e =>
                      this.onUserFilterChanges('id', e.target.value)
                    }
                  />
                )}
                {this.props.type === 'campaigns' && (
                  <input
                    className="form-control ng-untouched ng-pristine ng-valid"
                    placeholder="Search..."
                    type="text"
                    value={this.state.campaigns.id}
                    onChange={e =>
                      this.onCampaignsFilterChanges('id', e.target.value)
                    }
                  />
                )}
                {this.props.type === 'apps' && (
                  <input
                    className="form-control ng-untouched ng-pristine ng-valid"
                    placeholder="Search..."
                    type="text"
                    value={this.state.apps.id}
                    onChange={e =>
                      this.onAppsFilterChanges('id', e.target.value)
                    }
                  />
                )}
              </div>
            </div>
          )}
          {/* user */}
          {this.props.type === 'user' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.user.country}
                  onChange={e =>
                    this.onUserFilterChanges('country', e.target.value)
                  }
                >
                  <option value="">Select Country</option>
                  {Countries.countries.map((item, key) => {
                    return (
                      <option key={key} value={item.code}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {/* user */}
          {this.props.type === 'user' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.user.status}
                  onChange={e =>
                    this.onUserFilterChanges('status', e.target.value)
                  }
                >
                  <option value="">Select Status (all)</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                  <option value="declined">Declined</option>
                </select>
              </div>
            </div>
          )}
          {/* user */}
          {this.props.type === 'user' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.user.type}
                  onChange={e =>
                    this.onUserFilterChanges('type', e.target.value)
                  }
                >
                  <option value="">Select Type (all)</option>
                  <option value="advertiser">Advertiser</option>
                  <option value="publisher">Publisher</option>
                  <option value="both">Both</option>
                </select>
              </div>
            </div>
          )}
          {/* campaigns */}
          {this.props.type === 'campaigns' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.campaigns.type}
                  onChange={e =>
                    this.onCampaignsFilterChanges('type', e.target.value)
                  }
                >
                  <option value="">Select Type (all)</option>
                  <option value="video">Video Ad</option>
                  <option value="scene">Scene Ad</option>
                  <option value="3d">3D Object</option>
                  <option value="flat">Flat Baner</option>
                </select>
              </div>
            </div>
          )}
          {/* campaigns */}
          {this.props.type === 'campaigns' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.campaigns.status}
                  onChange={e =>
                    this.onCampaignsFilterChanges('status', e.target.value)
                  }
                >
                  <option value="">Select Status (all)</option>
                  <option value="active">Active</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                  <option value="paused">Paused</option>
                </select>
              </div>
            </div>
          )}
          {/* apps */}
          {this.props.type === 'apps' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.apps.type}
                  onChange={e => {
                    this.onAppsFilterChanges('type', e.target.value);
                  }}
                >
                  <option value="">Select Media Type (all)</option>
                  <option value="games">Games</option>
                  <option value="apps">Apps</option>
                </select>
              </div>
            </div>
          )}
          {/* apps */}
          {this.props.type === 'apps' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.apps.category}
                  onChange={e => {
                    this.onAppsFilterChanges('category', e.target.value);
                  }}
                >
                  <option value="">Select Category (all)</option>
                  <option value="business">Business</option>
                  <option value="creativity">Cretivity</option>
                  <option value="education">Education</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="lifestyle">Lifestyle</option>
                  <option value="news">News</option>
                  <option value="productivity">Productivity</option>
                  <option value="social">Social</option>
                  <option value="shopping">Shopping</option>
                </select>
              </div>
            </div>
          )}
          {/* apps */}
          {this.props.type === 'apps' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.apps.genre}
                  onChange={e => {
                    this.onAppsFilterChanges('genre', e.target.value);
                  }}
                >
                  <option value="">Select Genre (all)</option>
                  <option value="360_video">360° video</option>
                  <option value="action">Action</option>
                  <option value="adventure">Adventure</option>
                  <option value="casual">Casual</option>
                  <option value="comedy">Comedy</option>
                  <option value="creativestory">Creative story</option>
                  <option value="educational">Educational</option>
                  <option value="exploration">Exploration</option>
                  <option value="horror">Horror</option>
                  <option value="interactivevideo">Interactive video</option>
                  <option value="liveevent">Live event</option>
                  <option value="medical">Medical</option>
                  <option value="music">Music</option>
                  <option value="racing">Racing</option>
                  <option value="realestate">Real estate</option>
                  <option value="rpg">RPG</option>
                  <option value="science">Science</option>
                  <option value="shooter">Shooter</option>
                  <option value="sports">Sports</option>
                  <option value="simulation">Simulation</option>
                  <option value="strategy">Strategy</option>
                  <option value="tools">Tools</option>
                  <option value="travel">Travel</option>
                  <option value="war">War</option>
                </select>
              </div>
            </div>
          )}
          {/* apps */}
          {this.props.type === 'apps' && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control"
                  value={this.state.apps.status}
                  onChange={e => {
                    this.onAppsFilterChanges('status', e.target.value);
                  }}
                >
                  <option value="">Select Status (all)</option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="declined">Declined</option>
                  <option value="deleted">Deleted</option>
                </select>
              </div>
            </div>
          )}
          {(this.props.type === 'Date' ||
            this.props.type === 'Campaigns' ||
            (this.props.type === 'Country' && !this.props.potentials) ||
            this.props.type === 'adspaces') && (
            <div className="col-md-6 col-lg-6 col-xl-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  value={this.props.email}
                  onChange={e => this.props.onChange('email', e.target.value)}
                />
              </div>
            </div>
          )}
          {(this.props.type === 'Campaigns' ||
            this.props.type === 'Country' ||
            this.props.type === 'adspaces' ||
            this.props.type === 'genre' ||
            this.props.type === 'deviceType') && (
            <React.Fragment>
              <DatePicker
                className="form-control"
                value={this.props.from}
                onChange={value => this.props.onChange('from', value)}
                showYearDropdown
                scrollableYearDropdown
                placeholderText="Select Date Range"
              />
              <DatePicker
                className="form-control"
                value={this.props.to}
                onChange={value => this.props.onChange('to', value)}
                showYearDropdown
                scrollableYearDropdown
                placeholderText="Select Date Range"
              />
            </React.Fragment>
          )}
          <div className="col-md-6 col-lg-6 col-xl-2">
            {this.props.type === 'user' && (
              <div className="form-group d-flex justify-content-start align-items-center pr-5">
                <Button
                  inverted
                  color="red"
                  onClick={() => this.clearCampaignsFilter()}
                >
                  Clear
                </Button>
                <Button
                  inverted
                  color="green"
                  onClick={() => this.props.onFilteredSearch()}
                >
                  Filter
                </Button>
              </div>
            )}
            {this.props.type === 'campaigns' && (
              <div className="form-group d-flex justify-content-start align-items-center pr-5">
                <Button
                  inverted
                  color="red"
                  onClick={() => this.clearCampaignsFilter()}
                >
                  Clear
                </Button>
                <Button
                  inverted
                  color="green"
                  onClick={() => this.props.onFilteredSearch()}
                >
                  Filter
                </Button>
              </div>
            )}
            {this.props.type === 'apps' && (
              <div className="form-group d-flex justify-content-start align-items-center pr-5">
                <Button
                  inverted
                  color="red"
                  onClick={() => this.clearCampaignsFilter()}
                >
                  Clear
                </Button>
                <Button
                  inverted
                  color="green"
                  onClick={() => this.props.onFilteredSearch()}
                >
                  Filter
                </Button>
              </div>
            )}
            {(this.props.type === 'Date' ||
              this.props.type === 'Campaigns' ||
              this.props.type === 'Country' ||
              this.props.type === 'adspaces' ||
              this.props.type === 'genre' ||
              this.props.type === 'deviceType') && (
              <div className="form-group">
                <button
                  className="btn btn-success"
                  onClick={() => this.props.onFilteredSearch()}
                >
                  Filter
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FiltersRow;
