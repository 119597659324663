import React, { Component } from 'react';
// import Table from '../../shared/Table/Table';
import Table from '../../shared/SemanticTable/Table';
import Loader from '../../shared/Loader';
import MoreModal from '../../shared/Modal/MoreModal';
import './UsersComponent.scss';
import Countries from '../../config/countries.json';
import Filter from '../../shared/Filters/Filter';

class UsersComponent extends Component {
  state = {
    modal: false,
    key: null,
    tableHeader: [
      {
        title: 'Information',
        key: 'information',
      },
      {
        title: 'User Type',
        key: 'type',
      },
      {
        title: 'Current Balance',
        key: 'balance',
      },
      {
        title: 'Status',
        key: 'status',
      },
      {
        title: 'Actions',
        key: 'actions',
      },
    ],
  };

  getStatusBadge = status => {
    if (['both'].includes(status)) {
      return 'badge-warning';
    } else if (['advertiser'].includes(status)) {
      return 'badge-success';
    } else {
      return 'badge-primary';
    }
  };

  mapData = () => {
    const data = this.props.data || [];
    return data.map(item => {
      return {
        type: item.type,
        status: item.status,
        information: (
          <div>
            {item.first_name && (
              <p className="my-0">
                <span className="font-weight-bold">Full Name:</span>
                {item.first_name} {item.last_name}
              </p>
            )}
            <p className="my-0">
              <span className="font-weight-bold">Email:</span> {item.email}
            </p>
            <p className="my-0">
              <span className="font-weight-bold">Country:</span>
              {Countries.countries.map(country => {
                if (country.code === item.locale) return country.name;
              })}
            </p>
          </div>
        ),
        balance: `${(Number(item.balance) / 100).toFixed(2)}$`,
        id: item.id,
      };
    });
  };

  onMoreModal = key => this.setState({ modal: true, key: key });

  onModalClose = () => this.setState({ modal: false, key: null });

  handleStatusEdit = (id, type, status) => {
    const { updateItem } = this.props;
    updateItem({ id, type, status, provider: this.props.provider });
  };

  onFilteredSearch = data => {
    this.props.filterUsers(data);
  };

  render() {
    const {
      data,
      getItems,
      userPermissions,
      usersReducer,
      filtered,
      filteredItemsPending,
      resetFilters,
      resetTable,
      resetTableState,
      infiniteScroll,
      provider
    } = this.props;

    const { key, tableHeader, modal } = this.state;

    return (
      <div className="container-fluid">
        {modal && (
          <MoreModal
            type="user"
            data={data[key]}
            onModalClose={this.onModalClose}
          />
        )}
        {usersReducer.loader && <Loader />}
        <Filter
          type="user"
          filter={this.onFilteredSearch}
          isLoading={filteredItemsPending}
          fetchItems={getItems}
          resetTable={resetTable}
          provider={provider}
        />
        <Table
          cells={tableHeader}
          data={this.mapData()}
          action
          type="user"
          permissions={userPermissions}
          onViewModal={this.onViewModal}
          onMoreModal={this.onMoreModal}
          isLoading={usersReducer.loader}
          dataUpdate={this.handleStatusEdit}
          getItems={infiniteScroll}
          filtered={filtered}
          infinite
          pagination={usersReducer.pagination && usersReducer.pagination}
          dataStatus={usersReducer}
          resetTableState={resetTableState}
          provider={provider}
        />
      </div>
    );
  }
}

export default UsersComponent;
