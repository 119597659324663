import React, { Component } from 'react';
import './Table.scss';
import Loader from 'react-loader-spinner'
class Table extends Component {
    renderTableBody = () => {
        let body = []
        this.props.AdminsReducer.adminsData.map((item, key) => {
            return body.push(
                <div className="my-table-row" key={key}>
                    <div className="my-table-column  border-bottom">{item.first_name} {item.last_name}</div>
                    <div className="my-table-column  border-bottom">{item.email}</div>
                    <div className="my-table-column  border-bottom">
                        {this.props.permissions.update && <button className="btn btn-sm btn-info" style={{marginRight: "5px"}} onClick={()=>this.props.onPermisionModalOpen(key)}>Permissions</button>}
                        {/* <button className="btn btn-sm btn-danger" style={{marginRight: "5px"}} onClick={()=>this.props.onResetPassword(item)}>Reset password</button> */}
                        {this.props.permissions.update && <button className="btn btn-sm btn-secondary" onClick={()=>this.props.onBlock(item)}>{item.status === "active" ? "Block" : "Unblock"}</button>}
                    </div>
                </div>)
        })
        return body
    }
    render() {
        return (
            <React.Fragment>
                {this.props.AdminsReducer.adminsFetched && <div className="admins shadow-widget table-widget">
                    <div className="my-table">
                        <div className="my-table-header">
                            <div className="my-table-row border-bottom">
                                <div className="my-table-column">Name</div>
                                <div className="my-table-column">Email</div>
                                <div className="my-table-column">Actions</div>
                            </div>
                        </div>
                        <div className="my-table-body" id="scroledDiv">
                            {this.renderTableBody()}
                        </div>
                        <div className="my-table-footer">
                            {this.props.AdminsReducer.adminsData.length} Total
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        );
    }
}

export default Table;
