import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import { resetReducer } from '../../store/actions/resetReducerAction';
import { getPayments, getPaymentsLast, getPaymentsApprove } from '../../store/actions/PaymentActions';
import { withRouter } from 'react-router-dom';
import WidrovalComponent from '../../components/WidrovalComponent/WidrovalComponent'

class WidrovalContainer extends Component {
    componentWillUnmount() {
        this.props.resetReducer("RESET_PAYMENTS_REDUCER")
    }
    render() {
        return (
            <div>
                <InnerPagesComponent pageName="" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.DashboardReducer.permissionsFetched && <WidrovalComponent
                        PotentialsReducer={this.props.PotentialsReducer}
                        DashboardReducer={this.props.DashboardReducer}
                        resetReducer={this.props.resetReducer}
                        getPayments={this.props.getPayments}
                        paymentsReducer={this.props.paymentsReducer}
                        getPaymentsLast={this.props.getPaymentsLast}
                        getPaymentsApprove={this.props.getPaymentsApprove}
                    />}
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    PotentialsReducer: store.PotentialsReducer,
    DashboardReducer: store.DashboardReducer,
    paymentsReducer: store.paymentsReducer
});

const mapDispatchFromProps = dispatch => ({
    resetReducer: (name) => (dispatch(resetReducer(name))),
    getPayments: (body) => (dispatch(getPayments(body))),
    getPaymentsLast: (body) => (dispatch(getPaymentsLast(body))),
    getPaymentsApprove: (body) => (dispatch(getPaymentsApprove(body)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(WidrovalContainer));
