import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import UsersComponent from '../../components/UsersComponent/UsersComponent';
import {
  UsersTable,
  UsersStatusUbdate,
  UsersTableLastKey,
  resetUsersTable,
  infiniteUsersScroll,
  resetUsersReducer,
} from '../../store/actions/UsersAction';
import { withRouter } from 'react-router-dom';
import { filterUsers } from '../../store/actions/UsersAction';

const UsersContainer = ({
  getItems,
  resetReducer,
  data,
  loader,
  statusUpdate,
  usersReducer,
  dashboardReducer: {
    optionsData: {
      options: { logo },
      provider
    },
    permissionsFetched,
    permissionsData: { users },
  },
  pagination,
  filtered,
  resetFilters,
  resetTable,
  filterUsers,
  resetTableState,
  infiniteScroll,
}) => {
  useEffect(() => {
    getItems({ provider });
    return () => {
      resetReducer();
    };
  }, [getItems, resetReducer]);
  return (
    <InnerPagesComponent pageName="Users" logo={logo}>
      {permissionsFetched && (
        <UsersComponent
          data={data}
          isLoading={loader}
          updateItem={statusUpdate}
          getItems={getItems}
          usersReducer={usersReducer}
          userPermissions={users}
          lastEvaluatedKey={pagination}
          filtered={filtered}
          filteredItemsPending={filtered.pending}
          resetFilters={resetFilters}
          resetTable={resetTable}
          filterUsers={filterUsers}
          resetTableState={resetTableState}
          infiniteScroll={infiniteScroll}
          provider={provider}
        />
      )}
    </InnerPagesComponent>
  );
};

const mapStateFromProps = store => ({
  data: store.usersReducer.data,
  usersReducer: store.usersReducer,
  loader: store.usersReducer.loader,
  dashboardReducer: store.DashboardReducer,
  filtered: store.usersReducer.filtered,
  resetTableState: store.usersReducer.resetTableState,
});

const mapDispatchFromProps = dispatch => ({
  getItems: data => dispatch(UsersTable(data)),
  pagination: data => dispatch(UsersTableLastKey(data)),
  statusUpdate: data => dispatch(UsersStatusUbdate(data)),
  resetReducer: _ => dispatch(resetUsersReducer()),
  filterUsers: data => dispatch(filterUsers(data)),
  resetTable: () => dispatch(resetUsersTable()),
  infiniteScroll: props => dispatch(infiniteUsersScroll(props)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(UsersContainer)
);
