import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Authorization from './shared/HOC/authorization';
import { Routes } from './routes';
import Auth from "./shared/Auth/Auth";
import {getOptions} from './store/actions/DashboarActions';
import Loader from './shared/Loader'
import './assets/style/main.scss';


class App extends React.Component{
  state = {
    providerIsSet: false
  };

  componentDidMount() {
    let domain = window.location.host.replace('backofficedev.', '').replace('backoffice.','');
    this.props.getOptions(domain);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.optionsData && nextProps.optionsData.credentials && nextProps.optionsData.credentials.backoffice && !prevState.providerIsSet) {
      const credentials = nextProps.optionsData.credentials.backoffice;
      // Configuring Provider Cognito Credentials
      Auth.configure({
        'aws_cognito_identity_pool_id': credentials.IdentityPoolId,
        'aws_user_pools_id': credentials.UserPoolId,
        'aws_user_pools_web_client_id': credentials.ClientId,
      });
      return { providerIsSet: true };
    } else  {
      return { };
    }
  }

  renderSwitch = () => (
    <Switch>
      {Routes.map(route => {
        const component = Authorization(route.component, route.isPrivate);
        return (
          <Route
            key={route.path}
            exact={route.isExact}
            path={route.path}
            component={component}
          />
        );
      })}
    </Switch>
  );
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>} maxDuration={5000}>
        <Router>
          <div id='main'>
            {(this.props.optionsPending) && <Loader/>}
            {this.props.optionsFetched  && <div>
              {this.renderSwitch()}
            </div>}
          </div>
        </Router>
      </Suspense>
    );
  }
}

const mapStateFromProps = store => ({
  optionsPending: store.DashboardReducer.optionsPending,
  optionsFetched: store.DashboardReducer.optionsFetched,
  optionsData: store.DashboardReducer.optionsData
});

const mapDispatchFromProps = dispatch => ({
  getOptions: (domain) => dispatch(getOptions(domain))
});

export default connect(mapStateFromProps, mapDispatchFromProps)(App);

