import CommonAction from '../../shared/Http/Http';
import { GET_INCOMES } from '../constants';

export const getIncomes = body =>
  CommonAction(
    'POST',
    null,
    `backoffice-extended/product/reports`,
    GET_INCOMES,
    body
  );

export const resetIncomesReducer = () => ({ type: 'RESET_INCOMES_REDUCER' });
