import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import { withRouter } from 'react-router-dom';
import IncomesComponent from '../../components/IncomesComponent/IncomesComponent';
import {
  getIncomes,
  resetIncomesReducer,
} from '../../store/actions/IncomesActions';
import { getCountries } from '../../config/getCountries';

const IncomesContainer = ({
  DashboardReducer: {
    optionsData: {
      options: { logo },
    },
    permissionsFetched,
  },
  getIncomes,
  data,
  pending,
  filtered,
  resetReducer,
  status,
}) => {
  const [dataMapped, setDataMapped] = useState(null);

  useEffect(() => {
    getIncomes({ type: 'adminDate' });
    return () => resetReducer();
  }, [getIncomes, resetReducer]);

  useEffect(() => {
    const countries = getCountries();
    const mapped = data.map(it => {
      const item = { ...it };
      item.country &&
        countries.forEach(country => {
          if (item.country === country.code) {
            item.country = country.name;
          }
        });
      return item;
    });
    setDataMapped([...mapped]);
  }, [data]);

  return (
    <Fragment>
      <InnerPagesComponent pageName="Incomes" logo={logo}>
        {permissionsFetched && (
          <IncomesComponent
            data={dataMapped}
            pending={pending}
            filtered={filtered}
            resetReducer={resetReducer}
            status={status}
            getIncomes={getIncomes}
          />
        )}
      </InnerPagesComponent>
    </Fragment>
  );
};

const mapStateFromProps = store => ({
  DashboardReducer: store.DashboardReducer,
  data: store.IncomesReducer.data,
  pending: store.IncomesReducer.pending,
  filtered: store.IncomesReducer.filtered,
  status: store.IncomesReducer.status,
});

const mapDispatchFromProps = dispatch => ({
  resetReducer: _ => dispatch(resetIncomesReducer()),
  getIncomes: props => dispatch(getIncomes(props)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(IncomesContainer)
);
