import { createActions, handleActions, combineActions } from 'redux-actions';

export const LOGIN = {
  PENDING: 'LOGIN_PENDING',
  SUCCESS: 'LOGIN_SUCCESS',
  MFA_NEEDED: 'LOGIN_MFA_NEEDED',
  PASSWORD_CHANGE_REQUIRED: 'LOGIN_PASSWORD_CHANGE_REQUIRED',
  MFA_REQUIRED: 'LOGIN_MFA_REQUIRED',
  ERROR: 'LOGIN_ERROR',
  RESET: 'RESET_LOGIN_REDUCER',
  LOGOUT_PENDING: 'LOGOUT_PENDING',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
};

export const DASHBOARD = {
  PENDING: 'DASHBOARD_PENDING',
  SUCCESS: 'DASHBOARD_SUCCESS',
  ERROR: 'DASHBOARD_ERROR',
};

export const USERS = {
  PENDING: 'USERS_PENDING',
  SUCCESS: 'USERS_SUCCESS',
  ERROR: 'USERS_ERROR',
};
export const USERS_LAST = {
  PENDING: 'USERS_PENDING',
  SUCCESS: 'USERS_LAST_SUCCESS',
  ERROR: 'USERS_ERROR',
};
export const USERS_UPDATE = {
  PENDING: 'USERS_UPDATE_PENDING',
  SUCCESS: 'USERS_UPDATE_SUCCESS',
  ERROR: 'USERS_UPDATE_ERROR',
};

export const CAMPAIGNS = {
  PENDING: 'CAMPAIGNS_PENDING',
  SUCCESS: 'CAMPAIGNS_SUCCESS',
  ERROR: 'CAMPAIGNS_ERROR',
};
export const CAMPAIGNS_LAST = {
  PENDING: 'CAMPAIGNS_PENDING',
  SUCCESS: 'CAMPAIGNS_LAST_SUCCESS',
  ERROR: 'CAMPAIGNS_ERROR',
};
export const CAMPAIGNS_UPDATE = {
  PENDING: 'CAMPAIGNS_UPDATE_PENDING',
  SUCCESS: 'CAMPAIGNS_UPDATE_SUCCESS',
  ERROR: 'CAMPAIGNS_UPDATE_ERROR',
};

export const APPS = {
  PENDING: 'APPS_PENDING',
  SUCCESS: 'APPS_SUCCESS',
  ERROR: 'APPS_ERROR',
};
export const APPS_LAST = {
  PENDING: 'APPS_PENDING',
  SUCCESS: 'APPS_LAST_SUCCESS',
  ERROR: 'APPS_ERROR',
};
export const APPS_UPDATE = {
  PENDING: 'APPS_UPDATE_PENDING',
  SUCCESS: 'APPS_UPDATE_SUCCESS',
  ERROR: 'APPS_UPDATE_ERROR',
};

export const APPS_ADSPACES = {
  PENDING: 'APPS_ADSPACES_PENDING',
  SUCCESS: 'APPS_ADSPACES_SUCCESS',
  ERROR: 'APPS_ADSPACES_ERROR',
};
export const DEFAULT_OPTIONS_GET = {
  PENDING: 'DEFAULT_OPTIONS_PENDING',
  SUCCESS: 'DEFAULT_OPTIONS_SUCCESS',
  ERROR: 'DEFAULT_OPTIONS_ERROR',
};
export const DEFAULT_OPTIONS_EDIT = {
  PENDING: 'DEFAULT_OPTIONS_EDIT_PENDING',
  SUCCESS: 'DEFAULT_OPTIONS_EDIT_SUCCESS',
  ERROR: 'DEFAULT_OPTIONS_EDIT_ERROR',
};
export const BILLING_INFO_GET = {
  PENDING: 'BILLING_INFO_PENDING',
  SUCCESS: 'BILLING_INFO_SUCCESS',
  ERROR: 'BILLING_INFO_ERROR',
};
export const BILLING_INFO_EDIT = {
  PENDING: 'BILLING_INFO_EDIT_PENDING',
  SUCCESS: 'BILLING_INFO_EDIT_SUCCESS',
  ERROR: 'BILLING_INFO_EDIT_ERROR',
};
export const BID_BY_COUNTRY_GET = {
  PENDING: 'BID_BY_COUNTRY_PENDING',
  SUCCESS: 'BID_BY_COUNTRY_SUCCESS',
  ERROR: 'BID_BY_COUNTRY_ERROR',
};
export const BID_BY_COUNTRY_EDIT = {
  PENDING: 'BID_BY_COUNTRY_EDIT_PENDING',
  SUCCESS: 'BID_BY_COUNTRY_EDIT_SUCCESS',
  ERROR: 'BID_BY_COUNTRY_EDIT_ERROR',
};
export const PUBLISHERS_GET = {
  PENDING: 'PUBLISHERS_GET_PENDING',
  SUCCESS: 'PUBLISHERS_GET_SUCCESS',
  ERROR: 'PUBLISHERS_GET_ERROR',
};
export const PUBLISHERS_GET_LAST = {
  PENDING: 'PUBLISHERS_GET_PENDING',
  SUCCESS: 'PUBLISHERS_GET_LAST_SUCCESS',
  ERROR: 'PUBLISHERS_GET_ERROR',
};
export const ADVERTISERS_GET = {
  PENDING: 'ADVERTISERS_GET_PENDING',
  SUCCESS: 'ADVERTISERS_GET_SUCCESS',
  ERROR: 'ADVERTISERS_GET_ERROR',
};
export const ADVERTISERS_CAMPAIGNS_GET = {
  PENDING: 'ADVERTISERS_CAMPAIGNS_GET_PENDING',
  SUCCESS: 'ADVERTISERS_CAMPAIGNS_GET_SUCCESS',
  ERROR: 'ADVERTISERS_CAMPAIGNS_GET_ERROR',
};
export const ADVERTISERS_GET_LAST = {
  PENDING: 'ADVERTISERS_GET_PENDING',
  SUCCESS: 'ADVERTISERS_GET_LAST_SUCCESS',
  ERROR: 'ADVERTISERS_GET_ERROR',
};
export const POTENTIALS_GET = {
  PENDING: 'POTENTIALS_GET_PENDING',
  SUCCESS: 'POTENTIALS_GET_SUCCESS',
  ERROR: 'POTENTIALS_GET_ERROR',
};
export const OPTIONS_GET = {
  PENDING: 'OPTIONS_GET_PENDING',
  SUCCESS: 'OPTIONS_GET_SUCCESS',
  ERROR: 'OPTIONS_GET_ERROR',
};
export const ADMINS_GET = {
  PENDING: 'ADMINS_GET_PENDING',
  SUCCESS: 'ADMINS_GET_SUCCESS',
  ERROR: 'ADMINS_GET_ERROR',
};
export const PERMISION_UPDATE = {
  PENDING: 'PERMISION_UPDATE_PENDING',
  SUCCESS: 'PERMISION_UPDATE_SUCCESS',
  ERROR: 'PERMISION_UPDATE_ERROR',
};
export const CREATE_ADMIN = {
  PENDING: 'CREATE_ADMIN_PENDING',
  SUCCESS: 'CREATE_ADMIN_SUCCESS',
  ERROR: 'CREATE_ADMIN_ERROR',
};
export const RESET_ADMIN_PASSWORD = {
  PENDING: 'RESET_ADMIN_PASSWORD_PENDING',
  SUCCESS: 'RESET_ADMIN_PASSWORD_SUCCESS',
  ERROR: 'RESET_ADMIN_PASSWORD_ERROR',
};
export const PERMISSIONS_GET = {
  PENDING: 'PERMISSIONS_GET_PENDING',
  SUCCESS: 'PERMISSIONS_GET_SUCCESS',
  ERROR: 'PERMISSIONS_GET_ERROR',
};
export const LOGS_GET = {
  PENDING: 'LOGS_GET_PENDING',
  SUCCESS: 'LOGS_GET_SUCCESS',
  ERROR: 'LOGS_GET_ERROR',
};
export const LOGS_GET_LAST = {
  PENDING: 'LOGS_GET_PENDING',
  SUCCESS: 'LOGS_GET_LAST_SUCCESS',
  ERROR: 'LOGS_GET_ERROR',
};
export const PAYMENTS_GET = {
  PENDING: 'PAYMENTS_GET_PENDING',
  SUCCESS: 'PAYMENTS_SUCCESS',
  ERROR: 'PAYMENTS_GET_ERROR',
};
export const PAYMENTS_LAST_GET = {
  PENDING: 'PAYMENTS_GET_PENDING',
  SUCCESS: 'PAYMENTS_LAST_SUCCESS',
  ERROR: 'PAYMENTS_GET_ERROR',
};
export const PAYMENTS_APPROVE = {
  PENDING: 'PAYMENTS_APPROVE_PENDING',
  SUCCESS: 'PAYMENTS_APPROVE_SUCCESS',
  ERROR: 'PAYMENTS_APPROVE_ERROR',
};
export const AUTOCOMPLITE_USER = {
  PENDING: 'AUTOCOMPLITE_USER_PENDING',
  SUCCESS: 'AUTOCOMPLITE_USER_SUCCESS',
  ERROR: 'AUTOCOMPLITE_USER_ERROR',
};
export const ADD_MONEY = {
  PENDING: 'ADD_MONEY_PENDING',
  SUCCESS: 'ADD_MONEY_SUCCESS',
  ERROR: 'ADD_MONEY_ERROR',
};
export const TENANT_UPDATE = {
  PENDING: 'TENANT_UPDATE_PENDING',
  SUCCESS: 'TENANT_UPDATE_SUCCESS',
  ERROR: 'TENANT_UPDATE_ERROR',
};
export const GET_DASHBOARD_DATA = {
  PENDING: 'GET_DASHBOARD_DATA_PENDING',
  SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  ERROR: 'GET_DASHBOARD_DATA_ERROR',
};
export const GET_TRAFIC = {
  PENDING: 'GET_TRAFIC_PENDING',
  SUCCESS: 'GET_TRAFIC_SUCCESS',
  ERROR: 'GET_TRAFIC_ERROR',
};
export const GET_PAYMENTS_GATEWAYS = {
  PENDING: 'GET_PAYMENTS_GATEWAYS_PENDING',
  SUCCESS: 'GET_PAYMENTS_GATEWAYS_SUCCESS',
  ERROR: 'GET_PAYMENTS_GATEWAYS_ERROR',
};
export const GET_INCOMES = {
  PENDING: 'GET_INCOMES_PENDING',
  SUCCESS: 'GET_INCOMES_SUCCESS',
  ERROR: 'GET_INCOMES_ERROR',
};
export const PAYMENTS_UPDATE = {
  PENDING: 'PAYMENTS_UPDATE_PENDING',
  SUCCESS: 'PAYMENTS_UPDATE_SUCCESS',
  ERROR: 'PAYMENTS_UPDATE_ERROR',
};
export const FILTER_CAMPAIGNS = {
  PENDING: 'FILTER_CAMPAIGNS_PENDING',
  SUCCESS: 'FILTER_CAMPAIGNS_SUCCESS',
  ERROR: 'FILTER_CAMPAIGNS_ERROR',
};
export const FILTER_USERS = {
  PENDING: 'FILTER_USERS_PENDING',
  SUCCESS: 'FILTER_USERS_SUCCESS',
  ERROR: 'FILTER_USERS_ERROR',
};

export const INFINITE_CAMPAIGNS_SCROLL = {
  PENDING: 'INFINITE_CAMPAIGNS_SCROLL_PENDING',
  SUCCESS: 'INFINITE_CAMPAIGNS_SCROLL_SUCCESS',
  ERROR: 'INFINITE_CAMPAIGNS_SCROLL_ERROR',
};

export const INFINITE_USERS_SCROLL = {
  PENDING: 'INFINITE_USERS_SCROLL_PENDING',
  SUCCESS: 'INFINITE_USERS_SCROLL_SUCCESS',
  ERROR: 'INFINITE_USERS_SCROLL_ERROR',
};

export const INFINITE_APPS_SCROLL = {
  PENDING: 'INFINITE_APPS_SCROLL_PENDING',
  SUCCESS: 'INFINITE_APPS_SCROLL_SUCCESS',
  ERROR: 'INFINITE_APPS_SCROLL_ERROR',
};

export const RESET_CAMPAIGNS_REDUCER = 'RESET_CAMPAIGNS_REDUCER';
export const RESET_CAMPAIGNS_TABLE_STATE = 'RESET_CAMPAIGNS_TABLE_STATE';
export const RESET_ADVERTISER_REDUCER = 'RESET_ADVERTISER_REDUCER';
export const RESET_ADSPACES = 'RESET_ADSPACES';
export const RESET_USERS_TABLE = 'RESET_USERS_TABLE';
export const RESET_USERS_REDUCER = 'RESET_USERS_REDUCER';
