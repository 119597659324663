import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import PublishersComponent from '../../components/PublishersComponent/PublishersComponent';
import {
  getPublishers,
  getPublishersLast,
  resetPublishersReducer,
} from '../../store/actions/PublishersActions';
import { withRouter } from 'react-router-dom';
import { getCountries } from '../../config/getCountries';

const PublishersContainer = ({
  resetReducer,
  DashboardReducer: {
    optionsData: {
      options: { logo },
      provider
    },
    permissionsFetched,
  },
  getPublishers,
  PublishersReducer,
  getPublishersLast,
  data,
  pending,
  filtered,
  status,
}) => {
  const [dataMapped, setDataMapped] = useState(null);

  useEffect(() => {
    getPublishers({ type: 'adminDate', provider, filter: { userType: ['publisher'] } });
    return () => resetReducer();
  }, [getPublishers, resetReducer]);

  useEffect(() => {
    const countries = getCountries();
    const mapped = data.map(it => {
      const item = { ...it };
      item.country &&
        countries.forEach(country => {
          if (item.country === country.code) {
            item.country = country.name;
          }
        });
      return item;
    });
    setDataMapped([...mapped]);
  }, [data]);

  return (
    <div>
      <InnerPagesComponent pageName="Publisher" logo={logo}>
        {permissionsFetched && (
          <PublishersComponent
            getPublishers={getPublishers}
            PublishersReducer={PublishersReducer}
            resetReducer={resetReducer}
            getPublishersLast={getPublishersLast}
            data={dataMapped}
            pending={pending}
            filtered={filtered}
            status={status}
            provider={provider}
          />
        )}
      </InnerPagesComponent>
    </div>
  );
};

const mapStateFromProps = store => ({
  PublishersReducer: store.PublishersReducer,
  DashboardReducer: store.DashboardReducer,
  data: store.PublishersReducer.data,
  pending: store.PublishersReducer.pending,
  filtered: store.PublishersReducer.filtered,
  status: store.PublishersReducer.status,
});

const mapDispatchFromProps = dispatch => ({
  getPublishers: body => dispatch(getPublishers(body)),
  resetReducer: _ => dispatch(resetPublishersReducer()),
  getPublishersLast: body => dispatch(getPublishersLast(body)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(PublishersContainer)
);
