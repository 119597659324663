import countries from './countries.json';

export const getCountries = () => {
  return countries.countries.map(it => {
    it.text = it.name;
    it.key = it.name + it.code;
    it.value = it.code;
    return it;
  });
};
