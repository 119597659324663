import React, { Component } from 'react';
import './ActionsMenu.scss';
import * as _ from 'lodash';

class Table extends Component {
  state = {
    display: 'none',
    userActions:
      this.props.status === 'unconfirmed' || !this.props.Permissions.update
        ? ['More']
        : this.props.status === 'approved' && this.props.Permissions.update
        ? ['More', 'Block']
        : this.props.status === 'declined' && this.props.Permissions.update
        ? ['More', 'Approve']
        : this.props.status === 'pending' && this.props.Permissions.update
        ? ['More', 'Block', 'Approve']
        : [],
    comapaignsActions:
      this.props.status === 'active' && this.props.Permissions.update
        ? ['More', 'View', 'Block']
        : this.props.status === 'declined' && this.props.Permissions.update
        ? ['Active', 'More', 'View']
        : this.props.status === 'pending' && this.props.Permissions.update
        ? ['More', 'Block', 'Active']
        : ['More', 'View'],
    appsActions:
      this.props.status === 'active' && this.props.Permissions.update
        ? ['More', 'Block']
        : this.props.status === 'declined' && this.props.Permissions.update
        ? ['Active', 'More']
        : this.props.status === 'pending' && this.props.Permissions.update
        ? ['More', 'Block', 'Active']
        : ['More'],
    adActions: ['More'],
    bidActions:
      this.props.Permissions.update && this.props.Permissions.delete
        ? ['DELETE', 'EDIT']
        : this.props.Permissions.update && !this.props.Permissions.delete
        ? ['EDIT']
        : !this.props.Permissions.update && this.props.Permissions.delete
        ? ['DELETE']
        : [],
  };
  renderActionsList = List => {
    let ul = [];
    List.map((action, key) => {
      ul.push(
        <li
          key={key}
          onClick={() => this.onClickAction(this.props.index, action)}
        >
          {action}
        </li>
      );
    });
    return ul;
  };
  onOpenActions = () => {
    if (this.state.display === 'block') this.setState({ display: 'none' });
    if (this.state.display === 'none') this.setState({ display: 'block' });
  };
  onClickAction = (key, type) => {
    console.log(key, type);
    if (type === 'More') {
      this.props.onMoreModal(key);
    }
    if (_.includes(window.location.pathname, 'users') && type === 'Block') {
      this.props.UsersStatusUbdate(key, 'declined');
    }
    if (_.includes(window.location.pathname, 'users') && type === 'Approve') {
      this.props.UsersStatusUbdate(key, 'approved');
    }
    if (_.includes(window.location.pathname, 'campaigns') && type === 'Block') {
      this.props.CompaignStatusUbdate(key, 'declined');
    }
    if (
      _.includes(window.location.pathname, 'campaigns') &&
      type === 'Active'
    ) {
      this.props.CompaignStatusUbdate(key, 'active');
    }
    if (_.includes(window.location.pathname, 'apps') && type === 'Block') {
      this.props.appsStatusUbdate(key, 'declined');
    }
    if (_.includes(window.location.pathname, 'apps') && type === 'Active') {
      this.props.appsStatusUbdate(key, 'active');
    }
    if (type === 'View') {
      this.props.onViewModal(key);
    }
    if (
      _.includes(window.location.pathname, 'minbid-by-country') &&
      type === 'DELETE'
    ) {
      this.props.onDelete(key);
    }
    if (
      _.includes(window.location.pathname, 'minbid-by-country') &&
      type === 'EDIT'
    ) {
      this.props.onMoreModal(key);
    }
  };
  render() {
    return (
      <button
        className="btn actionsBtn"
        onClick={() => {
          this.onOpenActions();
        }}
      >
        Menu
        <div className="actionsMenu" style={{ display: this.state.display }}>
          <ul>
            {_.includes(window.location.pathname, 'users') &&
              this.renderActionsList(this.state.userActions)}
            {_.includes(window.location.pathname, 'campaigns') &&
              this.renderActionsList(this.state.comapaignsActions)}
            {_.includes(window.location.pathname, 'apps') &&
              this.props.type !== 'addSpaces' &&
              this.renderActionsList(this.state.appsActions)}
            {_.includes(window.location.pathname, 'apps') &&
              this.props.type === 'addSpaces' &&
              this.renderActionsList(this.state.adActions)}
            {_.includes(window.location.pathname, 'minbid-by-country') &&
              this.renderActionsList(this.state.bidActions)}
          </ul>
        </div>
      </button>
    );
  }
}

export default Table;
