import { useState, useEffect } from 'react';

const useCells = (data, activeItem) => {
  const [cells, setCells] = useState([
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'CPM',
      key: 'CPM',
    },
    {
      title: 'eCPM',
      key: 'eCPM',
    },
    {
      title: 'Income',
      key: 'cost',
    },
    {
      title: 'Interactions',
      key: 'count',
    },
    {
      title: 'Clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      key: 'CTR',
    },
  ]);

  useEffect(() => {
    if (activeItem === 'Country') {
      setCells(prevState => {
        const state = prevState.map(it => {
          const item = { ...it };
          return item;
        });
        state.shift();
        state.unshift({ title: 'Country', key: 'country' });
        return [...state];
      });
    } else {
      setCells(prevState => {
        const state = prevState.map(it => {
          const item = { ...it };
          return item;
        });
        state.shift();
        state.unshift({ title: 'Date', key: 'date' });
        return [...state];
      });
    }
  }, [data, activeItem]);

  return [cells];
};

export default useCells;
