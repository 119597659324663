import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Input, Select, Button, Icon } from 'semantic-ui-react';
import { useTypes } from './types';
import _ from 'lodash';
import './index.scss';

const Filter = ({ filter, isLoading, fetchItems, resetTable, type, provider }) => {
  const [filters, setFilters] = useState({});
  const [value, setValue] = useState('');
  const [campaigns, apps, users] = useTypes();

  const handleChange = data => {
    if (type === 'campaigns') {
      setFilters(prevState => {
        const state = { ...prevState };
        state[
          data.placeholder.toLowerCase() === 'type'
            ? 'adType'
            : data.placeholder.toLowerCase()
        ] = [data.value];
        return { ...state };
      });
    }

    if (type === 'user') {
      setFilters(prevState => {
        const state = { ...prevState };
        state[data.placeholder.toLowerCase()] = [data.value];

        return { ...state, [data.placeholder.toLowerCase()]: [data.value] };
      });
    }

    if (type === 'apps') {
      setFilters(prevState => {
        const state = { ...prevState };
        state[data.placeholder.toLowerCase()] = [data.value];

        return { ...state, [data.placeholder.toLowerCase()]: [data.value] };
      });
    }
  };

  const handleSubmit = () => {
    if (value) {
      filter({
        filter: {},
        search: { type: 'id', value },
        provider
      });
    } else {
      filter({ filter: { ...filters }, provider });
    }
  };

  const renderSelectOptions = type => {
    if (type === 'campaigns') {
      return _.map(campaigns, (value, key) => {
        return (
          <Col sm="2" key={key}>
            <Select
              options={campaigns[key]}
              placeholder={key}
              className="w-100"
              onChange={(e, data) => handleChange(data)}
            />
          </Col>
        );
      });
    }

    if (type === 'user') {
      return _.map(users, (value, key) => {
        return (
          <Col sm="2" key={key}>
            <Select
              options={users[key]}
              placeholder={key}
              className="w-100"
              onChange={(e, data) => handleChange(data)}
            />
          </Col>
        );
      });
    }

    if (type === 'apps') {
      return _.map(apps, (value, key) => {
        return (
          <Col sm="2" key={key}>
            <Select
              options={apps[key]}
              placeholder={key}
              className="w-100"
              onChange={(e, data) => handleChange(data)}
            />
          </Col>
        );
      });
    }
  };

  return (
    <Row>
      <Col sm="2">
        <Input
          loading={isLoading}
          icon="search"
          placeholder="Search..."
          onChange={e => setValue(e.target.value)}
          value={value}
          className="filtered-search w-100"
        />
      </Col>
      {renderSelectOptions(type)}
      <Col sm="2" className="d-flex align-items-center">
        <Button
          color="red"
          style={{ height: '100%' }}
          className="d-flex justify-content-between"
          inverted
          onClick={() => {
            setFilters({});
            resetTable();
            fetchItems();
          }}
        >
          Clear
          <Icon name="delete" />
        </Button>
        <Button
          color="green"
          style={{ height: '100%' }}
          className="d-flex justify-content-between"
          inverted
          onClick={() => {
            resetTable();
            handleSubmit();
          }}
          loading={isLoading}
          disabled={isLoading}
        >
          Filter
          <Icon name="search" />
        </Button>
      </Col>
    </Row>
  );
};

export default Filter;
