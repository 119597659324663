import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import BidByCountryComponent from '../../components/BidByCountryComponent/BidByCountryComponent'
import Loader from '../../shared/Loader';
import {resetReducer} from '../../store/actions/resetReducerAction'
import { getBids , editBid } from '../../store/actions/BidByCountryActions'
import { withRouter } from 'react-router-dom';

class BidByCountryContainer extends Component {
    componentWillMount() {
        this.props.getBids("geolocation", this.props.DashboardReducer.optionsData.provider)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.BidReducer.editFetched !== this.props.BidReducer.editFetched && nextProps.BidReducer.editFetched) {
            this.props.getBids("geolocation", this.props.DashboardReducer.optionsData.provider)
        }
    }
    componentWillUnmount(){
        this.props.resetReducer("RESET_BID_BY_COUNTRY_REDUCER")
    }
    render() {
        return (
            <div>
                <InnerPagesComponent pageName="" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.BidReducer.bidPending && <Loader />}
                    {this.props.BidReducer.editPending && <Loader />}
                    {this.props.BidReducer.bidFetched && !this.props.BidReducer.editPending && this.props.DashboardReducer.permissionsFetched &&
                        <BidByCountryComponent
                            bidData={this.props.BidReducer.bidData}
                            editBid={this.props.editBid}
                            bidPermissions={this.props.DashboardReducer.permissionsData.minbid}
                        />
                    }
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    BidReducer: store.BidReducer,
    DashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
    getBids: (globalType, provider) => (dispatch(getBids(globalType, provider))),
    editBid: (body) => (dispatch(editBid(body))),
    resetReducer: (name) => (dispatch(resetReducer(name)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(BidByCountryContainer));
