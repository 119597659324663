import { combineReducers } from 'redux';
import loginReducer from './LoginReducer';
import usersReducer from './UsersReducer';
import campaignsReducer from './CampaignsReducer';
import appsReducer from './AppsReducer';
import DefaultOptionsReducer from './DefaultOptionsReducer';
import BillingInfoReducer from './BillingInfoReducer';
import BidReducer from './BidByCountryReducer';
import PublishersReducer from './PublishersReducer';
import AdvertisersReducer from './AdvertisersReducer';
import PotentialsReducer from './PotentialsReducer';
import DashboardReducer from './DashboardReducer';
import AdminsReducer from './AdminsReducer';
import logsReducer from './LogsReducer';
import paymentsReducer from './PaymentReducer';
import TenantUpdateReducer from './TenatUpdateReducer';
import IncomesReducer from './IncomesReducer';

export default combineReducers({
  loginReducer,
  usersReducer,
  campaignsReducer,
  appsReducer,
  DefaultOptionsReducer,
  BillingInfoReducer,
  BidReducer,
  PublishersReducer,
  AdvertisersReducer,
  PotentialsReducer,
  DashboardReducer,
  AdminsReducer,
  logsReducer,
  paymentsReducer,
  TenantUpdateReducer,
  IncomesReducer,
});
