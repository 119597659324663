import React, { Fragment, useEffect, useState, useCallback } from 'react';
import 'numeral/locales/pt-br';
import Countries from '../../config/countries.json';
import { Table as STable, Header } from 'semantic-ui-react';
import _ from 'lodash';
import Loader from '../Loader';
import moment from 'moment';
import CreateGenerateData from './TableBody';
import './index.scss';

const Table = React.memo(
  ({
    isLoading,
    action,
    cells,
    infinite = false,
    pagination,
    getItems,
    data,
    changeStatus,
    dataStatus,
    permissions,
    type,
    onMoreModal,
    onViewModal,
    dataUpdate,
    filtered,
    modalData,
    resetTableState,
    filters,
     provider
  }) => {
    const [items, setItems] = useState([]);

    const handleScroll = useCallback(() => {
      const scroller = document.getElementById('main-content');
      if (
        scroller.scrollTop + scroller.offsetHeight === scroller.scrollHeight &&
        pagination &&
        !isLoading
      ) {
        getItems({
          LastEvaluatedKey: { ...pagination },
          ...filters,
          provider
        });
      }
    }, [filters, getItems, isLoading, pagination]);

    /**
     * @param useEffect ==> hook for infinite scroll table items
     */
    useEffect(() => {
      const scroller = document.getElementById('main-content');
      if (data && infinite && !scroller.onscroll) {
        scroller.addEventListener('scroll', handleScroll);
      }
      return () => {
        scroller.removeEventListener('scroll', handleScroll);
      };
    }, [data, handleScroll, infinite]);

    /**
     * @param useEffect ==> extracting fetched data from store
     */
    useEffect(() => {
      if (data && !isLoading && !dataStatus.updatedItem) {
        setItems([...data]);
      }
    }, [
      data,
      dataStatus,
      dataStatus.updatedItem,
      dataStatus.pending,
      isLoading,
      resetTableState,
      type,
    ]);

    /**
     * @param useEffect ==> hook for users
     */
    useEffect(() => {
      if (
        filtered.items &&
        filtered.items.length > 0 &&
        !filtered.pending &&
        type === 'user'
      ) {
        const state = filtered.items.map(item => ({
          type: item.type,
          status: item.status,
          information: (
            <div>
              {item.first_name && (
                <p className="my-0">
                  <span className="font-weight-bold">Full Name:</span>
                  {item.first_name} {item.last_name}
                </p>
              )}
              <p className="my-0">
                <span className="font-weight-bold">Email:</span> {item.email}
              </p>
              <p className="my-0">
                <span className="font-weight-bold">Country:</span>
                {Countries.countries.map(country => {
                  if (country.code === item.locale) return country.name;
                })}
              </p>
            </div>
          ),
          balance: `${(Number(item.balance) / 100).toFixed(2)}$`,
          id: item.id,
        }));
        setItems([...state]);
      }
    }, [
      data,
      dataStatus.updatedItem,
      filtered,
      isLoading,
      resetTableState,
      type,
    ]);
    /**
     * @param ==> hook for updating current edited item in table
     */
    useEffect(() => {
      if (
        dataStatus &&
        (dataStatus.campaignsStatusFetched ||
          dataStatus.userStatusFetched ||
          dataStatus.appsFetched) &&
        dataStatus.updatedItem
      ) {
        setItems(prevState => {
          const mutable = prevState.map(it => {
            if (
              (it.id || it.advertiser) === dataStatus.updatedItem.id &&
              type === 'campaigns'
            ) {
              const updated = {
                advertiser: dataStatus.updatedItem.id,
                title: dataStatus.updatedItem.title,
                adType:
                  dataStatus.updatedItem.adType === 'flatVideo'
                    ? 'Flat Video'
                    : dataStatus.updatedItem.adType,
                isDailyBudget: `${Number(
                  dataStatus.updatedItem.isDailyBudget
                ).toFixed(2)}$`,
                budget: `${Number(dataStatus.updatedItem.budget).toFixed(2)}$`,
                status: dataStatus.updatedItem.status,
              };
              const item = { ...updated };
              return item;
            }
            if (it.id === dataStatus.updatedItem.app_id && type === 'apps') {
              const updated = {
                id: dataStatus.updatedItem.app_id,
                name: dataStatus.updatedItem.name,
                media_type: dataStatus.updatedItem.media_type,
                category: dataStatus.updatedItem.category,
                genre: dataStatus.updatedItem.genre,
                url: dataStatus.updatedItem.url,
                updated_at: moment(dataStatus.updatedItem.updated_at).format(
                  'll'
                ),
                created_at: moment(dataStatus.updatedItem.created_at).format(
                  'll'
                ),
                status: dataStatus.updatedItem.status,
              };
              const item = { ...updated };
              return item;
            }

            if (it.id === dataStatus.updatedItem.id && type === 'user') {
              const updated = {
                type: dataStatus.updatedItem.type,
                status: dataStatus.updatedItem.status,
                information: (
                  <div>
                    {dataStatus.updatedItem.first_name && (
                      <p className="my-0">
                        <span className="font-weight-bold">Full Name:</span>{' '}
                        {dataStatus.updatedItem.first_name}{' '}
                        {dataStatus.updatedItem.last_name}
                      </p>
                    )}
                    <p className="my-0">
                      <span className="font-weight-bold">Email:</span>{' '}
                      {dataStatus.updatedItem.email}
                    </p>
                    <p className="my-0">
                      <span className="font-weight-bold">Country:</span>{' '}
                      {Countries.countries.map(country => {
                        if (country.code === dataStatus.updatedItem.locale) {
                          return country.name;
                        }
                      })}
                    </p>
                  </div>
                ),
                balance: `${(
                  Number(
                    dataStatus.updatedItem.balance ||
                      dataStatus.updatedItem.finance.balance
                  ) / 100
                ).toFixed(2)}$`,
                id: dataStatus.updatedItem.id,
              };
              const item = { ...updated };
              return item;
            }
            return it;
          });
          return [...mutable];
        });
      }
    }, [data, dataStatus, type]);

    return (
      <Fragment>
        <STable
          columns={cells.length}
          celled
          style={{ border: 'none', marginBottom: '7rem' }}
        >
          <STable.Header>
            <STable.Row>
              {cells &&
                _.map(cells, (value, key) => (
                  <STable.HeaderCell key={value.key} className="text-center">
                    <Header as="h4">{value.title}</Header>
                  </STable.HeaderCell>
                ))}
            </STable.Row>
          </STable.Header>
          <STable.Body className="mb-5">
            {items &&
              _.map(items, (dataValues, key) => (
                <Fragment key={key}>
                  <STable.Row key={key}>
                    <CreateGenerateData
                      data={dataValues}
                      cells={cells}
                      action={action}
                      id={dataValues.id}
                      changeStatus={changeStatus}
                      permissions={permissions}
                      type={type}
                      onMoreModal={onMoreModal}
                      onViewModal={onViewModal}
                      campaignStatusUpdate={dataUpdate}
                      actionKey={key}
                      modalData={modalData}
                      provider={provider}
                    />
                  </STable.Row>
                </Fragment>
              ))}
          </STable.Body>
        </STable>
        {isLoading && <Loader inline />}
      </Fragment>
    );
  }
);

export default Table;
