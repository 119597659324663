import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import NavbarComponent from './NavbarComponent/NavbarComponent';
import { getPermissions } from '../../store/actions/DashboarActions';
import { connect } from 'react-redux';
import './InnerPagesComponent.scss';
import Loader from '../../shared/Loader';

class InnerPagesComponent extends Component {
  componentDidMount() {
    const { getPermissions, provider } = this.props;
    getPermissions(provider);
  }
  render() {
    const { logo, permissionsPending, permissions, pageName } = this.props;
    return (
      <div>
        <HeaderComponent logo={logo} />
        <section className="inner-section">
          {permissionsPending && <Loader />}
          {Object.keys(permissions).length > 0 && (
            <NavbarComponent permissions={permissions} />
          )}
          <section id="main-content" className="main-content">
            <div className="inner-panel">
              <div className="container-fluid">
                {pageName !== '' && (
                  <div className="content-header clearfix">
                    <h1>{pageName}</h1>
                  </div>
                )}
                {this.props.children}
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }
}
const mapStateFromProps = store => ({
  permissionsPending: store.DashboardReducer.permissionsPending,
  permissions: store.DashboardReducer.permissionsData,
  provider: store.DashboardReducer.optionsData.provider,
});

const mapDispatchFromProps = dispatch => ({
  getPermissions: provider => dispatch(getPermissions(provider)),
});

export default connect(
  mapStateFromProps,
  mapDispatchFromProps
)(InnerPagesComponent);
