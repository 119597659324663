import {
  ADVERTISERS_GET,
  ADVERTISERS_GET_LAST,
  RESET_ADVERTISER_REDUCER,
  ADVERTISERS_CAMPAIGNS_GET,
} from '../constants';
import { handleActions } from 'redux-actions';

const defaultState = {
  pending: false,
  fetched: false,
  data: [],
  pagination: null,
  error: false,
  status: {
    updatedItem: null,
  },
  filtered: {
    items: [],
    pending: false,
    success: false,
  },
  advertiserCampaign: {
    pending: false,
    success: false,
  },
};

const AdvertisersReducer = handleActions(
  {
    [ADVERTISERS_GET.PENDING]: (state = defaultState) => ({
      ...state,
      pending: true,
      fetched: false,
      advertiserCampaign: {
        ...state.advertiserCampaign,
        success: false,
      },
    }),

    [ADVERTISERS_GET.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      pending: false,
      fetched: true,
      data: [...payload],
      pagination: payload.LastEvaluatedKey,
    }),

    [ADVERTISERS_GET_LAST.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      pending: false,
      fetched: true,
      data: [...state.advertisersData, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
    }),

    [ADVERTISERS_GET.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      pending: false,
      fetched: false,
      error,
      data: [],
      pagination: null,
    }),

    [RESET_ADVERTISER_REDUCER]: (state = defaultState) => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default AdvertisersReducer;
