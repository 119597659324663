import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  AppsTable,
  AppsStatusUbdate,
  AppsAdspaces,
  resetAdReducer,
  AppsTableLast,
} from '../../store/actions/AppsAction';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import AppsComponent from '../../components/AppsComponent/AppsComponent';
import { resetReducer } from '../../store/actions/resetReducerAction';
import { withRouter } from 'react-router-dom';

class AppsContainer extends Component {
  componentDidMount() {
    const {
      AppsTable,
      DashboardReducer: {
        optionsData: { provider },
      },
    } = this.props;
    AppsTable({ provider });
  }

  componentWillReceiveProps(nextProps) {
    const {
      appsReducer: { appsStatusFetched },
      DashboardReducer: {
        optionsData: { provider },
      },
      AppsTable,
    } = this.props;
    if (
      nextProps.appsReducer.appsStatusFetched !== appsStatusFetched &&
      nextProps.appsReducer.appsStatusFetched
    ) {
      AppsTable({ provider });
    }
  }
  componentWillUnmount() {
    const { resetReducer } = this.props;
    resetReducer('RESET_APP_REDUCER');
  }

  render() {
    const {
      DashboardReducer: {
        optionsData: {
          options: { logo },
          provider,
        },
        permissionsData: { apps },
        permissionsFetched,
      },
      appsReducer,
      AppsStatusUbdate,
      AppsAdspaces,
      AppsTable,
      resetAdReducer,
      AppsTableLast,
      filteredItems,
      resetTableState,
    } = this.props;

    return (
      <Fragment>
        <InnerPagesComponent pageName="Apps" logo={logo}>
          {permissionsFetched && (
            <AppsComponent
              appsReducer={appsReducer}
              appStatusUpdate={AppsStatusUbdate}
              appAdSpace={AppsAdspaces}
              resetReducer={resetAdReducer}
              appTable={AppsTable}
              appTableLast={AppsTableLast}
              appPermissions={apps}
              provider={provider}
              filteredItems={filteredItems}
              resetTableState={resetTableState}
            />
          )}
        </InnerPagesComponent>
      </Fragment>
    );
  }
}

const mapStateFromProps = store => ({
  appsReducer: store.appsReducer,
  DashboardReducer: store.DashboardReducer,
  filteredItems: store.appsReducer.filtered,
  resetTableState: store.appsReducer.resetTable,
});

const mapDispatchFromProps = dispatch => ({
  AppsTable: data => dispatch(AppsTable(data)),
  AppsTableLast: data => dispatch(AppsTableLast(data)),
  AppsStatusUbdate: data => dispatch(AppsStatusUbdate(data)),
  AppsAdspaces: data => dispatch(AppsAdspaces(data)),
  resetAdReducer: () => dispatch(resetAdReducer()),
  resetReducer: name => dispatch(resetReducer(name)),
});

export default withRouter(
  connect(
    mapStateFromProps,
    mapDispatchFromProps
  )(AppsContainer)
);
