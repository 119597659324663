import React, { Component } from 'react';

class BillingInfoComponent extends Component {
    state = {
        billing: {
            beneficiaryName: "",
            beneficiaryAddress: "",
            bankAccountNo: "",
            swift: "",
            bankName: "",
            bankAddress: "",
            correspondentBank: ""
        }
    };
    componentWillMount(){
        let billing = this.state.billing;
        let values = this.props.billingData[0].value
        for(let key in values){
            billing[key] = values[key]
        }
        this.setState({billing})
    }
    onChangeValues = (key, value) => {
        let billing = this.state.billing;
        billing[key]=value;
        this.setState({billing})
    }
    onSave=()=>{
        let body={
            id: this.props.billingData[0].id,
            value: this.state.billing
        }
        this.props.editBillingInfo([body])
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="shadow-widget" style={{ padding: "20px" }}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Beneficiary</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.beneficiaryName}
                                    onChange={(e)=>this.onChangeValues("beneficiaryName",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Beneficiary address</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.beneficiaryAddress}
                                    onChange={(e)=>this.onChangeValues("beneficiaryAddress",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Bank account no</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.bankAccountNo}
                                    onChange={(e)=>this.onChangeValues("bankAccountNo",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>BIC/SWIFT</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.swift}
                                    onChange={(e)=>this.onChangeValues("swift",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Bank</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.bankName}
                                    onChange={(e)=>this.onChangeValues("bankName",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Banks address</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.bankAddress}
                                    onChange={(e)=>this.onChangeValues("bankAddress",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <small>Correspondent bank</small>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.billing.correspondentBank}
                                    onChange={(e)=>this.onChangeValues("correspondentBank",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12"> </div>
                        {this.props.permissions.update && <div className="col-md-4">
                            <button className="btn btn-success" onClick={()=>this.onSave()}>Save</button>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default BillingInfoComponent;
