import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import BillingInfoComponent from '../../components/BillingInfoComponent/BillingInfoComponent';
import { getBillingInfo, editBillingInfo } from '../../store/actions/BillingInfoActions';
import {resetReducer} from '../../store/actions/resetReducerAction'
import Loader from '../../shared/Loader';
import {withRouter} from "react-router-dom";

class BillingInfoContainer extends Component {
  componentWillMount() {
    this.props.getBillingInfo("billingInfo", "adlooker")
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.BillingInfoReducer.editFetched !== this.props.BillingInfoReducer.editFetched && nextProps.BillingInfoReducer.editFetched) {
      this.props.getBillingInfo("billingInfo", "adlooker")
    }
  }
  componentWillUnmount(){
    this.props.resetReducer("RESET_BILING_INFO_REDUCER")
  }
  render() {
    return (
      <div>
        <InnerPagesComponent pageName="BILLING DETAILS" logo={this.props.DashboardReducer.optionsData.options.logo}>
          {this.props.BillingInfoReducer.billingPending && <Loader />}
          {this.props.BillingInfoReducer.editPending && <Loader />}
          {this.props.BillingInfoReducer.billingFetched && !this.props.BillingInfoReducer.editPending && this.props.DashboardReducer.permissionsFetched && 
            <BillingInfoComponent
              billingData={this.props.BillingInfoReducer.billingData}
              editBillingInfo={this.props.editBillingInfo}
              permissions={this.props.DashboardReducer.permissionsData.billingInfo}
            />
          }
        </InnerPagesComponent>
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  BillingInfoReducer: store.BillingInfoReducer,
  DashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
  getBillingInfo: (globalType, provider) => dispatch(getBillingInfo(globalType, provider)),
  editBillingInfo: (body) => dispatch(editBillingInfo(body)),
  resetReducer: (name) => dispatch(resetReducer(name))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(BillingInfoContainer));
