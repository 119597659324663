import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import DefaultOptionsComponent from '../../components/DefaultOptionsComponent/DefaultOptionsComponent';
import { getDefaultOptions, editDefaultOptions } from '../../store/actions/DefaultOptionsActions';
import {resetReducer} from '../../store/actions/resetReducerAction'
import Loader from '../../shared/Loader';
import { withRouter } from 'react-router-dom';

class DefaultOptionsContainer extends Component {
    componentWillMount() {
        this.props.getDefaultOptions("default", this.props.DashboardReducer.optionsData.provider);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.DefaultOptionsReducer.editFetched !== this.props.DefaultOptionsReducer.editFetched && nextProps.DefaultOptionsReducer.editFetched) {
            this.props.getDefaultOptions("default", this.props.DashboardReducer.optionsData.provider);
        }
    }
    componentWillUnmount(){
        this.props.resetReducer("RESET_DEFAULT_OPTIONS_REDUCER")
    }
    render() {
        const self = this;
        return (
            <div>
                <InnerPagesComponent pageName="DEFAULT OPTIONS" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.DefaultOptionsReducer.defaultPending && <Loader />}
                    {this.props.DefaultOptionsReducer.editPending && <Loader />}
                    {this.props.DefaultOptionsReducer.defaultFetched && !this.props.DefaultOptionsReducer.editPending && this.props.DashboardReducer.permissionsFetched && <DefaultOptionsComponent
                        DefaultOptionsReducer={this.props.DefaultOptionsReducer}
                        editDefaultOptions={this.props.editDefaultOptions}
                        permissions={this.props.DashboardReducer.permissionsData.defaultOptions}
                    />}
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    DefaultOptionsReducer: store.DefaultOptionsReducer,
    DashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
    getDefaultOptions: (globalType, provider) => (dispatch(getDefaultOptions(globalType, provider))),
    editDefaultOptions: (body) => (dispatch(editDefaultOptions(body))),
    resetReducer: (name) => (dispatch(resetReducer(name)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(DefaultOptionsContainer));
