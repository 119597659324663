import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import './NavbarComponent.scss';

class NavbarComponent extends Component {
  state = {
    // aside-navbar-active
  };

  render() {
    return (
      <nav className="main-navigation">
        <div className="inner-panel">
          <span className="nav-seperator">General
          </span>
          <ul>
            <li><NavLink exact={true} activeClassName='aside-navbar-active' to="/admin/dashboard"><i className="fa fa-home fa-lg"></i> Dashboard</NavLink></li>
          </ul>
          <span className="nav-seperator">User Control</span>
          <ul>
            {this.props.permissions.users.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/users"><i className="fa fa-users fa-md"></i> Users</NavLink></li>}
            {this.props.permissions.campaigns.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/campaigns"><i className="fa fa-users fa-md"></i> Campaigns</NavLink></li>}
            {this.props.permissions.apps.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/apps"><i className="fa fa-users fa-md"></i> Apps</NavLink></li>}
          </ul>
          <span className="nav-seperator">Reportings</span>
          <ul>
            {this.props.permissions.reports.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/publishers"><i className="fa fa-users fa-md"></i> Publishers</NavLink></li>}
            {this.props.permissions.reports.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/advertisers"><i className="fa fa-users fa-md"></i> Advertisers</NavLink></li>}
            {this.props.permissions.potentialReports.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/potentials"><i className="fa fa-line-chart" aria-hidden="true"></i> Potentials</NavLink></li>}
            <li><NavLink activeClassName='aside-navbar-active' to="/admin/incomes"><i className="fa fa-dollar" aria-hidden="true"></i> Incomes</NavLink></li>
          </ul>
          <span className="nav-seperator">Administrative</span>
          <ul>
            {this.props.permissions.adminUsers.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/admins"><i className="fa fa-users"></i> Admins</NavLink></li>}
            {this.props.permissions.logs.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/logs"><i className="fa fa-file-text-o fa-lg"></i> Logs</NavLink></li>}
          </ul>
          <span className="nav-seperator">Settings</span>
          <ul>
            {this.props.permissions.defaultOptions.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/default-options"><i className="fa fa-cogs fa-lg"></i>  Default Options</NavLink></li>}
            {/* {this.props.permissions.billingInfo.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/billing-info"><i className="fa fa-cogs fa-lg"></i>  Billing info</NavLink></li>} */}
            {this.props.permissions.minbid.read && <li><NavLink activeClassName='aside-navbar-active' to="/admin/minbid-by-country"><i className="fa fa-globe fa-lg"></i>  Minbid by country</NavLink></li>}
            <li><NavLink activeClassName='aside-navbar-active' to="/admin/tenats-update"><i className="fa fa-edit fa-lg"></i>  Website Settings</NavLink></li>
          </ul>
          <span className="nav-seperator">Billing</span>
          <ul>
            <li><NavLink activeClassName='aside-navbar-active' to="/admin/withdrawals"><i className="fa fa-credit-card fa-lg"></i> Withdrawals</NavLink></li>
            <li><NavLink activeClassName='aside-navbar-active' to="/admin/payments"><i className="fa fa-credit-card fa-lg"></i> Payments</NavLink></li>
          </ul>
          {/* <span className="nav-seperator">Tenants</span>
          <ul>
            <li><NavLink activeClassName='aside-navbar-active' to="/admin/tenats-update"><i className="fa fa-edit fa-lg"></i>  Update</NavLink></li>
          </ul> */}
        </div>
      </nav>
    );
  }
}

const mapStateFromProps = store => ({

});

const mapDispatchFromProps = dispatch => ({

});

export default connect(mapStateFromProps, mapDispatchFromProps)(NavbarComponent);
