import { useState, useEffect } from 'react';

const useCells = (data, activeItem) => {
  const [cells, setCells] = useState([
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'CPM',
      key: 'CPM',
    },
    {
      title: 'eCPM',
      key: 'eCPM',
    },
    {
      title: 'Income',
      key: 'cost',
    },
    {
      title: 'Interactions',
      key: 'count',
    },
    {
      title: 'Clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      key: 'CTR',
    },
  ]);

  const [date] = useState([
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'CPM',
      key: 'CPM',
    },
    {
      title: 'eCPM',
      key: 'eCPM',
    },
    {
      title: 'Income',
      key: 'cost',
    },
    {
      title: 'Interactions',
      key: 'count',
    },
    {
      title: 'Clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      key: 'CTR',
    },
  ]);

  const [camp] = useState([
    {
      title: 'Name',
      key: 'campaignName',
    },
    {
      title: 'CPM',
      key: 'CPM',
    },
    {
      title: 'eCPM',
      key: 'eCPM',
    },
    {
      title: 'Income',
      key: 'cost',
    },
    {
      title: 'Interactions',
      key: 'count',
    },
    {
      title: 'Clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      key: 'CTR',
    },
  ]);

  const [country] = useState([
    {
      title: 'Country',
      key: 'country',
    },
    {
      title: 'CPM',
      key: 'CPM',
    },
    {
      title: 'eCPM',
      key: 'eCPM',
    },
    {
      title: 'Income',
      key: 'cost',
    },
    {
      title: 'Interactions',
      key: 'count',
    },
    {
      title: 'Clicks',
      key: 'clicks',
    },
    {
      title: 'CTR',
      key: 'CTR',
    },
  ]);

  useEffect(() => {
    if (activeItem === 'Campaign') {
      setCells([...camp]);
    }
    if (activeItem === 'Country') {
      setCells([...country]);
    }
    if (activeItem === 'Date') {
      setCells([...date]);
    }
  }, [activeItem, camp, country, data, date]);

  return [cells];
};

export default useCells;
