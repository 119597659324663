import React, { useState } from 'react';
import 'numeral/locales/pt-br';
import {
  Table as STable,
  Header,
  Label,
  Icon,
  Accordion,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import Numeral from 'numeral';
import ActionsMenu from './ActionsMenu';
import './index.scss';

const CreateGenerateData = ({
  data,
  cells,
  action,
  id,
  changeStatus,
  permissions,
  type,
  onMoreModal,
  onViewModal,
  campaignStatusUpdate,
  actionKey,
  modalData, provider
}) => {
  return (
    data &&
    _.map(cells, (value, key) => {
      return value.title === 'Actions' ? (
        <STable.Cell
          key={key}
          style={{ verticalAlign: 'middle' }}
          className="text-center"
        >
          <ActionsMenu
            permissions={permissions}
            data={data}
            value={value}
            type={type}
            onViewModal={onViewModal}
            onMoreModal={onMoreModal}
            modalKey={key}
            actionKey={actionKey}
            campaignStatusUpdate={campaignStatusUpdate}
            modalData={modalData}
            provider={provider}
          />
        </STable.Cell>
      ) : (
        <STable.Cell
          key={key}
          style={{ verticalAlign: 'middle' }}
          className={value.title !== 'Information' ? 'text-center' : ''}
        >
          <RenderCell data={data} value={value} />
        </STable.Cell>
      );
    })
  );
};

const RenderCell = ({ data, value }) => {
  const numberFormatting = number => {
    const num = number
      .toString()
      .split('')
      .filter(it => it !== '-')
      .join('');
    const formatted = Numeral(num).format('$0,0.00');
    return `${formatted}`;
  };

  const ctrFormatting = number => {
    const num = number
      .toString()
      .split('')
      .filter(it => it !== '-')
      .join('');
    const formatted = Numeral(num).format('0,0.00');
    return `${formatted}`;
  };

  if (data[value.key] === 'flatVideo') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        Flat Video
      </Header>
    );
  }

  if (value.name === 'Campaign Title') {
    return (
      <Header as="h4" color="blue" style={{ fontWeight: 'normal' }}>
        {data[value.key]}
      </Header>
    );
  }

  if (value.key === 'status') {
    return (
      <Label
        color={
          data[value.key] === 'pending'
            ? 'yellow'
            : data[value.key] === 'declined'
            ? 'red'
            : data[value.key] === 'paused'
            ? 'grey'
            : data[value.key] === 'unconfirmed'
            ? 'grey'
            : 'green'
        }
        size="large"
        as="h4"
        className="w-100"
        style={{ fontWeight: 'normal' }}
      >
        {data[value.key].toUpperCase()}
      </Label>
    );
  }

  if (value.key === 'created_at') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {moment(data[value.key]).format('ll')}
      </Header>
    );
  }

  if (value.key === 'amount') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>{`${
        data[value.key]
      } $`}</Header>
    );
  }

  if (value.key === 'cost') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {numberFormatting(data[value.key] / 2)}
      </Header>
    );
  }

  if (value.key === 'CPM') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {numberFormatting(data[value.key] / 2)}
      </Header>
    );
  }

  if (value.key === 'eCPM') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {`${ctrFormatting(data[value.key] / 2)} %`}
      </Header>
    );
  }

  if (value.key === 'date') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {moment(data[value.key]).format('ll')}
      </Header>
    );
  }

  if (value.key === 'traffic') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {data.count}
      </Header>
    );
  }

  if (value.key === 'clicks') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {data.clicks || 0}
      </Header>
    );
  }

  if (value.key === 'CTR') {
    return (
      <Header as="h4" style={{ fontWeight: 'normal' }}>
        {(data.CTR && `${ctrFormatting(data.CTR)} %`) || `${ctrFormatting(0)}%`}
      </Header>
    );
  }

  if (value.key === 'name') {
    return <DropdownAccordion title={data[value.key]} />;
  }

  return (
    <Header as="h4" style={{ fontWeight: 'normal' }}>
      {data[value.key]}
    </Header>
  );
};

export default CreateGenerateData;

const DropdownAccordion = ({ id, title }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <Accordion
      fluid
      styled
      as={STable.Row}
      key={id}
      style={{ boxShadow: 'none' }}
    >
      <STable.Cell>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          style={{ border: 'none' }}
          className="p-0"
          onClick={() => setActiveIndex(activeIndex === 0 ? 1 : 0)}
        >
          <Icon name="dropdown" />
          {title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <p>
            A dog is a type of domesticated animal. Known for its loyalty and
            faithfulness, it can be found as a welcome guest in many households
            across the world.
          </p>
        </Accordion.Content>
      </STable.Cell>
    </Accordion>
  );
};
