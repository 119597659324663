import React, { Component } from 'react';
import Table from './Table/Table'

class LogsComponent extends Component {
    state={
        search: ""
    }
    componentWillMount() {
        let body={
            provider: this.props.DashboardReducer.optionsData.provider,
        }
        this.props.getLogs(body)
    }
    onSearchChange = (value) =>{
        this.setState({search: value})
    }
    onFilter=()=>{
        this.props.resetReducer("RESET_LOGS_REDUCER")
        let body={
            provider: this.props.DashboardReducer.optionsData.provider,
            email: this.state.search
        }
        this.props.getLogs(body)
    }
    render() {
        console.log(this.props.logsReducer)
        return (
            <React.Fragment>
                <div className="content-header clearfix" style={{ borderBottom: "1px solid lightgray", marginBottom: "20px" }}>
                    <h1>LOGS</h1>
                </div>
                <div className="shadow-widget">
                    <div className="row pt-3">
                        <div className="col-md-2">
                            <div className="form-group">
                                <input 
                                    type="text"
                                    className="form-control"
                                    value={this.state.search}
                                    onChange={(e)=>this.onSearchChange(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-1">
                            <div className="form-group">
                                <button className="btn btn-success" onClick={()=>this.onFilter()}>Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    logsReducer = {this.props.logsReducer}
                    getLogsLast={this.props.getLogsLast}
                    DashboardReducer={this.props.DashboardReducer}
                    search={this.state.search}
                />
            </React.Fragment>
        );
    }
}

export default LogsComponent;
