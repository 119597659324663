import React, { Component } from 'react';

const CoinPayments = (props) => {
    return <div className="CoinPaymentsSettings">
        <h5 style={{ paddingTop: "20px" }}>Payment Settings</h5>
        <div className="CoinPaymentsSettingsPart">
            <div className="form-group">
                <small>Min</small>
                <input
                    type="text"
                    className="form-control"
                    value={props.data.min}
                    onChange={(e)=>props.onChangeCoinPayments("min",e.target.value)}
                />
            </div>
            <div className="form-group">
                <small>Max</small>
                <input
                    type="text"
                    className="form-control"
                    value={props.data.max}
                    onChange={(e)=>props.onChangeCoinPayments("max",e.target.value)}
                />
            </div>
            <div className="form-group">
                <small>Fee</small>
                <input
                    type="text"
                    className="form-control"
                    value={props.data.fee}
                    onChange={(e)=>props.onChangeCoinPayments("fee",e.target.value)}
                />
            </div>
            <div className="form-group">
                <small>Public Key</small>
                <input
                    type="text"
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <small>Secret Key</small>
                <input
                    type="text"
                    className="form-control"
                />
            </div>
            <div className="form-group">
                <small>Status</small>
                <select className="form-control" value={props.data.status} onChange={(e)=>props.onChangeCoinPayments("status",e.target.value)}>
                    <option value="active">Active</option>
                    <option value="disabled">Disabled</option>
                </select>
            </div>
            <button
                style={{
                    marginTop: "10px",
                    width: "100%",
                    padding: "7px",
                    background: "#0095ff",
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20px"
                }}
                onClick={()=>props.onSave()}
            >Change </button>
        </div>
    </div>
}

export default CoinPayments;
