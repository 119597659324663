import React, { Fragment, useState } from 'react';
import Filters from '../../shared/Filters/Filters';
import moment from 'moment';
// import Table from './Table/Table';
import Table from '../../shared/SemanticTable/Table';
import './PotentialsComponent.scss';
import Tabs from './Tabs';

const PotentialsComponent = ({
  resetReducer,
  getPotentials,
  PotentialsReducer,
  data,
  pending,
  status,
  filteredItems,
}) => {
  const [cells] = useState([
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'Traffic',
      key: 'traffic',
    },
  ]);

  return (
    <Fragment>
      <Tabs getPotentials={getPotentials} />
      <Table
        cells={cells}
        data={data}
        action
        type="campaigns"
        isLoading={pending}
        filtered={filteredItems}
        // getItems={infiniteScroll}
        infinite
        // pagination={pagination}
        dataStatus={status}
        resetTableState={resetReducer}
        // filters={this.state.filters}
      />
    </Fragment>
  );
};

export default PotentialsComponent;
