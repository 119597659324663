import { LOGIN } from '../constants';

const defaultState = {
  needPasswordChange: false,
  needMFA: false,
  user: null,
  success: false,
  error: null,
  logout: false
}

const loginReducer  = (state = defaultState, action) => {
    const { type, payload, error } = action;
    switch(type) {
      case LOGIN.PASSWORD_CHANGE_REQUIRED : {
        return {
          ...state,
          needPasswordChange: true,
          user: payload
        }
      }
      case LOGIN.MFA_REQUIRED : {
        return {
          ...state,
          needMFA: true,
          user: payload
        }
      }
      case LOGIN.PENDING : {
        return {
          ...state,
          needPasswordChange: false,
          needMFA: false,
          error: null,
          logout: false
        }
      }
      case LOGIN.SUCCESS : {
        return {
          ...state,
          success: true,
          needPasswordChange: false,
          needMFA: false
        }
      }
      case LOGIN.ERROR : {
        return {
          ...state,
          error
        }
      }
      case LOGIN.LOGOUT_SUCCESS : {
        return {
          ...state,
          logout: true
        }
      }
      default : return state;
    }
}

export default loginReducer;
