import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginComponent from '../../components/Auth/LoginComponent/LoginComponent';
import NewPasswordComponent from '../../components/Auth/NewPasswordComponent/NewPasswordComponent';
import MFAComponent from '../../components/Auth/MFAComponent/MFAComponent';
import { LoginAction, LoginPasswordChangeAction, LoginMFAAction } from '../../store/actions/LoginAction';
import Loader from '../../shared/Loader';
import { Redirect } from 'react-router-dom'
import './LoginContainer.scss';

class LoginContainer extends Component {
  state = {
    loading: false,
    status: 'login',
    datalogin : {
      email : '',
      password : ''
    },
    validlogin : {
      email: true,
      password: true,
    },
    datapassword: {
      password : '',
      confirmPassword: ''
    },
    validpassword : {
      password: true,
      confirmPassword: true,
    },
    datamfa: {
      mfa : '',
    },
    validmfa : {
      mfa: true,
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.status !== 'needPasswordChange' && nextProps.needPasswordChange) {
      return ({
        status: 'needPasswordChange'
      })
    }
    if (prevState.status !== 'needMFA' && nextProps.needMFA) {
      return ({
        status: 'needMFA'
      })
    }
  }

  onChangeFields = (type, {name, value, valid}) => {
    const self = this, state = {...self.state};
    [state[`data${type}`][name], state[`valid${type}`][name]] = [value, valid];
    self.setState(state);
  }

  onLogin = () => {
    this.setState({
      loading: true
    }, () => {
      this.props.login({
        username: this.state.datalogin.email,
        password: this.state.datalogin.password
      })
    })
  }

  onNewPassword = () => {
    this.setState({
      loading: true
    }, () => {
      this.props.loginPasswordChange({
        password: this.state.datapassword.password,
        user: this.props.user
      })
    })
  }

  onMFA = () => {
    this.setState({
      loading: true
    }, () => {
      this.props.loginMFA({
        code: this.state.datamfa.mfa,
        user: this.props.user
      })
    })
  }

  initLoader = () => {
    if (!(this.props.needMFA || this.props.needPasswordChange || this.props.error) && this.state.loading) {
      return <Loader />;
    }
  }

  render() {
    const self = this;
    return (<div>
        {self.props.success && <Redirect to='/admin/dashboard'/>}
        {self.initLoader()}
        {self.state.status === 'needPasswordChange' && <NewPasswordComponent
          onChange={self.onChangeFields}
          containerState={self.state}
          onClickButton={self.onNewPassword}
          error={self.props.error}
          formInvalid={!(Object.values(self.state.datapassword).includes('') || Object.values(self.state.validpassword).includes(false))}
        />}
        {self.state.status === 'login' && <LoginComponent
          onChange={self.onChangeFields}
          containerState={self.state}
          onClickButton={self.onLogin}
          error={self.props.error}
          formInvalid={!(Object.values(self.state.datalogin).includes('') || Object.values(self.state.validlogin).includes(false))}
        />}
        {self.state.status === 'needMFA' && <MFAComponent
          onChange={self.onChangeFields}
          containerState={self.state}
          onClickButton={self.onMFA}
          error={self.props.error}
          formInvalid={!(Object.values(self.state.datamfa).includes('') || Object.values(self.state.validmfa).includes(false))}
        />}
      </div>
    );
  }
}

const mapStateFromProps = store => ({
  needPasswordChange: store.loginReducer.needPasswordChange,
  needMFA: store.loginReducer.needMFA,
  success: store.loginReducer.success,
  error: store.loginReducer.error,
  user: store.loginReducer.user,
  DashboardReducer: store.DashboardReducer
});

const mapDispatchFromProps = dispatch => ({
  login: (data) => dispatch(LoginAction(data)),
  loginPasswordChange: (data) => dispatch(LoginPasswordChangeAction(data)),
  loginMFA: (data) => dispatch(LoginMFAAction(data)),
});

export default connect(mapStateFromProps, mapDispatchFromProps)(LoginContainer);
