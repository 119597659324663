import {
  USERS,
  USERS_UPDATE,
  USERS_LAST,
  FILTER_USERS,
  INFINITE_USERS_SCROLL,
  RESET_USERS_TABLE,
  RESET_USERS_REDUCER,
} from '../constants';
import { handleActions } from 'redux-actions';

const defaultState = {
  data: [],
  pagination: null,
  error: null,
  loader: false,
  userStatusPending: false,
  userStatusFetched: false,
  userStatusError: null,
  filtered: {
    items: null,
    pending: false,
  },
  resetTableState: false,
};

const usersReducer = handleActions(
  {
    [INFINITE_USERS_SCROLL.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      data: [...state.data, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [INFINITE_USERS_SCROLL.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
    }),

    [USERS.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
    }),

    [USERS.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      data: payload.Items,
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [USERS_LAST.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      data: [...state.data, ...payload.Items],
      pagination: payload.LastEvaluatedKey,
      loader: false,
    }),

    [USERS.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      error,
    }),

    [USERS_UPDATE.PENDING]: (state = defaultState) => ({
      ...state,
      loader: true,
      userStatusPending: true,
      userStatusFetched: false,
    }),

    [USERS_UPDATE.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      userStatusPending: false,
      userStatusFetched: true,
      loader: false,
      updatedItem: { ...payload.Attributes },
    }),

    [USERS_UPDATE.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      userStatusPending: false,
      userStatusFetched: false,
      userStatusError: error,
    }),

    [USERS_UPDATE.ERROR]: (state = defaultState, { error }) => ({
      ...state,
      loader: false,
      userStatusPending: false,
      userStatusFetched: false,
      userStatusError: error,
    }),

    [FILTER_USERS.PENDING]: (state = defaultState, { error }) => ({
      ...state,
      filtered: {
        items: null,
        pending: true,
      },
    }),

    [FILTER_USERS.SUCCESS]: (state = defaultState, { payload }) => ({
      ...state,
      filtered: {
        items: payload.Items,
        pending: false,
      },
    }),

    [RESET_USERS_TABLE]: (state = defaultState) => ({
      ...defaultState,
      resetTableState: true,
    }),

    [RESET_USERS_REDUCER]: (state = defaultState) => ({
      ...defaultState,
    }),
  },
  defaultState
);

export default usersReducer;
