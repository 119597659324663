import React, { Component } from 'react';
import "./Modal.scss"

class Modal extends Component {
    render() {
        return (
            <div className="modalContainer">
                <div className="modalContent">
                    <div className="modalTitle">
                        <h6 className="Title">
                            CPM
                            <button onClick={() => this.props.onModalClose()}><i className="fa fa-close"></i></button>
                        </h6>
                    </div>
                    <div className="modalBody">
                        <div className="form-group">
                            <input type="text" className="form-control" value={this.props.CPM} onChange={(e)=>this.props.onChangeCreate("CPM",e.target.value)}/>
                        </div>
                        <button className="btn btn-success" style={{width: "100%"}} onClick={()=>this.props.onEdit()}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
