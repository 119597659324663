import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerPagesComponent from '../../components/InnerPagesComponent/InnerPagesComponent';
import WithdrawalsSettingsComponent from '../../components/WithdrawalsSettingsComponent/WithdrawalsSettingsComponent'
import { resetReducer } from '../../store/actions/resetReducerAction';
import { paymentGateways,paymentUpdate } from '../../store/actions/PaymentActions';
import { withRouter,Redirect } from 'react-router-dom';
import Loader from '../../shared/Loader'

class WithdrawalsSettingsContainer extends Component {
    componentWillMount(){
        this.props.paymentGateways({
            provider: this.props.optionsData.provider==="localhost" ? 'adlooker' : this.props.optionsData.provider,
            type: "withdrawalGateways"
        })
    }
    componentWillUnmount() {
        this.props.resetReducer("RESET_PAYMENTS_REDUCER")
    }
    render() {
        if(this.props.paymentUpdateData.fetched){
            return <Redirect to='/admin/withdrawals'/>
        }
        return (
            <div>
                <InnerPagesComponent pageName="Withdrawals Setting" logo={this.props.DashboardReducer.optionsData.options.logo}>
                    {this.props.paymentGatewaysData.pending && <Loader/>}
                    {this.props.paymentUpdateData.pending && <Loader/>}
                    {this.props.DashboardReducer.permissionsFetched && this.props.paymentGatewaysData.fetched && 
                        <WithdrawalsSettingsComponent
                            paymentGatewaysData={this.props.paymentGatewaysData}
                            paymentUpdate={this.props.paymentUpdate}
                            DashboardReducer={this.props.DashboardReducer}
                        />
                    }
                </InnerPagesComponent>
            </div>
        );
    }
}

const mapStateFromProps = store => ({
    DashboardReducer: store.DashboardReducer,
    optionsData: store.DashboardReducer.optionsData,
    paymentGatewaysData: store.paymentsReducer.paymentGatewaysData,
    paymentUpdateData: store.paymentsReducer.paymentUpdateData
});

const mapDispatchFromProps = dispatch => ({
    resetReducer: (name) => (dispatch(resetReducer(name))),
    paymentGateways: (data) => (dispatch(paymentGateways(data))),
    paymentUpdate: (body) => (dispatch(paymentUpdate(body)))
});

export default withRouter(connect(mapStateFromProps, mapDispatchFromProps)(WithdrawalsSettingsContainer));
