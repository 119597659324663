import React, { useState, Fragment } from 'react';
import moment from 'moment';
// import Table from './Table/Table';
import Table from '../../shared/SemanticTable/Table';
import Tabs from './Tabs';
import useCells from './useCells';
import './AdvertisersComponent.scss';

const AdvertisersComponent = ({
  resetReducer,
  getAdvertisers,
  AdvertisersReducer,
  getAdvertisersLast,
  data,
  filtered,
  pending,
  status,
  provider
}) => {
  const [activeItem, setActiveItem] = useState('Date');
  const [cells] = useCells(data, activeItem);

  return (
    <Fragment>
      <Tabs
        getAdvertisers={getAdvertisers}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        provider={provider}
      />
      <Table
        cells={cells}
        data={data}
        action
        type="advertiser"
        isLoading={pending}
        filtered={filtered}
        // getItems={infiniteScroll}
        infinite
        // pagination={pagination}
        dataStatus={status}
        resetTableState={resetReducer}
        // filters={this.state.filters}
      />
    </Fragment>
  );
};

export default AdvertisersComponent;
