import React, { Component } from 'react';
import Countries from "../../../config/countries.json"

class Create extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <div className="form-group">
                            <select className="form-control" value={this.props.country} onChange={(e)=> this.props.onChangeCreate("country",e.target.value)}>
                                <option value="">Select Country</option>
                                {
                                    Countries.countries.map(country => {
                                        return <option value={country.code}>{country.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <input
                                className="form-control"
                                placeholder="CPM"
                                value={this.props.CPM}
                                onChange={(e)=> this.props.onChangeCreate("CPM",e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-md-7"></div>
                    <div className="col-md-1">
                        <button 
                            className="btn btn-sm bg-success create-lead" 
                            mdtooltip="Save" 
                            type="button" 
                            style={{float:"right"}}
                            disabled={this.props.country==="" || this.props.CPM===""}
                            onClick={this.props.onSave}
                        >
                            <i aria-hidden="true" class="fa fa-check text-white"></i>
                        </button>
                        <button className="btn btn-sm bg-danger create-lead" type="button" mdtooltip="Cancel" style={{float:"right",marginRight: "5px"}} onClick={this.props.cancelCreate}>
                            <i aria-hidden="true" class="fa fa-ban text-white"></i>
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

export default Create;
