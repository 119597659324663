import { PUBLISHERS_GET, PUBLISHERS_GET_LAST } from '../constants';

const defaultState = {
  pending: false,
  fetched: false,
  error: null,
  data: [],
  pagination: null,
  status: {
    updatedItem: null,
  },
  filtered: {
    items: [],
    pending: false,
    success: false,
  },
};

const PublishersReducer = (state = defaultState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case PUBLISHERS_GET.PENDING: {
      return {
        ...state,
        pending: true,
        fetched: false,
      };
    }
    case PUBLISHERS_GET.SUCCESS: {
      return {
        ...state,
        pending: false,
        fetched: true,
        data: payload,
        pagination: payload.LastEvaluatedKey,
      };
    }
    case PUBLISHERS_GET_LAST.SUCCESS: {
      return {
        ...state,
        pending: false,
        fetched: true,
        data: [...state.publishersData, ...payload.Items],
        pagination: payload.LastEvaluatedKey,
      };
    }
    case PUBLISHERS_GET.ERROR: {
      return {
        ...state,
        pending: false,
        fetched: false,
        error,
        data: [],
        pagination: null,
      };
    }
    case 'RESET_PUBLISHERS_REDUCER': {
      return {
        ...defaultState,
      };
    }
    default:
      return { ...state };
  }
};

export default PublishersReducer;
